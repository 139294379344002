import productBannerImg1 from "../../../../public/img/home-7/product-banner-img-1.png";
import productBannerImg2 from "../../../../public/img/home-7/product-banner-img-2.png";
import productBannerImg3 from "../../../../public/img/home-7/product-banner-img-3.png";

function BannerMuebles() {
  return (
    <div className="section-space-sm-top section-space-bottom">
    <div className="container">
      <div className="row g-4">
        <div className="col-lg-6">
          <div className="product-banner">
            <img src={productBannerImg1} alt="imagen" className="img-fluid w-100" />
            <div className="product-banner__overlay text-center">
              <span className="product-banner__subtitle clr-light" style={{color:"white"}}>
                HASTA <span className="fw-bold clr-secondary" style={{color:"#FF7C0A"}}>20% DE DESCUENTO</span>
              </span>
              <h3 className="product-banner__title clr-light" style={{color:"white"}}>Moderno & Minimalista</h3>
              <p className="product-banner__info clr-light" style={{color:"white"}}>No te pierdas la oportunidad de ahorrar</p>
              <a href="#" className="link button button--two button-effect button-effect--secondary" style={{background:"#4FB72A", color:"white"}}>
                <span className="d-inline-block fw-semibold"> Comprar Ahora </span>
                <span className="d-inline-block">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="product-banner">
            <img src={productBannerImg2} alt="imagen" className="img-fluid w-100" />
            <div className="product-banner__overlay">
              <span className="product-banner__subtitle" style={{color:"black"}}>
                HASTA <span className="fw-bold clr-secondary" style={{color:"#FF7C0A"}}>20% DE DESCUENTO</span>
              </span>
              <h3 className="product-banner__title" style={{color:"black"}}>Moderno & Minimalista</h3>
              <p className="product-banner__info" style={{color:"black"}}>No te pierdas la oportunidad de ahorrar</p>
              <a href="#" className="link button button-effect button-effect--primary" style={{background:"#FF7C0A", color:"white"}}>
                <span className="d-inline-block fw-semibold"> Comprar Ahora </span>
                <span className="d-inline-block">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 d-none d-md-block">
          <div className="product-banner">
            <img src={productBannerImg3} alt="imagen" className="img-fluid w-100" />
            <div className="product-banner__overlay">
              <span className="product-banner__subtitle">
                HASTA <span className="fw-bold clr-secondary" style={{color:"#FF7C0A"}}>20% DE DESCUENTO</span>
              </span>
              <h3 className="product-banner__title">Nuevas Colecciones de Sofás</h3>
              <p className="product-banner__info">No te pierdas la oportunidad de ahorrar</p>
              <a href="#" className="link button button--dark button-effect button-effect--secondary" style={{background:"black",color:"white"}}>
                <span className="d-inline-block fw-semibold"> Comprar Ahora </span>
                <span className="d-inline-block">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export {BannerMuebles}
