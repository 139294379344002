import bannerBurger from "../../../../public/img/banner/banner-burger.jpg";
import bannerTomato from "../../../../public/img/banner/banner-tomato.jpg";

function BannerNatural() {
    return (
        <>
            <section className="banner-section mt--90 position-relative z-1">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-xl-6">
                            <div
                                className="banner-4 py-7 px-5 rounded-3"
                                style={{ backgroundImage: `url(${bannerBurger})` }}
                            >
                                <div className="d-flex align-items-center gap-2 mb-2">
                                    <h6 className="mb-0 gshop-subtitle text-danger fw-normal">Súper Oferta</h6>
                                    <span>
                                        <svg width="47" height="8" viewBox="0 0 47 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 3.99999L43.3478 4" stroke="#FF0505" strokeWidth="1.21739"></path>
                                            <path d="M47 4L41 0.535898L41 7.4641L47 4Z" fill="#FF0505"></path>
                                        </svg>
                                    </span>
                                </div>
                                <h3 className="mb-3 text-white">Deliciosa Hamburguesa</h3>
                                <div className="d-flex align-items-center gap-3">
                                    <span className="badge-clip fw-medium text-white">CALIENTE</span>
                                    <span className="text-white fw-semibold fs-xs">COMPRA 1 LLEVA 1 GRATIS</span>
                                </div>
                                <a href="product-details.html" className="btn btn-secondary btn-md rounded-1 mt-5">
                                    Comprar Ahora<span className="ms-2"><i className="fas fa-arrow-right"></i></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div
                                className="banner-4 py-7 px-5 rounded-3 shadow"
                                style={{ backgroundImage: `url(${bannerTomato})` }}
                            >
                                <div className="d-flex align-items-center gap-2 mb-2">
                                    <h6 className="mb-0 gshop-subtitle text-danger fw-normal">Oferta de Black Friday</h6>
                                    <span>
                                        <svg width="47" height="8" viewBox="0 0 47 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 3.99999L43.3478 4" stroke="#FF0505" strokeWidth="1.21739"></path>
                                            <path d="M47 4L41 0.535898L41 7.4641L47 4Z" fill="#FF0505"></path>
                                        </svg>
                                    </span>
                                </div>
                                <h3 className="mb-3">Alimentos Orgánicos Hasta <br /> un 50% de Descuento</h3>
                                <a href="product-details.html" className="btn btn-primary btn-md rounded-1 mt-5">
                                    Comprar Ahora<span className="ms-2"><i className="fas fa-arrow-right"></i></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export { BannerNatural }