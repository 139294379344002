import eventImg from "../../../../public/img/home-5/event-img.png";
import eventImg2 from "../../../../public/img/home-5/event-img-2.png";
import eventImg3 from "../../../../public/img/home-5/event-img-3.png";

function EventCarniceria() {
  return (
    <div className="section-space-sm-top section-space-bottom">
    <div className="section-space-sm-bottom">
      <div className="container">
        <div className="row g-4 align-items-center justify-content-between">
          <div className="col-lg-5 col-xxl-4">
            <h2 className="mb-0 display-6">
              Grostore <span className="meat-primary">Próximos</span> Coches y Eventos
            </h2>
          </div>
          <div className="col-lg-7 col-xl-6">
            <p className="mb-2">
              Sócrates apareció como un personaje en una serie de obras literarias, que van desde sátiras humorísticas
              hasta tragedias y romances históricos. El personaje que más se asemeja al Sócrates de la antigua Atenas.
            </p>
            <a href="#" className="meat-category-card__btn animated-btn-icon clr-primary px-0">
              <span className="meat-category-card__btn-text fw-semibold"> Compra Ahora </span>
              <span className="meat-category-card__btn-icon">
                <i className="fas fa-arrow-right-long"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row g-4">
        <div className="col-md-6 col-lg-4">
          <div className="event-post">
            <div className="event-post__img h-100 rounded-2 overflow-hidden">
              <img src={eventImg} alt="image" className="img-fluid w-100 h-100 object-fit-cover" />
            </div>
            <div className="event-post__overlay">
              <div className="event-post__overlay-content mt-auto">
                <div className="d-flex flex-wrap gap-4 align-items-center mb-4">
                  <div className="d-flex align-items-center gap-1 clr-white">
                    <div className="d-inline-block flex-shrink-0 fs-14">
                      <i className="fas fa-tags"></i>
                    </div>
                    <div className="d-inline-block fs-14">Vegetal Orgánico</div>
                  </div>
                  <div className="d-flex align-items-center gap-1 clr-white">
                    <div className="d-inline-block flex-shrink-0 fs-14">
                      <i className="fas fa-stopwatch"></i>
                    </div>
                    <div className="d-inline-block fs-14">24 de Mayo, 2022</div>
                  </div>
                </div>
                <h2 className="mb-2 fs-24 clr-white">
                  <a href="#" className="link d-inline-block clr-white :clr-primary"> Entrega a Domicilio de Vacaciones que Hemos Pedido Recientemente </a>
                </h2>
                <p className="mb-0 clr-white">
                  Sócrates apareció como un personaje en una serie de obras literarias que van desde sátiras.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <article className="blog-card rounded-2 overflow-hidden bg-white">
            <div className="thumbnail overflow-hidden">
              <a href="#"><img src={eventImg2} alt="blog thumb" className="img-fluid w-100" /></a>
            </div>
            <div className="blog-card-content">
              <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                <span className="fs-xs fw-medium"><i className="fa-solid fa-tags me-1"></i>Vegetal Orgánico</span>
                <span className="fs-xs fw-medium"><i className="fa-regular fa-clock me-1"></i>24 de Mayo, 2022</span>
              </div>
              <a href="#">
                <h4 className="mb-3">Entrega a Domicilio de Vacaciones que Hemos Pedido Recientemente</h4>
              </a>
              <p className="mb-0">Explotar estrategias de crecimiento de inversión en equidad de manera holística.</p>
            </div>
          </article>
        </div>
        <div className="col-md-6 col-lg-4">
          <article className="blog-card rounded-2 overflow-hidden bg-white">
            <div className="thumbnail overflow-hidden">
              <a href="#"><img src={eventImg3} alt="blog thumb" className="img-fluid w-100" /></a>
            </div>
            <div className="blog-card-content">
              <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                <span className="fs-xs fw-medium"><i className="fa-solid fa-tags me-1"></i>Vegetal Orgánico</span>
                <span className="fs-xs fw-medium"><i className="fa-regular fa-clock me-1"></i>24 de Mayo, 2022</span>
              </div>
              <a href="#">
                <h4 className="mb-3">Entrega a Domicilio de Vacaciones que Hemos Pedido Recientemente</h4>
              </a>
              <p className="mb-0">Explotar estrategias de crecimiento de inversión en equidad de manera holística.</p>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
  )
}

export {EventCarniceria}
