import { Header } from "../../components/Home/header/Header"
import logo from "../../../public/img/logo.png";
import cauliflowerXs from "../../../public/img/products/cauliflower-xs.png";
import { Footer } from "../../components/Home/footer/Footer";


function Invoice() {
  return (
    <div>
      <Header />
      <section className="invoice-section pt-6 pb-120">
  <div className="container">
    <div className="invoice-box bg-white rounded p-4 p-sm-6">
      <div className="row g-5 justify-content-between">
        <div className="col-lg-6">
          <div className="invoice-title d-flex align-items-center">
            <h3>Factura</h3>
            <span className="badge rounded-pill bg-primary-light text-primary fw-medium ms-3">Entregado</span>
          </div>
          <table className="invoice-table-sm">
            <tbody>
              <tr>
                <td><strong>N° de Factura</strong></td>
                <td>#FAC-000414</td>
              </tr>
              <tr>
                <td><strong>ID de Envío</strong></td>
                <td>#FAC-000414</td>
              </tr>
              <tr>
                <td><strong>Fecha</strong></td>
                <td>15 de enero, 2023</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-lg-5 col-md-8">
          <div className="text-lg-end">
            <a href="#"><img src={logo} alt="logo" className="img-fluid" /></a>
            <h6 className="mb-0 text-gray mt-4">Cecilia Chapman, 711-2880 Nulla St, Mankato Mississippi 96522</h6>
          </div>
        </div>
      </div>
      <span className="my-6 w-100 d-block border-top"></span>
      <div className="row justify-content-between g-5">
        <div className="col-xl-8 col-lg-7">
          <div className="welcome-message">
            <h4 className="mb-2">Estimado Fethawi Tesfalem</h4>
            <p className="mb-0">Aquí están los detalles de su pedido. Le agradecemos su compra.</p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5">
          <div className="shipping-address">
            <h6 className="mb-2">Dirección de Envío</h6>
            <p className="mb-0">Unidad 1/23 Hastings Road, Melbourne 3000, Victoria, Australia.</p>
          </div>
        </div>
      </div>
      <div className="table-responsive mt-6">
        <table className="table invoice-table">
          <thead>
            <tr>
              <th>SR.</th>
              <th>Productos</th>
              <th>SKU</th>
              <th>Cant.</th>
              <th>Precio Unitario</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td className="text-nowrap">
                <img src={cauliflowerXs} alt="producto" className="img-fluid product-item" />
                <span className="ms-2">Strawberry Green Magnetic</span>
              </td>
              <td>04928001</td>
              <td>02</td>
              <td>$240.00</td>
              <td>$480.00</td>
            </tr>
            <tr>
              <td>2</td>
              <td className="text-nowrap">
                <img src={cauliflowerXs} alt="producto" className="img-fluid product-item" />
                <span className="ms-2">Strawberry Green Magnetic</span>
              </td>
              <td>04928001</td>
              <td>02</td>
              <td>$240.00</td>
              <td>$480.00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-4 table-responsive">
        <table className="table footer-table">
          <tbody>
            <tr>
              <td>
                <strong className="text-dark d-block text-nowrap">Método de Pago</strong>
                <span>Paypal</span>
              </td>
              <td>
                <strong className="text-dark d-block text-nowrap">Costo de Envío</strong>
                <span>$60.00</span>
              </td>
              <td>
                <strong className="text-dark d-block text-nowrap">Descuento</strong>
                <span>$0.00</span>
              </td>
              <td>
                <strong className="text-dark d-block text-nowrap">Precio Total</strong>
                <span>$580.00</span>
              </td>
              <td>
                <strong className="text-dark d-block text-nowrap">Monto Total</strong>
                <span className="text-primary fw-bold">$580.00</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-3 mt-7">
        <a href="#" className="btn btn-primary btn-md">Facturación</a>
        <a href="#" className="btn btn-primary-light btn-md">Descargar Factura</a>
      </div>
    </div>
  </div>
</section>

<Footer />

    </div>
  )
}

export {Invoice}
