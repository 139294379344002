
function ProductHome() {
    return (
        <div className="section-space-sm-y">
            <div className="medicine-product-section section-space-y">
                <div className="section-space-sm-bottom">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-xl-5 col-xxl-6">
                                <h2 className="mb-0 display-6" style={{fontWeight: "700", color:"#191d28"}}>Medicina y Productos de Salud</h2>
                            </div>
                            <div className="col-xl-7 col-xxl-6">
                                <div className="row g-4 align-items-center">
                                    <div className="col-md-9">
                                        <p className="mb-0">
                                            productos médicos innovadores y tecnología avanzada en el cuidado de la salud.
                                            Proporciona eficientemente soluciones de salud de alta calidad. Integra continuamente
                                            herramientas y recursos médicos para un mejor bienestar.
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="text-lg-end">
                                            <div className="dropdown">
                                                <button className="button button-outline button-md button--primary dropdown-toggle fw-semibold" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: "#00b1b8", border:"3px solid #00b1b8", fontWeight:"700"}}>
                                                    Cuidado de la Salud
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Multivitamínico</a></li>
                                                    <li><a className="dropdown-item" href="#">Cuidado de la Piel</a></li>
                                                    <li><a className="dropdown-item" href="#">Proteínas</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-4 col-xxl-3">
                            <div className="meat-card">
                                <div className="meat-card__img text-center">
                                    <a href="#" className="link d-inline-block text-center">
                                        <img src="img/home-6/product-1.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-contain" />
                                    </a>
                                </div>
                                <div className="meat-card__body">
                                    <span className="meat-card__subtitle" style={{color: "#00b1b8"}}> Nutrición Deportiva </span>
                                    <h6 className="meat-card__title">
                                        <a href="#" className="link meat-card__title-link" style={{color:"#191d28", fontWeight:"700", lineHeight:"1.2"}}>
                                            Crema ACM Depi White Day SPF 20 (40ml)
                                        </a>
                                    </h6>
                                    <ul className="list list--row">
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li className="ms-2">
                                            <span className="d-inline-block fs-12 fw-semibold clr-heading">
                                                4.5 ( 1k Reseñas )
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <a href="#" className="link d-inline-block py-2 px-3 text-center rounded-1 primary-bg bg-opacity-1 :bg-opacity-100 clr-primary :clr-light fw-semibold" style={{color: "#00b1b8", background:"#00b1b81a"}}>
                                            Agregar al Carrito
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xxl-3">
                            <div className="meat-card">
                                <div className="meat-card__img text-center">
                                    <a href="#" className="link d-inline-block text-center">
                                        <img src="img/home-6/product-2.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-contain" />
                                    </a>
                                </div>
                                <div className="meat-card__body">
                                    <span className="meat-card__subtitle" style={{color: "#00b1b8"}}> Nutrición Deportiva </span>
                                    <h6 className="meat-card__title">
                                        <a href="#" className="link meat-card__title-link"  style={{color:"#191d28", fontWeight:"700", lineHeight:"1.3"}}>
                                            Multivitamínicos One A Day Hombres 50+
                                        </a>
                                    </h6>
                                    <ul className="list list--row">
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li className="ms-2">
                                            <span className="d-inline-block fs-12 fw-semibold clr-heading">
                                                4.5 ( 1k Reseñas )
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <a href="#" className="link d-inline-block py-2 px-3 text-center rounded-1 primary-bg bg-opacity-1 :bg-opacity-100 clr-primary :clr-light fw-semibold" style={{color: "#00b1b8", background:"#00b1b81a"}}>
                                            Agregar al Carrito
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xxl-3">
                            <div className="meat-card">
                                <div className="meat-card__img text-center">
                                    <a href="#" className="link d-inline-block text-center">
                                        <img src="img/home-6/product-3.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-contain" />
                                    </a>
                                </div>
                                <div className="meat-card__body">
                                    <span className="meat-card__subtitle" style={{color: "#00b1b8"}}> Nutrición Deportiva </span>
                                    <h6 className="meat-card__title">
                                        <a href="#" className="link meat-card__title-link"  style={{color:"#191d28", fontWeight:"700", lineHeight:"1.3"}}>
                                            Multivitamínicos One A Day Hombres 50+
                                        </a>
                                    </h6>
                                    <ul className="list list--row">
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li className="ms-2">
                                            <span className="d-inline-block fs-12 fw-semibold clr-heading">
                                                4.5 ( 1k Reseñas )
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <a href="#" className="link d-inline-block py-2 px-3 text-center rounded-1 primary-bg bg-opacity-1 :bg-opacity-100 clr-primary :clr-light fw-semibold" style={{color: "#00b1b8", background:"#00b1b81a"}}>
                                            Agregar al Carrito
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xxl-3">
                            <div className="meat-card">
                                <div className="meat-card__img text-center">
                                    <a href="#" className="link d-inline-block text-center">
                                        <img src="img/home-6/product-4.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-contain" />
                                    </a>
                                </div>
                                <div className="meat-card__body">
                                    <span className="meat-card__subtitle" style={{color: "#00b1b8"}}> Nutrición Deportiva </span>
                                    <h6 className="meat-card__title">
                                        <a href="#" className="link meat-card__title-link"  style={{color:"#191d28", fontWeight:"700", lineHeight:"1.3"}}>
                                            Cuidado de la Piel Loreal Paris (200ml)
                                        </a>
                                    </h6>
                                    <ul className="list list--row">
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li className="ms-2">
                                            <span className="d-inline-block fs-12 fw-semibold clr-heading">
                                                4.5 ( 1k Reseñas )
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <a href="#" className="link d-inline-block py-2 px-3 text-center rounded-1 primary-bg bg-opacity-1 :bg-opacity-100 clr-primary :clr-light fw-semibold" style={{color: "#00b1b8", background:"#00b1b81a"}}>
                                            Agregar al Carrito
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xxl-3">
                            <div className="meat-card">
                                <div className="meat-card__img text-center">
                                    <a href="#" className="link d-inline-block text-center">
                                        <img src="img/home-6/product-5.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-contain" />
                                    </a>
                                </div>
                                <div className="meat-card__body">
                                    <span className="meat-card__subtitle" style={{color: "#00b1b8"}}> Nutrición Deportiva </span>
                                    <h6 className="meat-card__title">
                                        <a href="#" className="link meat-card__title-link"  style={{color:"#191d28", fontWeight:"700", lineHeight:"1.3"}}>
                                            Multivitamínicos One A Day Mujeres 50+
                                        </a>
                                    </h6>
                                    <ul className="list list--row">
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li className="ms-2">
                                            <span className="d-inline-block fs-12 fw-semibold clr-heading">
                                                4.5 ( 1k Reseñas )
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <a href="#" className="link d-inline-block py-2 px-3 text-center rounded-1 primary-bg bg-opacity-1 :bg-opacity-100 clr-primary :clr-light fw-semibold" style={{color: "#00b1b8", background:"#00b1b81a"}}>
                                            Agregar al Carrito
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xxl-3">
                            <div className="meat-card">
                                <div className="meat-card__img text-center">
                                    <a href="#" className="link d-inline-block text-center">
                                        <img src="img/home-6/product-6.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-contain" />
                                    </a>
                                </div>
                                <div className="meat-card__body">
                                    <span className="meat-card__subtitle" style={{color: "#00b1b8"}}> Nutrición Deportiva </span>
                                    <h6 className="meat-card__title">
                                        <a href="#" className="link meat-card__title-link"  style={{color:"#191d28", fontWeight:"700", lineHeight:"1.3"}}>
                                            Multivitamínicos One A Day Mujeres 50+
                                        </a>
                                    </h6>
                                    <ul className="list list--row">
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li className="ms-2">
                                            <span className="d-inline-block fs-12 fw-semibold clr-heading">
                                                4.5 ( 1k Reseñas )
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <a href="#" className="link d-inline-block py-2 px-3 text-center rounded-1 primary-bg bg-opacity-1 :bg-opacity-100 clr-primary :clr-light fw-semibold" style={{color: "#00b1b8", background:"#00b1b81a"}}>
                                            Agregar al Carrito
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xxl-3">
                            <div className="meat-card">
                                <div className="meat-card__img text-center">
                                    <a href="#" className="link d-inline-block text-center">
                                        <img src="img/home-6/product-7.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-contain" />
                                    </a>
                                </div>
                                <div className="meat-card__body">
                                    <span className="meat-card__subtitle" style={{color: "#00b1b8"}}> Nutrición Deportiva </span>
                                    <h6 className="meat-card__title">
                                        <a href="#" className="link meat-card__title-link"  style={{color:"#191d28", fontWeight:"700", lineHeight:"1.3"}}>
                                            Vitamina D3 2000 IU (60 cápsulas)
                                        </a>
                                    </h6>
                                    <ul className="list list--row">
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li className="ms-2">
                                            <span className="d-inline-block fs-12 fw-semibold clr-heading">
                                                4.5 ( 1k Reseñas )
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <a href="#" className="link d-inline-block py-2 px-3 text-center rounded-1 primary-bg bg-opacity-1 :bg-opacity-100 clr-primary :clr-light fw-semibold" style={{color: "#00b1b8", background:"#00b1b81a"}}>
                                            Agregar al Carrito
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xxl-3">
                            <div className="meat-card">
                                <div className="meat-card__img text-center">
                                    <a href="#" className="link d-inline-block text-center">
                                        <img src="img/home-6/product-8.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-contain" />
                                    </a>
                                </div>
                                <div className="meat-card__body">
                                    <span className="meat-card__subtitle" style={{color: "#00b1b8"}}> Nutrición Deportiva </span>
                                    <h6 className="meat-card__title">
                                        <a href="#" className="link meat-card__title-link"  style={{color:"#191d28", fontWeight:"700", lineHeight:"1.3"}}>
                                            Omega-3 Pescado Aceite 1000 mg (200 cápsulas)
                                        </a>
                                    </h6>
                                    <ul className="list list--row">
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="meat-card__star d-inline-block">
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </li>
                                        <li className="ms-2">
                                            <span className="d-inline-block fs-12 fw-semibold clr-heading">
                                                4.5 ( 1k Reseñas )
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="mt-4">
                                        <a href="#" className="link d-inline-block py-2 px-3 text-center rounded-1 primary-bg bg-opacity-1 :bg-opacity-100 clr-primary :clr-light fw-semibold" style={{color: "#00b1b8", background:"#00b1b81a"}}>
                                            Agregar al Carrito
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ProductHome }