import React, { useState, useEffect } from 'react';
import banner1 from '../../../public/img/banner/banner-1.jpg';
import banner2 from '../../../public/img/banner/banner-2.jpg';
import banner5 from '../../../public/img/banner/banner-5.jpg';
import { Footer } from '../../components/Home/footer/Footer';
import { Header } from '../../components/Home/header/Header';
import { BreadCrumb } from '../../components/productsView/breadCrumb/BreadCrumb';

interface CountdownTimerProps {
  date: Date;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ date }) => {
  // Define la función antes de su uso
  const calculateRemainingTime = (date: Date): number => {
    const now = new Date();
    return Math.max(date.getTime() - now.getTime(), 0);
  };

  const [remainingTime, setRemainingTime] = useState<number>(calculateRemainingTime(date));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(calculateRemainingTime(date));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  const seconds = Math.floor(remainingTime / 1000) % 60;
  const minutes = Math.floor(remainingTime / (1000 * 60)) % 60;
  const hours = Math.floor(remainingTime / (1000 * 60 * 60)) % 24;
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));

  return (
    <ul className="timing-countdown countdown-timer d-inline-block gap-2 mt-3">
      <li className="list-inline-item bg-white position-relative z-1 rounded-2">
        <h5 className="mb-1 days fs-sm">{days}</h5>
        <span className="gshop-subtitle fs-xxs d-block">Days</span>
      </li>
      <li className="list-inline-item bg-white position-relative z-1 rounded-2">
        <h5 className="mb-1 hours fs-sm">{hours}</h5>
        <span className="gshop-subtitle fs-xxs d-block">Hours</span>
      </li>
      <li className="list-inline-item bg-white position-relative z-1 rounded-2">
        <h5 className="mb-1 minutes fs-sm">{minutes}</h5>
        <span className="gshop-subtitle fs-xxs d-block">Minutes</span>
      </li>
      <li className="list-inline-item bg-white position-relative z-1 rounded-2">
        <h5 className="mb-1 seconds fs-sm">{seconds}</h5>
        <span className="gshop-subtitle fs-xxs d-block">Seconds</span>
      </li>
    </ul>
  );
};

function AllCoupons() {
  const coupons = [
    { img: banner5, code: 'GTSMAR23', date: '06/30/2023 23:59:59' },
    { img: banner2, code: 'GTSMAR23', date: '06/30/2023 23:59:59' },
    { img: banner1, code: 'GTSMAR24', date: '06/30/2023 23:59:59' }
  ];

  return (
    <div>
      <Header />
      <BreadCrumb title="Todos los cupones" pageName="Página" currentPage="cupones" />
      <section className="tt-campaigns ptb-100">
        <div className="container">
          <div className="row g-4 justify-content-center">
            {coupons.map((coupon, index) => (
              <div className="col-lg-6 col-md-6" key={index}>
                <div
                  className="card shadow-lg border-0 tt-coupon-single tt-gradient-top"
                  style={{ background: `url(${coupon.img}) no-repeat center center / cover` }}
                >
                  <div className="card-body text-center p-5">
                    <div className="offer-text mb-2 justify-content-center">
                      <div className="up-to fw-bold text-light">UP TO</div>
                      <div className="display-4 fw-bold text-danger">75</div>
                      <p className="mb-0 fw-bold text-danger"><span>%</span> <br /> Off</p>
                    </div>
                    <div className="coupon-row">
                      <span className="copyCode">{coupon.code}</span>
                      <span className="copyBtn">Copy Code</span>
                    </div>
                    <h5 className="text-light">Valid Till:</h5>
                    <CountdownTimer date={new Date(coupon.date)} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export { AllCoupons };
