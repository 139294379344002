
import "./EditWeb.css"


function EditWeb() {

  return (
    <div className="tab-pane fade active show edit" id="order-history" role="tabpanel">
      <div className="payment-methods bg-white rounded py-5 px-4">
        <div className="update-profile bg-white py2 px-4">
          <h6 className="mb-4 text-dark fw-bold">Editar colores</h6>
          <form className="profile-form">
            <div className="row g-4">
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Color primario</label>
                  <input type="text" placeholder="45ff3f" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Color Segundario</label>
                  <input type="text" placeholder="66fff5" />
                </div>
              </div>
            </div>
            <button type="submit" className="btn  mt-6" style={{ backgroundColor: "#6eb356", color: "white", fontWeight: "500", padding: "13px" }}>
              Actualizar Colores
            </button>
          </form>
        </div>
      </div>

      <div className="payment-methods bg-white rounded py-5 px-4">
        <div className="update-profile bg-white py2 px-4">
          <h6 className="mb-4 text-dark fw-bold">Informacion de header</h6>
          <form className="profile-form">
            <div className="row g-4">
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Mensaje de Bievenidad</label>
                  <input type="text" placeholder="Bienvenido a nuestra tienda orgánica" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Correo Electronico</label>
                  <input type="text" placeholder="groshop@support.com" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Ubicacion</label>
                  <input type="text" placeholder="Washington, New York, USA - 254230" />
                </div>
              </div>
            </div>
            <button type="submit" className="btn  mt-6" style={{ backgroundColor: "#6eb356", color: "white", fontWeight: "500", padding: "13px" }}>
              Actualizar Header
            </button>
          </form>
        </div>
      </div>

      <div className="payment-methods bg-white rounded py-5 px-4">
        <div className="update-profile bg-white py2 px-4">
          <div className="configuraSlider">
            <h6 className="mb-4 text-dark fw-bold">Slider</h6>
            <div className="accionSlider">
              <div className="container">
                <div className="label-input-field">
                  <label form="sliderOptions">Sliders</label>
                  <select id="sliderOptions">
                    <option>Slider 1</option>
                    <option>Slider 2</option>
                    <option>Slider 3</option>
                  </select>
                </div>
              </div>
              <div className="iconAccion">

                <button type="submit" className="btn  " style={{ backgroundColor: "#6eb356", color: "white", fontWeight: "500", width: "150px" }}>
                  Agregar slider
                </button>
                <button type="submit" className="btn  " style={{ backgroundColor: "red", color: "white", fontWeight: "500", width: "150px" }}>
                  Borrar slider
                </button>
              </div>
            </div>

          </div>

          <form className="profile-form">
            <div className="row g-4">
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Subtitulo</label>
                  <input type="text" placeholder="Productos Genuinos 100% Orgánicos" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Titulo</label>
                  <input type="text" placeholder="Tienda de comestibles Productos" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Descripcion</label>
                  <input type="text" placeholder="Apunte asertivamente al capital intelectual impulsado por el mercado con un capital humano holístico a nivel mundial." />
                </div>
              </div>
              <div className="file-upload text-center rounded-3 mb-5">
                <input type="file" name="dp" />
                <img src="img/icons/image.svg" alt="dp" className="img-fluid" />
                <p className="text-dark fw-bold mb-2 mt-3">
                  Sube tu imagen aquí o <a href="#" className="enlace colorIcon">examinar</a>
                </p>
                <p className="mb-0 file-name">
                  (Solo se aceptarán imágenes *.jpeg y *.png)
                </p>
              </div>
            </div>
            <button type="submit" className="btn  mt-6" style={{ backgroundColor: "#6eb356", color: "white", fontWeight: "500", padding: "13px" }}>
              Actualizar Slider
            </button>
          </form>
        </div>
      </div>

      <div className="payment-methods bg-white rounded py-5 px-4">
        <div className="update-profile bg-white py2 px-4">
          <h6 className="mb-4 text-dark fw-bold">Redes Sociales</h6>
          <form className="profile-form">
            <div className="row g-4">
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Enlace de Facebook</label>
                  <input type="text" placeholder="https://www.facebook.com/profile.php?id=10008166" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Enlace de Twitter</label>
                  <input type="text" placeholder="https://www.twitter.com/profile.php?id=10008166" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Enlace de Linkedin</label>
                  <input type="text" placeholder="https://www.twitter.com/profile.php?id=10008166" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="label-input-field">
                  <label>Enlace de Youtube</label>
                  <input type="text" placeholder="https://www.youtube.com/profile.php?id=10008166" />
                </div>
              </div>
            </div>
            <button type="submit" className="btn  mt-6" style={{ backgroundColor: "#6eb356", color: "white", fontWeight: "500", padding: "13px" }}>
              Actualizar redes
            </button>
          </form>
        </div>
      </div>

      <div className="payment-methods bg-white rounded py-5 px-4">
        <div className="update-profile bg-white py2 px-4">
          <h6 className="mb-4 text-dark fw-bold">Promociones</h6>
          <form className="profile-form">

          </form>
        </div>
      </div>

    </div>
  )
}

export { EditWeb }