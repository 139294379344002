import { Header } from '../../components/Home/header/Header'
import { BreadCrumb } from '../../components/productsView/breadCrumb/BreadCrumb'
import team1 from "../../../public/img/authors/team-1.jpg";
import team2 from "../../../public/img/authors/team-2.jpg";
import { Footer } from '../../components/Home/footer/Footer';

function Team() {
  return (
    <div>
      <Header />
      <BreadCrumb title="Miembro de nuestro equipo" pageName="Página" currentPage="Equipo" />
      <section className="team-members ptb-120">
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-xl-5">
                <div className="section-title text-center">
                <h2 className="mb-0">Nuestro equipo de soporte en línea</h2>
                </div>
            </div>
            </div>
            <div className="row g-4 mt-5 justify-content-center">
            {[
                { nombre: "Frances Gilmartin", cargo: "CEO y Fundadora", img: team1 },
                { nombre: "Frances Gilmartin", cargo: "CEO y Fundadora", img: team2 },
                { nombre: "Frances Gilmartin", cargo: "CEO y Fundadora", img: team1 },
                { nombre: "Frances Gilmartin", cargo: "CEO y Fundadora", img: team2 },
                { nombre: "Frances Gilmartin", cargo: "CEO y Fundadora", img: team1 },
                { nombre: "Frances Gilmartin", cargo: "CEO y Fundadora", img: team2 },
                { nombre: "Frances Gilmartin", cargo: "CEO y Fundadora", img: team1 },
                { nombre: "Frances Gilmartin", cargo: "CEO y Fundadora", img: team2 }
            ].map((miembro, index) => (
                <div key={index} className="col-xl-3 col-lg-4 col-sm-6">
                <div className="team-card text-center bg-white rounded py-7 px-4">
                    <div className="team-thumb mb-5">
                    <img src={miembro.img} alt="team" className="img-fluid rounded-circle" />
                    <div className="team-social">
                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fab fa-behance"></i></a>
                    </div>
                    </div>
                    <h5>{miembro.nombre}</h5>
                    <span>{miembro.cargo}</span>
                </div>
                </div>
            ))}
            </div>
            <div className="text-center mt-7">
            <a href="#" className="btn btn-secondary btn-md rounded-1">Más Miembros</a>
            </div>
        </div>
        </section>

        <Footer />

    </div>
  )
}

export {Team}
