import React from 'react';
import "../../style/global.css";

interface CategoryItemProps {
  imgSrc: string;
  title: string;
  overlayTitle: string;
  overlayText: string;
  active: boolean;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ imgSrc, title, overlayTitle, overlayText, active }) => {
  return (
    <div className={`popular-category__item ${active ? 'active' : ''}`}>
      <div className="popular-category__container">
        <div className="popular-category__img">
          <img src={imgSrc} alt="imagen" className="img-fluid" />
        </div>
        <div className="popular-category__content">
          <h5 className="popular-category__content-title">{title}</h5>
        </div>
        <div className="popular-category__overlay">
          <h5 className="popular-category__overlay-title">{overlayTitle}</h5>
          <p className="popular-category__overlay-para">
            {overlayText}
          </p>
          <a href="#" className="popular-category__overlay-btn">
            <img src="img/home-6/icon-arrow.png" alt="imagen" className="img-fluid" />
          </a>
        </div>
      </div>
    </div>
  );
}

const Category: React.FC = () => {
  return (
    <div className="section-space-sm-y">
      <div className="section-space-xsm-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-xl-8">
              <h2 className="text-center mb-0 display-6" style={{fontWeight:"700"}}>Categoría Más Popular</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="popular-category">
              <CategoryItem 
                imgSrc="img/home-6/category-img-1.png"
                title="Cuidado Personal"
                overlayTitle="Cuidado Personal"
                overlayText="Desbloquee distintivamente tecnologías empresariales sin backend. Integre continuamente compatibles hacia atrás."
                active={false}
              />
              <CategoryItem 
                imgSrc="img/home-6/category-img-2.png"
                title="Cuidado Femenino"
                overlayTitle="Cuidado Femenino"
                overlayText="Desbloquee distintivamente tecnologías empresariales sin backend. Integre continuamente compatibles hacia atrás."
                active={false}
              />
              <CategoryItem 
                imgSrc="img/home-6/category-img-1.png" 
                title="Bebé y Mamá" 
                overlayTitle="Bebé y Mamá" 
                overlayText="Mejore la experiencia con productos especializados que promueven la salud y el bienestar integral, facilitando cada etapa con seguridad y confianza absolutas."
                active={true}
              />
              <CategoryItem 
                imgSrc="img/home-6/category-img-3.png"
                title="Cuidado Diabético"
                overlayTitle="Cuidado Diabético"
                overlayText="Desbloquee distintivamente tecnologías empresariales sin backend. Integre continuamente compatibles hacia atrás."
                active={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Category };
