import promoBg from "../../../../public/img/home-5/promo-bg.png";

function CounterPromoCarniceria() {
  return (
    <div className="counter-promo">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-7">
          <div 
            className="promo-info-wrap d-flex align-items-center justify-content-around p-4 bg-overlay-img rounded" 
            style={{ background: `url(${promoBg}), no-repeat center top` }}
          >
            <div className="singl-promo-info text-center text-light">
              <span className="fw-bolder display-4">345+</span>
              <strong className="d-block">Toneladas de Carne Cada Mes</strong>
            </div>
            <div className="singl-promo-info text-center text-light">
              <span className="fw-bolder display-4">345+</span>
              <strong className="d-block">Toneladas de Carne Cada Mes</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CounterPromoCarniceria
