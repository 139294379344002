function FeedbackCarne() {
  return (
    <section className="hl-feedback">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-5">
          <div className="text-center">
            <span className="hl-subtitle px-4 fw-bold">Comentarios de Clientes</span>
            <h2 className="mb-0 mt-2">Comentarios de Nuestros Clientes Satisfechos</h2>
          </div>
        </div>
      </div>
      <div className="hl-feedback-slider py-7" style={{ overflow: "hidden", overflowX:"auto"}}>
        <div className="swiper-wrapper">
          <div className="hl-feedback-single bg-white rounded py-5 px-4 swiper-slide" style={{width: "400px", marginRight: "50px"}}>
            <h5 className="mb-3">La Mejor Tienda Halal Online</h5>
            <p className="mb-3">Mejoras en lugar de metodologías covalentes. Negocie completamente redes precisas con innovación de clics y mortero.</p>
            <div className="clients_info">
              <h6 className="fs-md mb-1">Animul Islam</h6>
              <ul className="rating-star text-warning d-flex">
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
              </ul>
            </div>
          </div>

          <div className="hl-feedback-single bg-white rounded py-5 px-4 swiper-slide" style={{width: "400px", marginRight: "50px"}}>
            <h5 className="mb-3">La Mejor Tienda Halal Online</h5>
            <p className="mb-3">Mejoras en lugar de metodologías covalentes. Negocie completamente redes precisas con innovación de clics y mortero.</p>
            <div className="clients_info">
              <h6 className="fs-md mb-1">Animul Islam</h6>
              <ul className="rating-star text-warning d-flex">
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
              </ul>
            </div>
          </div>

          <div className="hl-feedback-single bg-white rounded py-5 px-4 swiper-slide" style={{width: "400px", marginRight: "50px"}}>
            <h5 className="mb-3">La Mejor Tienda Halal Online</h5>
            <p className="mb-3">Mejoras en lugar de metodologías covalentes. Negocie completamente redes precisas con innovación de clics y mortero.</p>
            <div className="clients_info">
              <h6 className="fs-md mb-1">Animul Islam</h6>
              <ul className="rating-star text-warning d-flex">
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
              </ul>
            </div>
          </div>

          <div className="hl-feedback-single bg-white rounded py-5 px-4 swiper-slide" style={{width: "400px", marginRight: "50px"}}>
            <h5 className="mb-3">La Mejor Tienda Halal Online</h5>
            <p className="mb-3">Mejoras en lugar de metodologías covalentes. Negocie completamente redes precisas con innovación de clics y mortero.</p>
            <div className="clients_info">
              <h6 className="fs-md mb-1">Animul Islam</h6>
              <ul className="rating-star text-warning d-flex">
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
                <li><i className="fas fa-star"></i></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default FeedbackCarne;
