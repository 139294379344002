import Texture from "../../../../public/img/shapes/texture.png";
import Object1 from "../../../../public/img/shapes/object-1.png";
import Object2 from "../../../../public/img/shapes/object-2.png";
import heroGirl from "../../../../public/img/home-2/hero-girl.png";

function Slider() {
    return (
        <section className="organic-hero position-relative overflow-hidden z-1">
            <span className="overlay-texture w-100 h-100 position-absolute start-0 top-0" style={{ backgroundImage: `url(${Texture})` }}></span>
            <img src={Object1} alt="object" className="position-absolute object-1" />
            <img src={Object2} alt="object" className="position-absolute object-2" />
            <div className="container">
                <div className="row align-items-center justify-content-between g-5">
                    <div className="col-xxl-5 col-xl-6 col-lg-9">
                        <div className="organic-hero-left position-relative z-1">
                            <div className="d-flex align-items-center gap-2 flex-wrap">
                                <h5 className="mb-0 gshop-subtitle fw-normal text-secondary">100% Alimentos Orgánicos</h5>
                                <span>
                                    <svg width="138" height="16" viewBox="0 0 138 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line y1="8" x2="132" y2="8" stroke="#FF7C08" strokeWidth="2"></line>
                                        <path d="M138 8L126 14.9282L126 1.0718L138 8Z" fill="#FF7C08"></path>
                                    </svg>
                                </span>
                            </div>
                            <h1 className="mt-2 mb-3 display-4">Cuidado de la Salud Natural y Orgánico</h1>
                            <p className="mb-7">Dirija de manera asertiva el capital intelectual impulsado por el mercado con capital humano global. Sistemas progresivos con enfoque holístico.</p>
                            <div className="apps-btn-group gap-4 d-flex align-items-center flex-wrap">
                                <a href="#" className="app-btn btn-secondary d-inline-flex align-items-center">
                                    <span className="icon me-2"><i className="fa-brands fa-google-play"></i></span>
                                    <div className="app-btn-content text-start">
                                        <span>Obtenlo en</span>
                                        <strong>Google Play</strong>
                                    </div>
                                </a>
                                <a href="#" className="app-btn btn-black d-inline-flex align-items-center">
                                    <span className="icon me-2"><i className="fa-brands fa-apple"></i></span>
                                    <div className="app-btn-content text-start">
                                        <span>Obtenlo en</span>
                                        <strong>App Store</strong>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="hero-right text-center position-relative z-1">
                            <img src={heroGirl} alt="girl" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="at-header-social d-none d-sm-flex align-items-center position-absolute">
                <span className="title fw-medium">Síguenos en</span>
                <ul className="social-list ms-3">
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                </ul>
            </div>
        </section>
    )
}

export { Slider }