import mango from "../../../../public/img/shapes/mango.png";
import ab1 from "../../../../public/img/about/ab-1.png";
import handIcon from "../../../../public/img/icons/hand-icon.svg";

function AboutNatural() {
    return (
        <section className="pt-120 ab-about-section position-relative z-1 overflow-hidden">
            <img src={mango} alt="mango" className="position-absolute mango z--1" />
            <div className="container">
                <div className="row g-5 g-xl-4 align-items-center">
                    <div className="col-xl-6">
                        <div className="ab-left position-relative">
                            <img src={ab1} alt="image" className="img-fluid" />
                            <div className="text-end">
                                <div className="ab-quote p-4 text-start">
                                    <h4 className="mb-0 fw-normal text-white">
                                        “¡Me encanta esta tienda natural! Siempre encuentro productos frescos y saludables aquí. Es genial saber que puedo confiar en la calidad.”
                                        <span className="fs-md fw-medium position-relative">George Scholll</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="ab-about-right">
                            <div className="subtitle d-flex align-items-center gap-3 flex-wrap">
                                <span className="gshop-subtitle">Alimentos 100% Orgánicos</span>
                                <span>
                                    <svg width="78" height="16" viewBox="0 0 78 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.0138875" y1="7.0001" x2="72.0139" y2="8.0001" stroke="#FF7C08" strokeWidth="2"></line>
                                        <path d="M78 8L66 14.9282L66 1.0718L78 8Z" fill="#FF7C08"></path>
                                    </svg>
                                </span>
                            </div>
                            <h2 className="mb-4">
                                Sé saludable y come fresco<br /> comida orgánica
                            </h2>
                            <p className="mb-8">
                                Apunta al mercado de manera asertiva. El lorem ipsum es simplemente un texto gratuito disponible, dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore.
                            </p>
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div className="image-box py-6 px-4 image-box-border">
                                        <div className="icon position-relative">
                                            <img src={handIcon} alt="icono de mano" className="img-fluid" />
                                        </div>
                                        <h4 className="mt-3">Nuestra Misión</h4>
                                        <p className="mb-0">Transformar continuamente las metodologías virtuales. Aprovechar las alineaciones existentes.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image-box py-6 px-4 image-box-border">
                                        <div className="icon position-relative">
                                            <img src={handIcon} alt="icono de mano" className="img-fluid" />
                                        </div>
                                        <h4 className="mt-3">Nuestra Visión</h4>
                                        <p className="mb-0">Transformar continuamente las metodologías virtuales. Aprovechar las alineaciones existentes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export { AboutNatural }