import loginBanner from "../../../public/img/banner/login-banner.jpg";
import logo from "../../../public/img/logo.png";
import googleLogo from "../../../public/img/brands/google.png";

function Register() {
  return (
    <div>
      <section className="login-section py-5">
  <div className="container">
    <div className="row justify-content-center">
      <div
        className="col-lg-5 col-12 tt-login-img"
        data-background="assets/img/banner/login-banner.jpg"
        style={{ backgroundImage: `url(${loginBanner})` }}
      ></div>
      <div className="col-lg-5 col-12 bg-white d-flex p-0 tt-login-col shadow">
        <form className="tt-login-form-wrap p-3 p-md-6 p-lg-6 py-7 w-100">
          <div className="text-center mb-7">
            <a href="index.html">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <h4 className="mb-3">Comienza completamente gratis</h4>
          <p className="fs-xs">
            ¿Ya tienes una cuenta?{' '}
            <a href="login.html" className="text-secondary">
              Inicia sesión
            </a>
          </p>
          <div className="row g-3">
            <div className="col-sm-6">
              <div className="input-field">
                <input
                  type="text"
                  placeholder="Nombre"
                  className="theme-input"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="input-field">
                <input
                  type="text"
                  placeholder="Apellido"
                  className="theme-input"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="input-field">
                <input
                  type="email"
                  placeholder="Correo electrónico"
                  className="theme-input"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="input-field check-password">
                <input
                  type="password"
                  placeholder="Contraseña"
                  className="theme-input"
                />
                <span className="eye eye-icon">
                  <i className="fa-solid fa-eye"></i>
                </span>
                <span className="eye eye-slash">
                  <i className="fa-solid fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row g-4 mt-4">
            <div className="col-sm-6">
              <button type="submit" className="btn btn-primary w-100">
                Crear cuenta
              </button>
            </div>
            <div className="col-sm-6">
              <a href="#" className="btn btn-outline google-btn w-100">
                <img
                  src={googleLogo}
                  alt="google"
                  className="me-2"
                />
                Iniciar con Google
              </a>
            </div>
          </div>
          <p className="mb-0 fs-xxs mt-4 text-center">
            Al registrarme, acepto los{' '}
            <a href="#" className="text-dark">
              Términos de uso y Política de privacidad
            </a>
          </p>
        </form>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export {Register}
