import heroImg1 from "../../../../public/img/home-7/hero-img-1.png";

function SliderMueble() {
  return (
    <div className="hero-7">
      <div className="swiper hero-7__slider swiper-fade swiper-initialized swiper-horizontal swiper-watch-progress swiper-backface-hidden">
        <div className="swiper-wrapper" id="swiper-wrapper-b5e6cc7f49e63ecd" aria-live="polite">
          <div className="swiper-slide swiper-slide-duplicate swiper-slide-prev" data-swiper-slide-index="2" role="group" aria-label="3 / 3">
            <div className="hero-7__slider-item">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="hero-7__slider-inner">
                      <div className="row">
                        <div className="col-lg-6">
                          <h1 className="hero-7__slider-title mb-12">
                          Único y con estilo
                            <span className="d-inline-block clr-primary text-decoration" style={{color:"#6DB356"}}>
                            Muebles
                              <svg
                                width="350"
                                height="18"
                                className="text-decoration__img"
                                viewBox="0 0 350 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path 
                                  d="M0.760582 17.8078C58.2735 11.9812 115.591 6.4118 174.037 5.89076C231.128 5.36082 287.619 7.7998 344.092 12.953C350.861 13.5145 350.908 6.40578 344.14 5.71507C286.505 0.554238 227.686 -0.995243 169.616 1.33782C113.094 3.55181 55.3997 7.56765 0.569619 17.4188C0.181752 17.5455 0.373564 17.8052 0.760582 17.8078Z"
                                  fill="#6DB356" 
                                />
                              </svg>
                            </span>
                          </h1>
                          <p className="hero-7__slider-para fs-18 mb-12 text-wrap-balance">
                          Diríjase asertivamente al capital intelectual impulsado por el mercado con humano es simplemente un concurso de texto gratuito Bibendum holístico.
                          </p>
                          <a href="#" className="link button button-effect button-effect--primary" style={{background:"#FF7C0A", color:"white"}}>
                            <span className="d-inline-block fw-semibold"> Comprar Ahora </span>
                            <span className="d-inline-block">
                              <i className="fas fa-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-7__slider-img d-none d-lg-block">
                <img src={heroImg1} alt="image" className="img-fluid w-100 h-100 object-fit-contain" />
              </div>
            </div>
          </div>

          <div className="swiper-slide swiper-slide-visible swiper-slide-active" data-swiper-slide-index="0" role="group" aria-label="1 / 3">
            <div className="hero-7__slider-item">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="hero-7__slider-inner">
                      <div className="row">
                        <div className="col-lg-6">
                          <h1 className="hero-7__slider-title mb-12">
                            Unique & Stylish
                            <span className="d-inline-block clr-primary text-decoration">
                              Furniture
                              <svg
                                width="350"
                                height="18"
                                className="text-decoration__img"
                                viewBox="0 0 350 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path 
                                  d="M0.760582 17.8078C58.2735 11.9812 115.591 6.4118 174.037 5.89076C231.128 5.36082 287.619 7.7998 344.092 12.953C350.861 13.5145 350.908 6.40578 344.14 5.71507C286.505 0.554238 227.686 -0.995243 169.616 1.33782C113.094 3.55181 55.3997 7.56765 0.569619 17.4188C0.181752 17.5455 0.373564 17.8052 0.760582 17.8078Z"
                                  fill="#6DB356" 
                                />
                              </svg>
                            </span>
                          </h1>
                          <p className="hero-7__slider-para fs-18 mb-12 text-wrap-balance">
                            Assertively target market-driven intellectual capital with worldwide
                            human is simply free text quiz Bibendum holistic.
                          </p>
                          <a href="#" className="link button button-effect button-effect--primary">
                            <span className="d-inline-block fw-semibold"> Shop Now </span>
                            <span className="d-inline-block">
                              <i className="fas fa-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-7__slider-img d-none d-lg-block">
                <img src="assets/img/home-7/hero-img-1.png" alt="image" className="img-fluid w-100 h-100 object-fit-contain" />
              </div>
            </div>
          </div>

          <div className="swiper-slide swiper-slide-next" data-swiper-slide-index="1" role="group" aria-label="2 / 3">
            <div className="hero-7__slider-item">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="hero-7__slider-inner">
                      <div className="row">
                        <div className="col-lg-6">
                          <h1 className="hero-7__slider-title mb-12">
                            Unique & Stylish Modern
                            <span className="d-inline-block clr-primary text-decoration">
                              Furniture
                              <svg
                                width="350"
                                height="18"
                                className="text-decoration__img"
                                viewBox="0 0 350 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path 
                                  d="M0.760582 17.8078C58.2735 11.9812 115.591 6.4118 174.037 5.89076C231.128 5.36082 287.619 7.7998 344.092 12.953C350.861 13.5145 350.908 6.40578 344.14 5.71507C286.505 0.554238 227.686 -0.995243 169.616 1.33782C113.094 3.55181 55.3997 7.56765 0.569619 17.4188C0.181752 17.5455 0.373564 17.8052 0.760582 17.8078Z"
                                  fill="#6DB356" 
                                />
                              </svg>
                            </span>
                          </h1>
                          <p className="hero-7__slider-para fs-18 mb-12 text-wrap-balance">
                            Assertively target market-driven intellectual capital with worldwide
                            human is simply free text quiz Bibendum holistic.
                          </p>
                          <a href="#" className="link button button-effect button-effect--primary">
                            <span className="d-inline-block fw-semibold"> Shop Now </span>
                            <span className="d-inline-block">
                              <i className="fas fa-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-7__slider-img d-none d-lg-block">
                <img src="assets/img/home-7/hero-img-2.png" alt="image" className="img-fluid w-100 h-100 object-fit-contain" />
              </div>
            </div>
          </div>

          <div className="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index="2" role="group" aria-label="3 / 3">
            <div className="hero-7__slider-item">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="hero-7__slider-inner">
                      <div className="row">
                        <div className="col-lg-6">
                          <h1 className="hero-7__slider-title mb-12">
                            Unique & Stylish
                            <span className="d-inline-block clr-primary text-decoration">
                              Furniture
                              <svg
                                width="350"
                                height="18"
                                className="text-decoration__img"
                                viewBox="0 0 350 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path 
                                  d="M0.760582 17.8078C58.2735 11.9812 115.591 6.4118 174.037 5.89076C231.128 5.36082 287.619 7.7998 344.092 12.953C350.861 13.5145 350.908 6.40578 344.14 5.71507C286.505 0.554238 227.686 -0.995243 169.616 1.33782C113.094 3.55181 55.3997 7.56765 0.569619 17.4188C0.181752 17.5455 0.373564 17.8052 0.760582 17.8078Z"
                                  fill="#6DB356" 
                                />
                              </svg>
                            </span>
                          </h1>
                          <p className="hero-7__slider-para fs-18 mb-12 text-wrap-balance">
                            Assertively target market-driven intellectual capital with worldwide
                            human is simply free text quiz Bibendum holistic.
                          </p>
                          <a href="#" className="link button button-effect button-effect--primary">
                            <span className="d-inline-block fw-semibold"> Shop Now </span>
                            <span className="d-inline-block">
                              <i className="fas fa-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-7__slider-img d-none d-lg-block">
                <img src="assets/img/home-7/hero-img-3.png" alt="image" className="img-fluid w-100 h-100 object-fit-contain" />
              </div>
            </div>
          </div>

        </div>
        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
      </div>
    </div>
  )
}

export {SliderMueble}
