import handIcon from "../../../../public/img/icons/hand-icon.svg";
import ab2 from "../../../../public/img/about/ab-2.png";

function AboutOrganico() {
  return (
    <section className="about-us-section pb-120 hm3-about-section overflow-hidden">
    <div className="container">
      <div className="row g-4 align-items-center">
        <div className="col-xl-5">
          <div className="about-us-left position-relative">
            <img src={ab2} alt="no encontrado" className="img-fluid" />
            <div className="exp-box p-3 bg-white rounded-circle position-absolute">
              <div className="bg-secondary w-100 h-100 rounded-circle d-flex align-items-center justify-content-center flex-column">
                <h2 className="text-white">14+</h2>
                <span className="h6 text-white">Años de experiencia</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7">
          <div className="about-us-right">
            <div className="section-title-mx mb-6">
              <div className="d-flex align-items-center gap-2 flex-wrap mb-2">
                <h6 className="mb-0 gshop-subtitle text-secondary">¿Por qué elegirnos?</h6>
                <span>
                  <svg width="58" height="10" viewBox="0 0 58 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0" y1="5.2" x2="52" y2="5.2" stroke="#FF7C08" strokeWidth="1.6"></line>
                    <path d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z" fill="#FF7C08"></path>
                  </svg>
                </span>
              </div>
              <h2 className="mb-3">No compramos del mercado abierto</h2>
              <p className="mb-0">Modamente crear oportunidades intermandatadas y negocios electrónicos multimedia basados en transparencia.</p>
            </div>
            <div className="row g-3">
              {[
                { title: 'Socio confiable', description: 'Modamente crear oportunidades intermandatadas de negocios electrónicos.' },
                { title: 'Política de devoluciones', description: 'Modamente crear oportunidades intermandatadas de negocios electrónicos.' },
                { title: '100% Fresco y Orgánico', description: 'Modamente crear oportunidades intermandatadas de negocios electrónicos.' },
                { title: 'Pago seguro', description: 'Modamente crear oportunidades intermandatadas de negocios electrónicos.' }
              ].map((item, index) => (
                <div className="col-xl-6 col-lg-4 col-md-6" key={index}>
                  <div className="horizontal-icon-box d-flex align-items-center gap-4 bg-white rounded p-4 hover-shadow flex-wrap flex-xxl-nowrap">
                    <span className="icon-wrapper position-relative flex-shrink-0">
                      <img src={handIcon} alt="icono de mano" className="img-fluid" />
                    </span>
                    <div className="content-right">
                      <h5 className="mb-3">{item.title}</h5>
                      <p className="mb-0">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default AboutOrganico
