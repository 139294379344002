
import leafShadow from '../../../../public/img/shapes/leaf-shadow.png';
import mango from '../../../../public/img/shapes/mango.png';
import heroCircleSm from '../../../../public/img/shapes/hero-circle-sm.png';
import fruits from '../../../../public/img/home1/fruits.png';
import tree from '../../../../public/img/shapes/tree.png';
import orange1 from '../../../../public/img/shapes/orange-1.png';
import orange2 from '../../../../public/img/shapes/orange-2.png';
import heroCircleLg from '../../../../public/img/shapes/hero-circle-lg.png';

function Slider() {
    return (
        <>
        <section className="gshop-hero pt-120 bg-white position-relative z-1 overflow-hidden">
            <img src={leafShadow} alt="hoja" className="position-absolute leaf-shape z--1 rounded-circle" />
            <img 
                src={mango} 
                alt="mango" 
                className="position-absolute mango z--1" 
                data-parallax='{ "y": -120 }'
                style={{ transform: 'translate3d(0px, -0.014px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)' }}
            />
            <img src={heroCircleSm} alt="círculo" className="position-absolute hero-circle circle-sm z--1" />
            <div className="container">
                <div className="gshop-hero-slider swiper swiper-fade swiper-initialized swiper-horizontal swiper-pointer-events swiper-watch-progress swiper-backface-hidden">
                    <div className="swiper-wrapper" aria-live="off">
                        <div className="swiper-slide gshop-hero-single swiper-slide-visible swiper-slide-active">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-5 col-lg-8">
                                    <div className="hero-left-content">
                                        <span className="gshop-subtitle fs-5 text-secondary mb-2 d-block">Productos 100% Orgánicos</span>
                                        <h1 className="display-4 mb-3">Grocery Online <br /> Productos <mark className="p-0 bg-transparent text-secondary">Frescos</mark></h1>
                                        <p className="mb-7 fs-6">Asegure el capital intelectual impulsado por el mercado con capital humano mundial holístico.</p>
                                        <div className="hero-btns d-flex align-items-center gap-3 gap-sm-5 flex-wrap">
                                            <a href="shop-grid.html" className="btn btn-secondary">Comprar Ahora<span className="ms-2"><i className="fa-solid fa-arrow-right"></i></span></a>
                                            <a href="about.html" className="btn btn-primary">Sobre Nosotros<span className="ms-2"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-7">
                                    <div className="hero-right text-center position-relative z-1 mt-8 mt-xl-0">
                                        <img src={fruits} alt="frutas" className="img-fluid position-absolute end-0 top-50 hero-img" />
                                        <img src={tree} alt="árbol" className="img-fluid position-absolute tree z-1" />
                                        <img src={orange1} alt="naranja" className="position-absolute orange-1 z-1" />
                                        <img src={orange2} alt="naranja" className="position-absolute orange-2 z-1" />
                                        <img src={heroCircleLg} alt="círculo grande" className="img-fluid hero-circle" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Repite la estructura para los otros slides */}
                    </div>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                </div>
            </div>
            <div className="at-header-social d-none d-sm-flex align-items-center position-absolute">
                <span className="title fw-medium">Síguenos en</span>
                <ul className="social-list ms-3">
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                </ul>
            </div>
            <div className="gshop-hero-slider-pagination theme-slider-control position-absolute top-50 translate-middle-y z-5 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                <span className="swiper-pagination-bullet swiper-pagination-bullet-active" role="button" aria-label="Ir a la diapositiva 1" aria-current="true"></span>
                <span className="swiper-pagination-bullet" role="button" aria-label="Ir a la diapositiva 2"></span>
                <span className="swiper-pagination-bullet" role="button" aria-label="Ir a la diapositiva 3"></span>
            </div>

        </section>


        </>
    )
}

export { Slider }