import hlBanner1 from "../../../../public/img/banner/hl-banner-1.jpg";
import hlBanner2 from "../../../../public/img/banner/hl-banner-2.jpg";

function BannerCarne() {
  return (
    <div className="hl-banner-section pt-9">
      <div className="container">
        <div className="row g-4">
          <div className="col-xl-7">
            <div className="banner-1">
              <a href="#">
                <img
                  src={hlBanner1}
                  alt="banner"
                  className="rounded img-fluid"
                />
              </a>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="banner-2">
              <a href="#">
                <img
                  src={hlBanner2}
                  alt="banner"
                  className="rounded img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export {BannerCarne};
