import { BannerMuebles } from "./bannerMuebles/BannerMuebles"
import { BlogMueble } from "./blogMueble/BlogMueble"
import { CategoriesMueble } from "./categoriesMueble/CategoriesMueble"
import { FeaturedMueble } from "./featuredMueble/FeaturedMueble"
import { FooterMueble } from "./footerMueble/FooterMueble"
import { HeaderMueble } from "./headerMueble/HeaderMueble"
import { ProductsMueble } from "./productsMueble/ProductsMueble"
import { SliderMueble } from "./sliderMueble/SliderMueble"


function HomeMueble() {
  return (
    <div style={{background:"#F2F2F2"}}>
    <HeaderMueble />
    <SliderMueble />
    <CategoriesMueble />
    <BannerMuebles />
    <FeaturedMueble />
    <ProductsMueble />
    <BlogMueble/>
    <FooterMueble />
    </div>
  )
}

export {HomeMueble}
