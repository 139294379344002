import loginBanner from "../../../public/img/banner/login-banner.jpg";
import logo from "../../../public/img/logo.png";
import googleLogo from "../../../public/img/brands/google.png";

function Login() {
  return (
    <section className="login-section py-5">
    <div className="container">
      <div className="row justify-content-center">
        <div
          className="col-lg-5 col-12 tt-login-img"
          style={{ backgroundImage: `url(${loginBanner})` }}
        ></div>
        <div className="col-lg-5 col-12 bg-white d-flex p-0 tt-login-col shadow">
          <form className="tt-login-form-wrap p-3 p-md-6 p-lg-6 py-7 w-100">
            <div className="mb-7">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <h2 className="mb-4 h3">
              ¡Hola! <br />
              Bienvenido de nuevo a <span className="text-secondary">Grostore.</span>
            </h2>
            <div className="row g-3">
              <div className="col-sm-12">
                <div className="input-field">
                  <label className="fw-bold text-dark fs-sm mb-1">Correo Electrónico</label>
                  <input type="email" placeholder="Ingresa tu correo" className="theme-input" />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="input-field check-password">
                  <label className="fw-bold text-dark fs-sm mb-1">Contraseña</label>
                  <div className="check-password">
                    <input type="password" placeholder="Contraseña" className="theme-input" />
                    <span className="eye eye-icon">
                      <i className="fa-solid fa-eye"></i>
                    </span>
                    <span className="eye eye-slash">
                      <i className="fa-solid fa-eye-slash"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="checkbox d-inline-flex align-items-center gap-2">
                <div className="theme-checkbox flex-shrink-0">
                  <input type="checkbox" id="save-password" />
                  <span className="checkbox-field">
                    <i className="fa-solid fa-check"></i>
                  </span>
                </div>
                <label className="save-password fs-sm">Recordar por 30 días</label>
              </div>
              <a href="#" className="fs-sm">
                ¿Olvidaste tu contraseña?
              </a>
            </div>
            <div className="row g-4 mt-4">
              <div className="col-sm-6">
                <button type="submit" className="btn btn-primary w-100">
                  Iniciar Sesión
                </button>
              </div>
              <div className="col-sm-6">
                <a href="#" className="btn btn-outline google-btn w-100">
                  <img src={googleLogo} alt="google" className="me-2" />
                  Iniciar con Google
                </a>
              </div>
            </div>
            <p className="mb-0 fs-xs mt-4">
              ¿No tienes una cuenta? <a href="/signup">Regístrate</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
  )
}

export {Login}
