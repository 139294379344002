
import "./OrderTracking.css"

function OrderTracking() {
    return (
        <div className="tab-pane fade active show" id="order-tracking" role="tabpanel">
            <div className="order-tracking-wrap bg-white rounded py-5 px-4">
                <h6 className="mb-4">Seguimiento de Pedido</h6>
                <ol id="progress-bar">
                    <li className="fs-xs tt-step tt-step-done">Pendiente</li>
                    <li className="fs-xs tt-step tt-step-done">Procesando</li>
                    <li className="fs-xs tt-step active">En camino</li>
                    <li className="fs-xs tt-step">Entregado</li>
                </ol>
                <div className="table-responsive-md mt-5">
                    <table className="table table-bordered fs-xs">
                        <thead>
                            <tr>
                                <th scope="col">Fecha y Hora</th>
                                <th scope="col">Información del Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>14 Feb 2023 - 13:19</td>
                                <td>Su paquete ha sido entregado. ¡Gracias por comprar en Grostore!</td>
                            </tr>
                            <tr>
                                <td>13 Feb 2023 - 13:39</td>
                                <td>Su paquete ha sido entregado a Grostore Delivery.</td>
                            </tr>
                            <tr>
                                <td>12 Feb 2023 - 14:50</td>
                                <td>Su paquete ha sido empacado y está siendo entregado a un socio logístico.</td>
                            </tr>
                            <tr>
                                <td>12 Feb 2023 - 13:05</td>
                                <td>Su pedido ha sido verificado con éxito.</td>
                            </tr>
                            <tr>
                                <td>12 Feb 2023 - 13:05</td>
                                <td>Gracias por comprar en GroStore! Su pedido está siendo verificado.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export { OrderTracking }