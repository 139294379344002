import counterBackground from "../../../../public/img/home-4/cta-bg.jpg";

function CounterCarne() {
  return (
    <section className="hl-counter-section">
    <div className="container">
      <div
        className="hl-counter-box rounded"
        style={{ backgroundImage: `url(${counterBackground})` }}
      >
        <div className="row">
          <div className="col-xl-4">
            <div className="hl-counter-item border-r">
              <h1 className="display-3 text-white mb-2">
                <span className="counter">20</span>+
              </h1>
              <h5 className="text-white mb-0">Toneladas de Carne Cada Mes</h5>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="hl-counter-item border-r">
              <h1 className="display-3 text-white mb-2">
                <span className="counter">5</span>+
              </h1>
              <h5 className="text-white mb-0">Tipos de Productos Cárnicos</h5>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="hl-counter-item">
              <h1 className="display-3 text-white mb-2">
                <span className="counter">0</span>+
              </h1>
              <h5 className="text-white mb-0">Sección Mundial</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export {CounterCarne}
