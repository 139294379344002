import vegetable from "../../../../public/img/category/vegetable.png";
import freshFruits from "../../../../public/img/category/fresh-fruits.png";
import milk from "../../../../public/img/category/milk.png";
import coffeeDrinks from "../../../../public/img/category/coffee-drinks.png";
import meat from "../../../../public/img/category/meat.png";
import cleaning from "../../../../public/img/category/cleaning.png";
import banner9 from "../../../../public/img/banner/banner-9.jpg";
import banner10 from "../../../../public/img/banner/banner-10.jpg";

function CategoryOrganico() {
  return (
    <section className="category-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center">
              <h2 className="mb-2">Explora Todas las Categorías</h2>
              <p className="mb-0">
                Mercados de nicho pegajosos a través de redes orientadas a
                objetivos Recaptualización completa
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center g-4 mt-4">
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img
                  src={vegetable}
                  alt="flower"
                  className="img-fluid"
                />
              </div>
              <a
                href="#"
                className="text-dark fs-sm fw-bold d-block mt-3"
              >
                Verduras
              </a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">
              25 Artículos
              </span>
              <a
                href="shop-grid.html"
                className="explore-btn position-absolute"
              >
                <i className="fa-solid fa-arrow-up"></i>
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-2">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img
                  src={freshFruits}
                  alt="flower"
                  className="img-fluid"
                />
              </div>
              <a
                href="shop-grid.html"
                className="text-dark fs-sm fw-bold d-block mt-3"
              >
                Frutas frescas
              </a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">
              25 Artículos
              </span>
              <a
                href="shop-grid.html"
                className="explore-btn position-absolute"
              >
                <i className="fa-solid fa-arrow-up"></i>
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-3">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img
                  src={milk}
                  alt="flower"
                  className="img-fluid"
                />
              </div>
              <a
                href="shop-grid.html"
                className="text-dark fs-sm fw-bold d-block mt-3"
              >
                Leche &amp; Lácteos
              </a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">
                25 Artículos
              </span>
              <a
                href="shop-grid.html"
                className="explore-btn position-absolute"
              >
                <i className="fa-solid fa-arrow-up"></i>
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-4">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img
                  src={coffeeDrinks}
                  alt="flower"
                  className="img-fluid"
                />
              </div>
              <a
                href="shop-grid.html"
                className="text-dark fs-sm fw-bold d-block mt-3"
              >
                Café &amp; Bebidas
              </a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">
                25 Artículos
              </span>
              <a
                href="shop-grid.html"
                className="explore-btn position-absolute"
              >
                <i className="fa-solid fa-arrow-up"></i>
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-5">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img
                  src={meat}
                  alt="flower"
                  className="img-fluid"
                />
              </div>
              <a
                href="shop-grid.html"
                className="text-dark fs-sm fw-bold d-block mt-3"
              >
                Carne
              </a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">
                25 Artículos
              </span>
              <a
                href="shop-grid.html"
                className="explore-btn position-absolute"
              >
                <i className="fa-solid fa-arrow-up"></i>
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-3">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img
                  src={cleaning}
                  alt="flower"
                  className="img-fluid"
                />
              </div>
              <a
                href="shop-grid.html"
                className="text-dark fs-sm fw-bold d-block mt-3"
              >
                Limpieza esencial
              </a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">
                25 Artículos
              </span>
              <a
                href="shop-grid.html"
                className="explore-btn position-absolute"
              >
                <i className="fa-solid fa-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row g-4 justify-content-center mt-1">
          <div className="col-xl-4">
            <div
              className="hm3-banner-2 rounded-3"
              data-background="assets/img/banner/banner-9.jpg"
              style={{ backgroundImage: `url(${banner9})`, }}
            >
              <div className="d-flex align-items-center flex-wrap gap-2">
                <span className="gshop-subtitle text-primary">
                  Disfruta hasta un 20%
                </span>
                <span>
                  <svg
                    width="58"
                    height="10"
                    viewBox="0 0 58 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="-6.99382e-08"
                      y1="5.2"
                      x2="52"
                      y2="5.2"
                      stroke="#4EB529"
                      strokeWidth="1.6"
                    ></line>
                    <path
                      d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z"
                      fill="#4EB529"
                    ></path>
                  </svg>
                </span>
              </div>
              <h3 className="mt-2 mb-5">Vegetales Frescos</h3>
              <a href="shop-grid.html" className="btn btn-primary btn-md">
                Ver Ahora{" "}
                <span className="ms-1">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="col-xl-8">
            <div
              className="hm3-banner-3 rounded-3"
              data-background="assets/img/banner/banner-10.jpg"
              style={{
                backgroundImage: `url(${banner10})`,
              }}
            >
              <div className="banner-content">
                <div className="d-flex align-items-center gap-2 flex-wrap">
                  <h6 className="mb-0 text-secondary gshop-subtitle fw-normal">
                    Disfruta hasta un 20% en todos los productos
                  </h6>
                  <span>
                    <svg
                      width="58"
                      height="10"
                      viewBox="0 0 58 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="-6.99382e-08"
                        y1="5.2"
                        x2="52"
                        y2="5.2"
                        stroke="#FF7C08"
                        strokeWidth="1.6"
                      ></line>
                      <path
                        d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z"
                        fill="#FF7C08"
                      ></path>
                    </svg>
                  </span>
                </div>
                <h3 className="mt-2 mb-3 text-white">
                  Todos los Productos Orgánicos y Frescos
                </h3>
                <p className="mb-5 text-white">
                  Modelos progresivos de cadenas de suministro funcionalizados.
                </p>
                <a href="shop-grid.html" className="btn btn-secondary btn-md">
                  Ver Ahora
                  <span className="ms-1">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { CategoryOrganico };
