import { Header } from "./header/header";
import { Banner } from "./banner/Banner";
import { Slider } from "./slider/Slider";
import { Search } from "./search/Search";
import { Category } from "./category/Category";
import { ProductHome } from "./productHome/ProductHome";
import { Feedback } from "./feedback/Feedback";
import { Download } from "./download/Download";
import { Footer } from "./footer/Footer";
import "./styleMedicina/globalMedicina.css";

function HomeMedina() {
    return (
        <>
            <Header />
            <Slider />
            <Search />
            <Banner />
            <Category />
            <ProductHome />
            <Feedback />
            <Download />
            <Footer />
        </>
    )
}

export { HomeMedina }