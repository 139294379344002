
import lemon from "../../../../public/img/products/lemon.png";
import bg1 from "../../../../public/img/home-3/bg-1.jpg";
import lineShape from "../../../../public/img/shapes/line-shape.png";
import apple from "../../../../public/img/products/apple.png";
import banana from "../../../../public/img/products/banana.png";
import stroberry from "../../../../public/img/products/stroberry.png";

function OfferOrganico() {
  return (
    <section className="offer-section hm3-offer-section mb--80" data-background="assets/img/home-3/bg-1.jpg" style={{backgroundImage: `url(${bg1})`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-5">
                        <div className="gshop-title text-center">
                            <h2 className="mb-3 text-white">Las mejores ofertas <mark className="text-secondary position-relative z-1 p-0 bg-transparent">Esta semana<img src={lineShape} className="position-absolute border-line w-100" alt="line shape"/></mark></h2>
                            <p className="mb-0 text-white">Compellingly fashion intermandated opportunities and multimedia based fnsparent e-business.</p>
                        </div>
                        <ul className="countdown-timer offer-countdown list-inline text-center mt-5" data-date="06/30/2023 23:59:59">
                            <li className="gs-offer-countdown-box text-center list-inline-item">
                                <div className="bg-sky-captain py-2 px-3">
                                    <h3 className="mb-0 text-white days">45</h3>
                                    <h6 className="mb-0 gshop-subtitle text-white fw-normal">Días</h6>
                                </div>
                            </li>
                            <li className="gs-offer-countdown-box text-center list-inline-item">
                                <div className="bg-sky-captain py-2 px-3">
                                    <h3 className="mb-0 text-white hours">45</h3>
                                    <h6 className="mb-0 gshop-subtitle text-white fw-normal">Hora</h6>
                                </div>
                            </li>
                            <li className="gs-offer-countdown-box text-center list-inline-item">
                                <div className="bg-sky-captain py-2 px-3">
                                    <h3 className="mb-0 text-white minutes">45</h3>
                                    <h6 className="mb-0 gshop-subtitle text-white fw-normal">Minutos</h6>
                                </div>
                            </li>
                            <li className="gs-offer-countdown-box text-center list-inline-item">
                                <div className="bg-sky-captain py-2 px-3">
                                    <h3 className="mb-0 text-white seconds">45</h3>
                                    <h6 className="mb-0 gshop-subtitle text-white fw-normal">Segundos</h6>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="products-slider-wrapper mt-8 position-relative">
                    <div className="offer-product-slider swiper pb-6 swiper-initialized swiper-horizontal">
                        <div className="swiper-wrapper" id="swiper-wrapper-e6eebaf3318efb7d" aria-live="off" style={{transform: "translate3d(-2688px, 0px, 0px)", transitionDuration: "700ms"}}><div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src={apple} alt="apple" className="img-fluid" /></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Cebolla seca Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Shop Now</a>
                                    </div>
                                </div>
                            </div><div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src={banana} alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Cebolla seca Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Comprar ahora</a>
                                    </div>
                                </div>
                            </div><div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src={stroberry} alt="apple" className="img-fluid" /></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Cebolla seca Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Comprar ahora</a>
                                    </div>
                                </div>
                            </div><div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src={lemon} alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Cebolla seca Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Comprar ahora</a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src="assets/img/products/apple.png" alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Dried Onion Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reviews)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Shop Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src="assets/img/products/banana.png" alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Dried Onion Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reviews)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Shop Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src="assets/img/products/stroberry.png" alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Dried Onion Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reviews)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Shop Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide swiper-slide-prev" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src="assets/img/products/lemon.png" alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Dried Onion Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reviews)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Shop Now</a>
                                    </div>
                                </div>
                            </div>
                        <div className="swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src={apple} alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Cebolla seca Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Comprar ahora</a>
                                    </div>
                                </div>
                            </div><div className="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src={banana} alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Cebolla seca Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Comprar ahora</a>
                                    </div>
                                </div>
                            </div><div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src={stroberry} alt="apple" className="img-fluid"/></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Cebolla seca Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Comprar ahora</a>
                                    </div>
                                </div>
                            </div><div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "312px", marginRight: "24px"}}>
                                <div className="vertical-product-card rounded-2 position-relative border-0 bg-white">
                                    <div className="thumbnail position-relative text-center p-4">
                                        <a href="#"><img src={lemon} alt="apple" className="img-fluid" /></a>
                                        <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                            <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                            <a href="#" className="rounded-btn">
                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                                </svg>
                                            </a>
                                            <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-content text-center">
                                        <a href="product-details.html" className="card-title fw-bold d-block mb-2">Cebolla seca Kinh Men</a>
                                        <div className="pricing mb-2 d-flex align-items-center justify-content-center gap-2">
                                            <del className="mb-0 h6 text-gray">$240.00</del>
                                            <h6 className="price text-danger mb-0">$140.00</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-nowrap star-rating fs-xxs mb-2">
                                            <ul className="d-flex align-items-center me-2">
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            </ul>
                                            <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                        </div>
                                        <a href="product-details.html" className="btn btn-outline-secondary border-secondary btn-md rounded-1 mt-4">Comprar ahora</a>
                                    </div>
                                </div>
                            </div></div>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                    <button className="ofp-slider-prev" aria-label="Previous slide" aria-controls="swiper-wrapper-e6eebaf3318efb7d"><i className="fas fa-angle-left"></i></button>
                    <button className="ofp-slider-next" aria-label="Next slide" aria-controls="swiper-wrapper-e6eebaf3318efb7d"><i className="fas fa-angle-right"></i></button>
                </div>
            </div>
        </section>
  )
}

export {OfferOrganico}
