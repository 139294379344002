import { Header } from "../../Home/header/Header";
import { BreadCrumb } from "../../productsView/breadCrumb/BreadCrumb";
import blogThumbMd1 from "../../../../public/img/blog/blog-thumb-1.jpg";
import blogThumbMd2 from "../../../../public/img/blog/blog-thumb-2.jpg";
import blogThumbMd3 from "../../../../public/img/blog/blog-thumb-3.jpg";
import blogThumbMd4 from "../../../../public/img/blog/blog-thumb-md-4.jpg";
import blogThumbMd5 from "../../../../public/img/blog/blog-thumb-md-5.jpg";
import banner6 from "../../../../public/img/banner/banner-6.jpg";
import galleryLg1 from "../../../../public/img/gallery/gallery-lg-1.jpg";
import client3 from "../../../../public/img/authors/client-3.png";
import { Footer } from "../../Home/footer/Footer";

function MainBlog() {
  return (
    <>
      <Header />
      <BreadCrumb title="Cuadrícula de blogs" pageName="Página" currentPage="Cuadrícula de blogs" />
      <section className="blog-grid-section ptb-120">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-xxl-6 col-xl-7">
              <div className="row g-4">
                <div className="col-md-6 col-xl-12">
                  <article className="article-horizontal d-flex align-items-center gap-4 p-4 rounded-3">
                    <div className="thumbnail flex-shrink-0 overflow-hidden rounded-3">
                      <a href="#">
                        <img
                          src={blogThumbMd1}
                          alt="no encontrado"
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="article-contents">
                      <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mb-2">
                        <span className="fw-medium fs-xs">
                          <i className="fa-solid fa-tags me-2"></i>Vegetales
                          Orgánicos
                        </span>
                        <span className="fw-medium fs-xs">
                          <i className="fa-regular fa-clock me-2"></i>24 de
                          mayo, 2022
                        </span>
                      </div>
                      <a href="#">
                        <h4 className="mb-2">
                          Calidad Perfecta, Precio Razonable Para Tu Familia
                        </h4>
                      </a>
                      <p className="mb-3">
                        Dirigir assertivamente el mercado lorem ipsum es
                        onsectetur notado et dolore.
                      </p>
                      <a href="#" className="explore-btn fw-bold">
                        Explorar Más
                        <span className="ms-2">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </article>
                </div>
                <div className="col-md-6 col-xl-12">
                  <article className="article-horizontal d-flex align-items-center gap-4 p-4 rounded-3">
                    <div className="thumbnail flex-shrink-0 overflow-hidden rounded-3">
                      <a href="#">
                        <img
                          src={blogThumbMd2}
                          alt="no encontrado"
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="article-contents">
                      <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mb-2">
                        <span className="fw-medium fs-xs">
                          <i className="fa-solid fa-tags me-2"></i>Vegetales
                          Orgánicos
                        </span>
                        <span className="fw-medium fs-xs">
                          <i className="fa-regular fa-clock me-2"></i>24 de
                          mayo, 2022
                        </span>
                      </div>
                      <a href="#">
                        <h4 className="mb-2">
                          Los Mejores Beneficios De La Carne Fresca Para La
                          Salud De Las Mujeres
                        </h4>
                      </a>
                      <p className="mb-3">
                        Dirigir assertivamente el mercado lorem ipsum es
                        onsectetur notado et dolore.
                      </p>
                      <a href="#" className="explore-btn fw-bold">
                        Explorar Más
                        <span className="ms-2">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-5">
              <div
                className="linear-banner position-relative overflow-hidden z-1 rounded-3 text-center"
                style={{ backgroundImage: `url(${banner6})` }}
              >
                <div className="d-flex align-items-center justify-content-center flex-wrap gap-4 mb-2">
                  <span className="text-secondary fw-medium fs-xs">
                    <i className="fa-solid fa-tags me-2"></i>Vegetales Orgánicos
                  </span>
                  <span className="text-secondary fw-medium fs-xs">
                    <i className="fa-regular fa-clock me-2"></i>Vegetales
                    Orgánicos
                  </span>
                </div>
                <h4 className="text-white mb-5">
                  Entrega a Domicilio en Vacaciones Hemos Recientemente Ordenado
                  Vegetales Orgánicos
                </h4>
                <a href="#" className="btn btn-primary rounded-1 btn-md">
                  Explorar Más
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-carousel-section">
        <div className="container">
          <div className="blog-carousel swiper">
            <div className="swiper-wrapper">
              <div className="article-horizontal card-lg rounded-3 p-5 d-flex align-items-center gap-4 bg-white shadow swiper-slide">
                <div className="thumbnail overflow-hidden flex-shrink-0 rounded-2">
                  <a href="#">
                    <img src={galleryLg1} alt="galería" className="img-fluid" />
                  </a>
                </div>
                <div className="article-contents">
                  <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mb-2">
                    <span className="fw-medium fs-xs">
                      <i className="fa-solid fa-tags me-2"></i>Vegetales
                      Orgánicos
                    </span>
                    <span className="fw-medium fs-xs">
                      <i className="fa-regular fa-clock me-2"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h3 className="mb-3">
                      Maneras de Elegir Frutas y Mariscos Buenos para el
                      Embarazo
                    </h3>
                  </a>
                  <p className="mb-4">
                    Desarrollar profesionalmente mercados únicos a través de un
                    enlace total paralelo. Maximizar monotónicamente el capital
                    intelectual corporativo mientras que se catalizan cambios
                    granulares. Proveer acceso assertivo a tecnologías
                    económicas mientras se utilizan plataformas virtuales.
                  </p>
                  <div className="d-flex justify-content-between gap-3">
                    <div className="d-inline-flex align-items-center gap-3">
                      <div className="author-thumb">
                        <img
                          src={client3}
                          alt="autor"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="author-info">
                        <h6 className="mb-1">Wendell Carter</h6>
                        <span className="fs-xs">24 de mayo, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="article-horizontal card-lg rounded-3 p-5 d-flex align-items-center gap-4 bg-white shadow swiper-slide">
                <div className="thumbnail overflow-hidden flex-shrink-0 rounded-2">
                  <a href="#">
                    <img
                      src="assets/img/gallery/gallery-lg-5.jpg"
                      alt="galería"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="article-contents">
                  <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mb-2">
                    <span className="fw-medium fs-xs">
                      <i className="fa-solid fa-tags me-2"></i>Vegetales
                      Orgánicos
                    </span>
                    <span className="fw-medium fs-xs">
                      <i className="fa-regular fa-clock me-2"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h3 className="mb-3">
                      Maneras de Elegir Frutas y Mariscos Buenos para el
                      Embarazo
                    </h3>
                  </a>
                  <p className="mb-4">
                    Desarrollar profesionalmente mercados únicos a través de un
                    enlace total paralelo. Maximizar monotónicamente el capital
                    intelectual corporativo mientras que se catalizan cambios
                    granulares. Proveer acceso assertivo a tecnologías
                    económicas mientras se utilizan plataformas virtuales.
                  </p>
                  <div className="d-flex justify-content-between gap-3">
                    <div className="d-inline-flex align-items-center gap-3">
                      <div className="author-thumb">
                        <img
                          src="assets/img/authors/client-1.png"
                          alt="autor"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="author-info">
                        <h6 className="mb-1">Wendell Carter</h6>
                        <span className="fs-xs">24 de mayo, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="article-horizontal card-lg rounded-3 p-5 d-flex align-items-center gap-4 bg-white shadow swiper-slide">
                <div className="thumbnail overflow-hidden flex-shrink-0 rounded-2">
                  <a href="#">
                    <img
                      src="assets/img/gallery/gallery-lg-4.jpg"
                      alt="galería"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="article-contents">
                  <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mb-2">
                    <span className="fw-medium fs-xs">
                      <i className="fa-solid fa-tags me-2"></i>Vegetales
                      Orgánicos
                    </span>
                    <span className="fw-medium fs-xs">
                      <i className="fa-regular fa-clock me-2"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h3 className="mb-3">
                      Maneras de Elegir Frutas y Mariscos Buenos para el
                      Embarazo
                    </h3>
                  </a>
                  <p className="mb-4">
                    Desarrollar profesionalmente mercados únicos a través de un
                    enlace total paralelo. Maximizar monotónicamente el capital
                    intelectual corporativo mientras que se catalizan cambios
                    granulares. Proveer acceso assertivo a tecnologías
                    económicas mientras se utilizan plataformas virtuales.
                  </p>
                  <div className="d-flex justify-content-between gap-3">
                    <div className="d-inline-flex align-items-center gap-3">
                      <div className="author-thumb">
                        <img
                          src="assets/img/authors/client-2.png"
                          alt="autor"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="author-info">
                        <h6 className="mb-1">Wendell Carter</h6>
                        <span className="fs-xs">24 de mayo, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="article-horizontal card-lg rounded-3 p-5 d-flex align-items-center gap-4 bg-white shadow swiper-slide">
                <div className="thumbnail overflow-hidden flex-shrink-0 rounded-2">
                  <a href="#">
                    <img
                      src="assets/img/gallery/gallery-lg-1.jpg"
                      alt="galería"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="article-contents">
                  <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mb-2">
                    <span className="fw-medium fs-xs">
                      <i className="fa-solid fa-tags me-2"></i>Vegetales
                      Orgánicos
                    </span>
                    <span className="fw-medium fs-xs">
                      <i className="fa-regular fa-clock me-2"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h3 className="mb-3">
                      Maneras de Elegir Frutas y Mariscos Buenos para el
                      Embarazo
                    </h3>
                  </a>
                  <p className="mb-4">
                    Desarrollar profesionalmente mercados únicos a través de un
                    enlace total paralelo. Maximizar monotónicamente el capital
                    intelectual corporativo mientras que se catalizan cambios
                    granulares. Proveer acceso assertivo a tecnologías
                    económicas mientras se utilizan plataformas virtuales.
                  </p>
                  <div className="d-flex justify-content-between gap-3">
                    <div className="d-inline-flex align-items-center gap-3">
                      <div className="author-thumb">
                        <img
                          src="assets/img/authors/client-3.png"
                          alt="autor"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="author-info">
                        <h6 className="mb-1">Wendell Carter</h6>
                        <span className="fs-xs">24 de mayo, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-carousel-control theme-slider-control text-center mt-6 swiper-pagination"></div>
          </div>
        </div>
      </section>

      <div className="light-bg ptb-120">
        <div className="container">
          <div className="row justify-content-center g-4">
            <div className="col-xl-4 col-md-6">
              <article className="blog-card rounded-2 overflow-hidden bg-white border">
                <div className="thumbnail overflow-hidden">
                  <img
                    src={blogThumbMd1}
                    alt="blog thumb"
                    className="img-fluid"
                  />
                </div>
                <div className="blog-card-content">
                  <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                    <span className="fs-xs fw-bold">
                      <i className="fa-solid fa-tags me-1"></i>Vegetal Orgánico
                    </span>
                    <span className="fs-xs fw-bold">
                      <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h4 className="mb-3">
                      Entrega a Domicilio de Vacaciones que Hemos Recientemente
                      Ordenado
                    </h4>
                  </a>
                  <p className="mb-0 mb-5">
                    Explota estrategias de crecimiento de capital invertido de
                    manera integral.
                  </p>
                  <a href="#" className="btn btn-primary-light btn-md">
                    Explorar Más
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </article>
            </div>
            <div className="col-xl-4 col-md-6">
              <article className="blog-card rounded-2 overflow-hidden bg-white border">
                <div className="thumbnail overflow-hidden">
                  <img
                    src={blogThumbMd2}
                    alt="blog thumb"
                    className="img-fluid"
                  />
                </div>
                <div className="blog-card-content">
                  <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                    <span className="fs-xs fw-bold">
                      <i className="fa-solid fa-tags me-1"></i>Vegetal Orgánico
                    </span>
                    <span className="fs-xs fw-bold">
                      <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h4 className="mb-3">
                      Entrega a Domicilio de Vacaciones que Hemos Recientemente
                      Ordenado
                    </h4>
                  </a>
                  <p className="mb-0 mb-5">
                    Explota estrategias de crecimiento de capital invertido de
                    manera integral.
                  </p>
                  <a href="#" className="btn btn-primary-light btn-md">
                    Explorar Más
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </article>
            </div>
            <div className="col-xl-4 col-md-6">
              <article className="blog-card rounded-2 overflow-hidden bg-white border">
                <div className="thumbnail overflow-hidden">
                  <img
                    src={blogThumbMd3}
                    alt="blog thumb"
                    className="img-fluid"
                  />
                </div>
                <div className="blog-card-content">
                  <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                    <span className="fs-xs fw-bold">
                      <i className="fa-solid fa-tags me-1"></i>Vegetal Orgánico
                    </span>
                    <span className="fs-xs fw-bold">
                      <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h4 className="mb-3">
                      Entrega a Domicilio de Vacaciones que Hemos Recientemente
                      Ordenado
                    </h4>
                  </a>
                  <p className="mb-0 mb-5">
                    Explota estrategias de crecimiento de capital invertido de
                    manera integral.
                  </p>
                  <a href="#" className="btn btn-primary-light btn-md">
                    Explorar Más
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </article>
            </div>
          </div>

          <div className="row g-4 justify-content-center mt-1">
            <div className="col-md-6 col-xl-12 col-xxl-6">
              <article className="article-horizontal d-flex align-items-center gap-4 p-4 rounded-3">
                <div className="thumbnail flex-shrink-0 overflow-hidden rounded-3">
                  <a href="#">
                    <img
                      src={blogThumbMd4}
                      alt="not found"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="article-contents">
                  <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mb-2">
                    <span className="fw-medium fs-xs">
                      <i className="fa-solid fa-tags me-2"></i>Vegetal Orgánico
                    </span>
                    <span className="fw-medium fs-xs">
                      <i className="fa-regular fa-clock me-2"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h4 className="mb-2">
                      Calidad Perfecta a Precio Razonable para Tu Familia
                    </h4>
                  </a>
                  <p className="mb-3">
                    Dirige el mercado de manera asertiva, lorem ipsum es
                    onsectetur noted et dolore.
                  </p>
                  <a href="#" className="explore-btn fw-bold">
                    Explorar Más
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </article>
            </div>
            <div className="col-md-6 col-xl-12 col-xxl-6">
              <article className="article-horizontal d-flex align-items-center gap-4 p-4 rounded-3">
                <div className="thumbnail flex-shrink-0 overflow-hidden rounded-3">
                  <a href="#">
                    <img
                      src={blogThumbMd5}
                      alt="not found"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="article-contents">
                  <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mb-2">
                    <span className="fw-medium fs-xs">
                      <i className="fa-solid fa-tags me-2"></i>Vegetal Orgánico
                    </span>
                    <span className="fw-medium fs-xs">
                      <i className="fa-regular fa-clock me-2"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h4 className="mb-2">
                      Calidad Perfecta a Precio Razonable para Tu Familia
                    </h4>
                  </a>
                  <p className="mb-3">
                    Dirige el mercado de manera asertiva, lorem ipsum es
                    onsectetur noted et dolore.
                  </p>
                  <a href="#" className="explore-btn fw-bold">
                    Explorar Más
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </article>
            </div>
          </div>

          <div className="row g-4 justify-content-center mt-1">
          <div className="col-xl-4 col-md-6">
              <article className="blog-card rounded-2 overflow-hidden bg-white border">
                <div className="thumbnail overflow-hidden">
                  <img
                    src={blogThumbMd1}
                    alt="blog thumb"
                    className="img-fluid"
                  />
                </div>
                <div className="blog-card-content">
                  <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                    <span className="fs-xs fw-bold">
                      <i className="fa-solid fa-tags me-1"></i>Vegetal Orgánico
                    </span>
                    <span className="fs-xs fw-bold">
                      <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h4 className="mb-3">
                      Entrega a Domicilio de Vacaciones que Hemos Recientemente
                      Ordenado
                    </h4>
                  </a>
                  <p className="mb-0 mb-5">
                    Explota estrategias de crecimiento de capital invertido de
                    manera integral.
                  </p>
                  <a href="#" className="btn btn-primary-light btn-md">
                    Explorar Más
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </article>
            </div>
            <div className="col-xl-4 col-md-6">
              <article className="blog-card rounded-2 overflow-hidden bg-white border">
                <div className="thumbnail overflow-hidden">
                  <img
                    src={blogThumbMd2}
                    alt="blog thumb"
                    className="img-fluid"
                  />
                </div>
                <div className="blog-card-content">
                  <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                    <span className="fs-xs fw-bold">
                      <i className="fa-solid fa-tags me-1"></i>Vegetal Orgánico
                    </span>
                    <span className="fs-xs fw-bold">
                      <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h4 className="mb-3">
                      Entrega a Domicilio de Vacaciones que Hemos Recientemente
                      Ordenado
                    </h4>
                  </a>
                  <p className="mb-0 mb-5">
                    Explota estrategias de crecimiento de capital invertido de
                    manera integral.
                  </p>
                  <a href="#" className="btn btn-primary-light btn-md">
                    Explorar Más
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </article>
            </div>
            <div className="col-xl-4 col-md-6">
              <article className="blog-card rounded-2 overflow-hidden bg-white border">
                <div className="thumbnail overflow-hidden">
                  <img
                    src={blogThumbMd3}
                    alt="blog thumb"
                    className="img-fluid"
                  />
                </div>
                <div className="blog-card-content">
                  <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                    <span className="fs-xs fw-bold">
                      <i className="fa-solid fa-tags me-1"></i>Vegetal Orgánico
                    </span>
                    <span className="fs-xs fw-bold">
                      <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                      2022
                    </span>
                  </div>
                  <a href="#">
                    <h4 className="mb-3">
                      Entrega a Domicilio de Vacaciones que Hemos Recientemente
                      Ordenado
                    </h4>
                  </a>
                  <p className="mb-0 mb-5">
                    Explota estrategias de crecimiento de capital invertido de
                    manera integral.
                  </p>
                  <a href="#" className="btn btn-primary-light btn-md">
                    Explorar Más
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </article>
            </div>            
          </div>
          
        </div>
      </div>
      <Footer />
    </>
  );
}

export { MainBlog };
