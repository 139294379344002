import product1 from "../../../../public/img/home-7/product-1.png";
import product2 from "../../../../public/img/home-7/product-2.png";
import product3 from "../../../../public/img/home-7/product-3.png";
import product4 from "../../../../public/img/home-7/product-4.png";
import product5 from "../../../../public/img/home-7/product-5.png";
import product6 from "../../../../public/img/home-7/product-6.png";
import product7 from "../../../../public/img/home-7/product-7.png";
import product8 from "../../../../public/img/home-7/product-8.png";
import productBannerImg4 from "../../../../public/img/home-7/product-banner-img-4.png";
import productBannerImg5 from "../../../../public/img/home-7/product-banner-img-5.png";

function FeaturedMueble() {
  return (
    <div className="featured-section section-space-top section-space-sm-bottom">
      <div className="section-space-sm-bottom">
        <div className="container">
          <div className="row g-4 justify-content-lg-between align-items-center">
            <div className="col-lg-6">
              <h2 className="mb-0 display-6">Colecciones Destacadas</h2>
            </div>
            <div className="col-lg-6 col-xl-5">
              <p className="mb-0">
                Impulsar globalmente áreas temáticas estratégicas centradas en
                principios frente a las mejores prácticas orientadas a
                objetivos.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row g-4">
          <div className="col-12">
            <div
              className="list-group flex-row flex-wrap featured-nav gap-4 gap-md-6 mb-12"
              role="tablist"
            >
              <a
                className="link featured-nav__link fw-bold clr-heading active"
                data-bs-toggle="list"
                href="#gaming-chair"
                aria-selected="true"
                role="tab"
                style={{color:"#FF7C0A"}}
              >
                Silla de juego
                <span className="featured-nav__link-count" style={{color:"#ec092c"}}>12</span>
              </a>
              <a
                className="link featured-nav__link fw-bold clr-heading"
                data-bs-toggle="list"
                href="#dining-set"
                aria-selected="false"
                role="tab"
              >
                Comedor
                <span className="featured-nav__link-count">10</span>
              </a>
              <a
                className="link featured-nav__link fw-bold clr-heading"
                data-bs-toggle="list"
                href="#office-table"
                aria-selected="false"
                role="tab"
                
              >
                Mesa de despacho
                <span className="featured-nav__link-count">20</span>
              </a>
              <a
                className="link featured-nav__link fw-bold clr-heading"
                data-bs-toggle="list"
                href="#office-desk"
                aria-selected="false"
                style={{color:"#FF7C0A"}}
                role="tab"
              >
                Escritorio de oficina
                <span className="featured-nav__link-count">09</span>
              </a>
              <a
                className="link d-inline-flex justify-content-center align-items-center gap-1 clr-heading :clr-secondary ms-md-auto"
                href="#"
              >
                <span className="d-inline-block fw-bold">
                  {" "}
                  Todos los muebles{" "}
                </span>
                <span className="d-inline-block clr-secondary">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </a>
            </div>
            <div className="tab-content">
              <div
                className="tab-pane fade active show"
                id="gaming-chair"
                role="tabpanel"
              >
                <div className="row g-4">
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={product1}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle" style={{color:"#FF7C0A"}}>
                          {" "}
                          Mesa de ordenador{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link" >
                            Mesa de centro de madera
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0" style={{color:"#FF7C0A"}}>
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={product2}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle" style={{color:"#FF7C0A"}}>
                          {" "}
                          Mesa de ordenador{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                          Mesa de centro de madera
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0" style={{color:"#FF7C0A"}}>
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={product3}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle" style={{color:"#FF7C0A"}}>
                          {" "}
                          Mesa de ordenador{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                          Mesa de centro de madera
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0" style={{color:"#FF7C0A"}}>
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={product4}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle" style={{color:"#FF7C0A"}}>
                          {" "}
                          Mesa de ordenador{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                          Mesa de centro de madera
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0" style={{color:"#FF7C0A"}}>
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={product5}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle" style={{color:"#FF7C0A"}}>
                          {" "}
                          Mesa de ordenador{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                          Mesa de centro de madera
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0" style={{color:"#FF7C0A"}}>
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={product6}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle" style={{color:"#FF7C0A"}}>
                          {" "}
                          Mesa de ordenador{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                          Mesa de centro de madera
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0" style={{color:"#FF7C0A"}}>
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={product7}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle" style={{color:"#FF7C0A"}}>
                          {" "}
                          Mesa de ordenador{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                          Mesa de centro de madera
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0" style={{color:"#FF7C0A"}}>
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={product8}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle"style={{color:"#FF7C0A"}}>
                          {" "}
                          Mesa de ordenador{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                          Mesa de centro de madera
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0" style={{color:"#FF7C0A"}}>
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="dining-set" role="tabpanel">
                <div className="row g-4">
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-1.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-2.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-3.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-4.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-5.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-6.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-7.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-8.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="office-table" role="tabpanel">
                <div className="row g-4">
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-1.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-2.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-3.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-4.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-5.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-6.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-7.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-8.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="office-desk" role="tabpanel">
                <div className="row g-4">
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-1.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-2.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-3.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-4.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-5.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-6.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-7.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card meat-card--secondary">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src="assets/img/home-7/product-8.png"
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <ul className="list gap-2 meat-card__icon-list">
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="link d-grid place-content-center w-9 h-9 rounded-circle light-bg clr-heading :two-bg :clr-light drop-shadow"
                            >
                              <i className="far fa-share-square"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="meat-card__body">
                        <span className="meat-card__subtitle">
                          {" "}
                          Computer Table{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Wooden Showpiece Table
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__current-price mb-0">
                              $55.00
                            </h6>
                          </div>
                          <a href="#" className="link meat-card__cart-btn">
                            {" "}
                            Add to Cart{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="product-banner">
              <img
                src={productBannerImg4}
                alt="image"
                className="img-fluid w-100"
              />
              <div className="product-banner__overlay">
                <span className="product-banner__subtitle">
                MEGA VENTA{" "}
                  <span className="fw-bold clr-secondary" style={{color:"#FF7C0A"}}>65% OFF</span>
                </span>
                <h3 className="product-banner__title">Nuevas colecciones de sofás</h3>
                <p className="product-banner__info clr-heading">
                Dirigirse asertivamente al capital intelectual orientado al mercado libre
                concurso de texto Bibendum holistic.
                </p>
                <a
                  href="#"
                  className="link button button--secondary button-effect button-effect--dark"
                  style={{background:"#FF7C0A", color:"white"}}
                >
                  <span className="d-inline-block fw-semibold"> Comprar ahora </span>
                  <span className="d-inline-block">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="product-banner">
              <img
                src={productBannerImg5}
                alt="image"
                className="img-fluid w-100"
              />
              <div className="product-banner__overlay">
                <span className="d-block fs-18">
                MEGA VENTA{" "}
                  <span className="fw-bold clr-secondary" style={{color:"#FF7C0A"}}>65% OFF</span>
                </span>
                <h3 className="fs-32">Colección de sillas</h3>
                <a
                  href="#"
                  className="link button button--primary button-md button-effect button-effect--dark"
                  style={{background:"#6db356", color:"white"}}
                >
                  <span className="d-inline-block fw-semibold"> Comprar ahora </span>
                  <span className="d-inline-block">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { FeaturedMueble };
