import "../../style/global.css"

function Feedback() {
    return (
        <div className="feedback-section section-space-sm-y">
            <div className="section-space-sm-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <h2 className="mb-0 display-6" style={{ fontWeight:"700"}}>Lo que dicen los participantes</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row g-4 g-lg-0 align-items-center">
                    <div className="col-lg-6">
                        <img src="img/home-6/feedback-img-1.png" alt="imagen" className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                        <div className="p-6">
                            <ul className="list list--row gap-1 mb-4">
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                            </ul>
                            <p className="mb-8 fs-20 fw-medium">
                                “Compre uno de sus producto, ha mejorado mi vida significativamente. Fácil de usar y muy efectivo.
                                Altamente recomendado para cualquier persona que necesite una solución confiable en salud.”
                            </p>
                            <div className="d-flex align-items-center gap-6">
                                <div className="w-16 h-16 rounded-circle overflow-hidden flex-shrink-0">
                                    <img src="img/home-6/user-1.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-cover" />
                                </div>
                                <div className="flex-grow-1">
                                    <h6 className="mb-0 fs-24">María López</h6>
                                    <p className="mb-0">Seguridad Industrial</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-4 order-lg-3">
                        <div className="p-6">
                            <ul className="list list--row justify-content-lg-end gap-1 mb-4">
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <span className="meat-card__star d-inline-block fs-24">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </li>
                            </ul>
                            <p className="mb-8 fs-20 fw-medium text-lg-end">
                                “he comprado producto médico para mi farmacia. Los clientes están muy satisfechos con los resultados.
                                Una herramienta que permite que los prodyctos lleguen rapidos, seguros a cualquier establecimiento de salud.”
                            </p>
                            <div className="d-flex align-items-center flex-lg-row-reverse gap-6">
                                <div className="w-16 h-16 rounded-circle overflow-hidden flex-shrink-0">
                                    <img src="img/home-6/user-2.png" alt="imagen" className="img-fluid w-100 h-100 object-fit-cover" />
                                </div>
                                <div className="flex-grow-1">
                                    <h6 className="mb-0 fs-24 text-lg-end">Juan Pérez</h6>
                                    <p className="mb-0 text-lg-end">Farmacia</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-3 order-lg-4">
                        <img src="img/home-6/feedback-img-2.png" alt="imagen" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Feedback }