import videoBg from "../../../../public/img/home-4/video-bg.png";
import thumbsUp from "../../../../public/img/icons/thumbs-up.svg";

function WcsSection() {
  return (
<section className="hl-wcs-section ptb-120">
            <div className="container position-relative">
                <div className="hl-video-box">
                    <img src={videoBg} alt="fondo de video" className="img-fluid" />
                    <a href="https://www.youtube.com/watch?v=6WZOxnYi4Cs&t" className="video-popup-btn"><i className="fas fa-play"></i></a>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-5">
                        <div className="text-center">
                            <span className="hl-subtitle fw-bold">Por Qué Elegirnos</span>
                            <h2 className="mb-6 mt-3">Por Qué Elegir Productos Halal de Grostore</h2>
                        </div>
                    </div>
                </div>
                <div className="mt-7">
                    <div className="row g-4">
                        <div className="col-xl-4 col-md-6">
                            <div className="text-xl-end">
                                <div className="hl-icon-box bg-white d-inline-block text-start">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle mb-4 p-3 bg-white">
                                        <img src={thumbsUp} alt="icono" className="img-fluid" />
                                    </span>
                                    <h5 className="mb-2">100% Pago Seguro</h5>
                                    <p className="mb-0 fs-sm">Envisionamos datos enfocados de manera fosforescente después de un liderazgo a prueba de futuro.</p>
                                </div>
                            </div>
                            <div className="text-start mt-7">
                                <div className="hl-icon-box bg-white d-inline-block">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle mb-4 p-3 bg-white">
                                        <img src={thumbsUp} alt="icono" className="img-fluid" />
                                    </span>
                                    <h5 className="mb-2">100% Pago Seguro</h5>
                                    <p className="mb-0 fs-sm">Envisionamos datos enfocados de manera fosforescente después de un liderazgo a prueba de futuro.</p>
                                </div>
                            </div>
                            <div className="text-xl-end mt-7">
                                <div className="hl-icon-box bg-white d-inline-block">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle mb-4 p-3 bg-white">
                                        <img src={thumbsUp} alt="icono" className="img-fluid" />
                                    </span>
                                    <h5 className="mb-2">100% Pago Seguro</h5>
                                    <p className="mb-0 fs-sm">Envisionamos datos enfocados de manera fosforescente después de un liderazgo a prueba de futuro.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 offset-xl-4">
                            <div className="text-start">
                                <div className="hl-icon-box bg-white d-inline-block text-start">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle mb-4 p-3 bg-white">
                                        <img src={thumbsUp} alt="icono" className="img-fluid" />
                                    </span>
                                    <h5 className="mb-2">100% Pago Seguro</h5>
                                    <p className="mb-0 fs-sm">Envisionamos datos enfocados de manera fosforescente después de un liderazgo a prueba de futuro.</p>
                                </div>
                            </div>
                            <div className="text-xl-end mt-7">
                                <div className="hl-icon-box bg-white d-inline-block text-start">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle mb-4 p-3 bg-white">
                                        <img src={thumbsUp} alt="icono" className="img-fluid" />
                                    </span>
                                    <h5 className="mb-2">100% Pago Seguro</h5>
                                    <p className="mb-0 fs-sm">Envisionamos datos enfocados de manera fosforescente después de un liderazgo a prueba de futuro.</p>
                                </div>
                            </div>
                            <div className="text-start mt-7">
                                <div className="hl-icon-box bg-white d-inline-block">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle mb-4 p-3 bg-white">
                                        <img src={thumbsUp} alt="icono" className="img-fluid" />
                                    </span>
                                    <h5 className="mb-2">100% Pago Seguro</h5>
                                    <p className="mb-0 fs-sm">Envisionamos datos enfocados de manera fosforescente después de un liderazgo a prueba de futuro.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export {WcsSection}
