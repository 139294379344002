import { Header } from "../../components/Home/header/Header"
import { BreadCrumb } from "../../components/productsView/breadCrumb/BreadCrumb"
import thumbSm1 from "../../../public/img/products/thumb-sm-1.png";
import { Footer } from "../../components/Home/footer/Footer";


function Favorites() {
  return (
    <div>
      <Header />
      <BreadCrumb title="Página de lista de deseos" pageName="Página" currentPage="lista de deseos" />

        <section className="wishlist-section ptb-120">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wishlist-table bg-white">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th className="text-center">Imagen</th>
                                        <th className="text-center">Nombre del Producto</th>
                                        <th className="text-center">Estado de Stock</th>
                                        <th className="text-center">Precio Unitario</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center thumbnail">
                                            <img src={thumbSm1} alt="product-thumb" className="img-fluid" />
                                        </td>
                                        <td>
                                            <span className="fw-bold text-secondary fs-xs">Vegetales</span>
                                            <h6 className="mb-1 mt-1">Tres Zanahorias Verduras Cocina Peruana</h6>
                                            <div className="star-rating">
                                                <ul className="rating-fields fs-xs text-warning d-inline-flex align-items-center">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="far fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">( 3 reseñas )</span>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <span className="stock-btn text-dark fw-bold fs-xxs d-inline-block rounded-pill">En Stock</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="price fw-bold text-dark">$918.00</span>
                                            <a href="#" className="btn btn-secondary btn-sm ms-5 rounded-1">Añadir al Carrito</a>
                                            <a href="#" className="close-btn ms-3"><i className="fas fa-close"></i></a>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td className="text-center thumbnail">
                                            <img src={thumbSm1} alt="product-thumb" className="img-fluid" />
                                        </td>
                                        <td>
                                            <span className="fw-bold text-secondary fs-xs">Vegetales</span>
                                            <h6 className="mb-1 mt-1">Tres Zanahorias Verduras Cocina Peruana</h6>
                                            <div className="star-rating">
                                                <ul className="rating-fields fs-xs text-warning d-inline-flex align-items-center">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="far fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">( 3 reseñas )</span>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <span className="stock-btn text-dark fw-bold fs-xxs d-inline-block rounded-pill">En Stock</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="price fw-bold text-dark">$918.00</span>
                                            <a href="#" className="btn btn-secondary btn-sm ms-5 rounded-1">Añadir al Carrito</a>
                                            <a href="#" className="close-btn ms-3"><i className="fas fa-close"></i></a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-center thumbnail">
                                            <img src={thumbSm1} alt="product-thumb" className="img-fluid" />
                                        </td>
                                        <td>
                                            <span className="fw-bold text-secondary fs-xs">Vegetales</span>
                                            <h6 className="mb-1 mt-1">Tres Zanahorias Verduras Cocina Peruana</h6>
                                            <div className="star-rating">
                                                <ul className="rating-fields fs-xs text-warning d-inline-flex align-items-center">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="far fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">( 3 reseñas )</span>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <span className="stock-btn text-dark fw-bold fs-xxs d-inline-block rounded-pill">En Stock</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="price fw-bold text-dark">$918.00</span>
                                            <a href="#" className="btn btn-secondary btn-sm ms-5 rounded-1">Añadir al Carrito</a>
                                            <a href="#" className="close-btn ms-3"><i className="fas fa-close"></i></a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-center thumbnail">
                                            <img src={thumbSm1} alt="product-thumb" className="img-fluid" />
                                        </td>
                                        <td>
                                            <span className="fw-bold text-secondary fs-xs">Vegetales</span>
                                            <h6 className="mb-1 mt-1">Tres Zanahorias Verduras Cocina Peruana</h6>
                                            <div className="star-rating">
                                                <ul className="rating-fields fs-xs text-warning d-inline-flex align-items-center">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="far fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">( 3 reseñas )</span>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <span className="stock-btn text-dark fw-bold fs-xxs d-inline-block rounded-pill">En Stock</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="price fw-bold text-dark">$918.00</span>
                                            <a href="#" className="btn btn-secondary btn-sm ms-5 rounded-1">Añadir al Carrito</a>
                                            <a href="#" className="close-btn ms-3"><i className="fas fa-close"></i></a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-center thumbnail">
                                            <img src={thumbSm1} alt="product-thumb" className="img-fluid" />
                                        </td>
                                        <td>
                                            <span className="fw-bold text-secondary fs-xs">Vegetales</span>
                                            <h6 className="mb-1 mt-1">Tres Zanahorias Verduras Cocina Peruana</h6>
                                            <div className="star-rating">
                                                <ul className="rating-fields fs-xs text-warning d-inline-flex align-items-center">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="far fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">( 3 reseñas )</span>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <span className="stock-btn text-dark fw-bold fs-xxs d-inline-block rounded-pill">En Stock</span>
                                        </td>
                                        <td className="text-end">
                                            <span className="price fw-bold text-dark">$918.00</span>
                                            <a href="#" className="btn btn-secondary btn-sm ms-5 rounded-1">Añadir al Carrito</a>
                                            <a href="#" className="close-btn ms-3"><i className="fas fa-close"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<Footer />

    </div>
  )
}

export {Favorites}
