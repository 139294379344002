import { Footer } from "../Home/footer/Footer"
import AboutCarniceria from "./aboutCarniceria/AboutCarniceria"
import { CategoryCarniceria } from "./categoryCarniceria/CategoryCarniceria"
import ChooseUsCarniceria from "./chooseUsCarniceria/ChooseUsCarniceria"
import CounterPromoCarniceria from "./counterPromoCarniceria/CounterPromoCarniceria"
import { EventCarniceria } from "./eventCarniceria/EventCarniceria"
import { FeedbackCarniceria } from "./feedbackCarniceria/FeedbackCarniceria"
import HeaderCarniceria from "./headerCarniceria/HeaderCarniceria"
import { OnSaleCarniceria } from "./onSaleCarniceria/OnSaleCarniceria"
import { PupularCarniceria } from "./pupularCarniceria/PupularCarniceria"
import { SliderCarniceria } from "./sliderCarniceria/SliderCarniceria"


function HomeCarniceria() {
  return (
    <div style={{background:"#F4F6F8"}}>
    <HeaderCarniceria />
    <SliderCarniceria />
    <CounterPromoCarniceria />
    <CategoryCarniceria />
    <AboutCarniceria />
    <PupularCarniceria />
    <ChooseUsCarniceria />
    <FeedbackCarniceria />
    <OnSaleCarniceria />
    <EventCarniceria />
    <Footer />
    </div>
  )
}

export {HomeCarniceria}
