


function Feedblack() {
  return (
    <section className="ptb-120 bg-shade position-relative overflow-hidden z-1 feedback-section">
    <img src="img/shapes/bg-shape-5.png" alt="bg shape" className="position-absolute start-0 bottom-0 z--1 w-100" />
    <img src="img/shapes/map-bg.png" alt="map" className="position-absolute start-50 top-50 translate-middle z--1" />
    <img src="img/shapes/fd-1.png" alt="shape" className="position-absolute z--1 fd-1" />
    <img src="img/shapes/fd-2.png" alt="shape" className="position-absolute z--1 fd-2" />
    <img src="img/shapes/fd-3.png" alt="shape" className="position-absolute z--1 fd-3" />
    <img src="img/shapes/fd-4.png" alt="shape" className="position-absolute z--1 fd-4" />
    <img src="img/shapes/fd-5.png" alt="shape" className="position-absolute z--1 fd-5" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6">
          <div className="section-title text-center">
            <h2 className="mb-6">Lo que dicen nuestros clientes</h2>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="gshop-feedback-slider-wrapper">
            <div className="swiper gshop-feedback-thumb-slider swiper-coverflow swiper-3d">
              <div className="swiper-wrapper">
                {[1, 2, 3, 4, 5].map((index) => (
                  <div key={index} className="swiper-slide control-thumb">
                    <img src={`img/authors/client-${index}.png`} alt="clients" className="img-fluid rounded-circle" />
                  </div>
                ))}
              </div>
            </div>
            <div className="swiper gshop-feedback-slider mt-4">
              <div className="swiper-wrapper">
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="swiper-slide feedback-single text-center">
                    <p className="mb-5">
                    “Libere de forma distintiva tecnologías empresariales sin métricas de backend. Competencias básicas convenientemente distribuidas en red. Integrar continuamente información compatible con versiones anteriores y compatible con versiones anteriores”
                    </p>
                    <span className="clients_name text-dark fw-bold d-block mb-1">Rasmus Geisler</span>
                    <ul className="star-rating fs-sm d-inline-flex align-items-center text-warning">
                      {[...Array(5)].map((_, starIndex) => (
                        <li key={starIndex}><i className="fas fa-star"></i></li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export  {Feedblack}