import a from "../../../../public/img/home-5/products/1.png";
import b from "../../../../public/img/home-5/products/2.png";
import c from "../../../../public/img/home-5/products/3.png";
import d from "../../../../public/img/home-5/products/4.png";
import e from "../../../../public/img/home-5/products/5.png";
import f from "../../../../public/img/home-5/products/6.png";
import g from "../../../../public/img/home-5/products/7.png";
import h from "../../../../public/img/home-5/products/8.png";

function PupularCarniceria() {
  return (
    <section className="section-space-top section-space-sm-bottom">
      <div className="section-space-sm-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-xl-8 col-xxl-6">
              <h2 className="text-center h2 display-6 mb-4">
                Más populares <span className="meat-primary">Productos</span>
              </h2>
              <p className="mb-0 text-center">
                Promover de forma monótona tecnologías proactivas con altos
                estándares de fabricación
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="list-group d-flex flex-wrap flex-row gap-4 justify-content-center mb-12"
              role="tablist"
            >
              <a
                className="meat-category-tab active"
                data-bs-toggle="list"
                href="#beefMeat"
                aria-selected="true"
                role="tab"
              >
                Carne de vacuno
              </a>
              <a
                className="meat-category-tab"
                data-bs-toggle="list"
                href="#chickenMeat"
                aria-selected="false"
                role="tab"
              >
                Carne de pollo
              </a>
              <a
                className="meat-category-tab"
                data-bs-toggle="list"
                href="#goatMeat"
                aria-selected="false"
                role="tab"
              >
                Carne de cabra
              </a>
              <a
                className="meat-category-tab"
                data-bs-toggle="list"
                href="#processedMeat"
                aria-selected="false"
                role="tab"
              >
                Carne procesada
              </a>
              <a
                className="meat-category-tab"
                data-bs-toggle="list"
                href="#frozenMeat"
                aria-selected="false"
                role="tab"
              >
                Carne congelada
              </a>
            </div>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="beefMeat"
                role="tabpanel"
              >
                <div className="row g-4">
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={a}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <span className="meat-card__badge"> A la venta </span>
                      </div>
                      <div className="meat-card__body">
                        <span
                          className="meat-card__subtitle"
                          style={{ color: "#ec092c", fontWeight: "800" }}
                        >
                          {" "}
                          Carne de vacuno{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Envejecido Bistec de Vacuno
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                              $95.00
                            </h6>
                            <h6
                              className="meat-card__current-price mb-0"
                              style={{ color: "#ec092c", fontWeight: "800" }}
                            >
                              $55.00
                            </h6>
                          </div>
                          <a
                            href="#"
                            className="link meat-card__cart-btn"
                            style={{ background: "#ec092c", color: "white" }}
                          >
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={b}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                      </div>
                      <div className="meat-card__body">
                        <span
                          className="meat-card__subtitle"
                          style={{ color: "#ec092c", fontWeight: "800" }}
                        >
                          {" "}
                          Carne de vacuno{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Carne de pollo Buffalo Wing
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                              $95.00
                            </h6>
                            <h6
                              className="meat-card__current-price mb-0"
                              style={{ color: "#ec092c", fontWeight: "800" }}
                            >
                              $55.00
                            </h6>
                          </div>
                          <a
                            href="#"
                            className="link meat-card__cart-btn"
                            style={{ background: "#ec092c", color: "white" }}
                          >
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={c}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                      </div>
                      <div className="meat-card__body">
                        <span
                          className="meat-card__subtitle"
                          style={{ color: "#ec092c", fontWeight: "800" }}
                        >
                          {" "}
                          Carne de vacuno{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Envejecido Bistec de Vacuno
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                              $95.00
                            </h6>
                            <h6
                              className="meat-card__current-price mb-0"
                              style={{ color: "#ec092c", fontWeight: "800" }}
                            >
                              $55.00
                            </h6>
                          </div>
                          <a
                            href="#"
                            className="link meat-card__cart-btn"
                            style={{ background: "#ec092c", color: "white" }}
                          >
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={d}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <span className="meat-card__badge"> A la venta </span>
                      </div>
                      <div className="meat-card__body">
                        <span
                          className="meat-card__subtitle"
                          style={{ color: "#ec092c", fontWeight: "800" }}
                        >
                          {" "}
                          Carne de vacuno{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Envejecido Bistec de Vacuno
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                              $95.00
                            </h6>
                            <h6
                              className="meat-card__current-price mb-0"
                              style={{ color: "#ec092c", fontWeight: "800" }}
                            >
                              $55.00
                            </h6>
                          </div>
                          <a
                            href="#"
                            className="link meat-card__cart-btn"
                            style={{ background: "#ec092c", color: "white" }}
                          >
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={e}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                      </div>
                      <div className="meat-card__body">
                        <span
                          className="meat-card__subtitle"
                          style={{ color: "#ec092c", fontWeight: "800" }}
                        >
                          {" "}
                          Carne de vacuno{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Costillas Cordero &amp; Carne de cordero
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                              $95.00
                            </h6>
                            <h6
                              className="meat-card__current-price mb-0"
                              style={{ color: "#ec092c", fontWeight: "800" }}
                            >
                              $55.00
                            </h6>
                          </div>
                          <a
                            href="#"
                            className="link meat-card__cart-btn"
                            style={{ background: "#ec092c", color: "white" }}
                          >
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={f}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <span className="meat-card__badge"> A la venta </span>
                      </div>
                      <div className="meat-card__body">
                        <span
                          className="meat-card__subtitle"
                          style={{ color: "#ec092c", fontWeight: "800" }}
                        >
                          {" "}
                          Carne de vacuno{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            {" "}
                            Bistec Carne de vacuno{" "}
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                              $95.00
                            </h6>
                            <h6
                              className="meat-card__current-price mb-0"
                              style={{ color: "#ec092c", fontWeight: "800" }}
                            >
                              $55.00
                            </h6>
                          </div>
                          <a
                            href="#"
                            className="link meat-card__cart-btn"
                            style={{ background: "#ec092c", color: "white" }}
                            
                          >
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={g}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                        <span className="meat-card__badge"> A la venta </span>
                      </div>
                      <div className="meat-card__body">
                        <span
                          className="meat-card__subtitle"
                          style={{ color: "#ec092c", fontWeight: "800" }}
                        >
                          {" "}
                          Carne de vacuno{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Envejecido Bistec de Vacuno
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                              $95.00
                            </h6>
                            <h6
                              className="meat-card__current-price mb-0"
                              style={{ color: "#ec092c", fontWeight: "800" }}
                            >
                              $55.00
                            </h6>
                          </div>
                          <a
                            href="#"
                            className="link meat-card__cart-btn"
                            style={{ background: "#ec092c", color: "white" }}
                          >
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <div className="meat-card">
                      <div className="meat-card__img text-center">
                        <a href="#" className="link d-inline-block text-center">
                          <img
                            src={h}
                            alt="image"
                            className="img-fluid w-100 h-100 object-fit-contain"
                          />
                        </a>
                      </div>
                      <div className="meat-card__body">
                        <span
                          className="meat-card__subtitle"
                          style={{ color: "#ec092c", fontWeight: "800" }}
                        >
                          {" "}
                          Carne de vacuno{" "}
                        </span>
                        <h6 className="meat-card__title">
                          <a href="#" className="link meat-card__title-link">
                            Cordero &amp; Tocino de lomo de cordero
                          </a>
                        </h6>
                        <ul className="list list--row">
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                          <li>
                            <span className="meat-card__star d-inline-block">
                              <i className="fas fa-star"></i>
                            </span>
                          </li>
                        </ul>
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-4">
                          <div className="d-flex align-items-center gap-3">
                            <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                              $95.00
                            </h6>
                            <h6
                              className="meat-card__current-price mb-0"
                              style={{ color: "#ec092c", fontWeight: "800" }}
                            >
                              $55.00
                            </h6>
                          </div>
                          <a
                            href="#"
                            className="link meat-card__cart-btn"
                            style={{ background: "#ec092c", color: "white" }}
                          >
                            {" "}
                            Añadir a la cesta{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { PupularCarniceria };
