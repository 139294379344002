import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart as farHeart, faEye as fasEye } from '@fortawesome/free-regular-svg-icons';
import { faArrowUp, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons';
import "../../style/global.css"


function Featured() {
  return (
    <section className="featured-products pt-120 pb-200 bg-shade position-relative overflow-hidden z-1">
      <img
        src="img/shapes/roll-1.png"
        alt="roll"
        className="position-absolute roll-1 z--1"
        style={{
          transform:
            'translate3d(0px, -1.182px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)',
          WebkitTransform:
            'translate3d(0px, -1.182px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)',
        }}
      />
      <img
        src="img/shapes/roll-2.png"
        alt="roll"
        className="position-absolute roll-2 z--1"
        style={{
          transform:
            'translate3d(0px, 1.212px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)',
          WebkitTransform:
            'translate3d(0px, 1.212px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)',
        }}
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-4">
              <h3 className="mb-2" style={{ color: "#191d28", fontWeight:"700"}}>Productos de marca destacados</h3>
              <p className="mb-0" style={{ fontWeight: '500', color: '#5d6374',  marginBottom: '1.75rem', fontSize: '.9rem'}}>Plataforma mindshare a través de infomediarios eficaces Implantar dinámicamente..</p>
            </div>
          </div>
        </div>
        <div className="row g-4 justify-content-center">
          <div className="col-xxl-4 col-lg-6">
            <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 gap-4">
              <div className="thumbnail position-relative rounded-2">
                <a href="product-details.html">
                  <img src="img/products/p-sm-1.png" alt="product" className="img-fluid" />
                </a>
                <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                  <a href="#" className="rounded-btn">
                    <FontAwesomeIcon icon={farHeart} />
                  </a>
                  <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn">
                    <FontAwesomeIcon icon={fasEye} />
                  </a>
                  <a href="#" className="rounded-btn">
                    <FontAwesomeIcon icon={faArrowUp} />
                  </a>
                </div>
              </div>
              <div className="card-content mt-4 mt-sm-0">
                <div className="d-flex align-items-center flex-nowrap star-rating">
                  <ul className="d-flex align-items-center me-2">
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                  </ul>
                  <span className="flex-shrink-0">(5.2k Reviews)</span>
                </div>
                <a href="product-details.html" className="fw-bold text-heading title d-block">
                  Strawberry Fruit
                </a>
                <div className="pricing mt-2">
                  <span className="fw-bold h4 deleted me-1 text-muted">$230.00</span>
                  <span className="fw-bold h4 text-danger">$180.00</span>
                </div>
                <a href="product-details.html" className="fs-xs fw-bold mt-3 d-inline-block explore-btn">
                  Shop Now
                  <span className="ms-1">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </a>
              </div>
              
            </div>
          </div>
          {/* Repeat the same structure for the other products */}
          <div className="col-xxl-4 col-lg-6 order-3 order-xxl-2">
            <div className="product-card-lg bg-white rounded-2 d-flex flex-coloumn h-100">
              <div>
                <div className="card-content position-relative z-2">
                  <span className="fs-xs gshop-subtitle text-secondary">100% Organic Products</span>
                  <h4 className="mb-0">Fresh Fruits</h4>
                  <h3 className="mb-3">Healthy Juice</h3>
                  <p className="mb-4">Get 50% Off on Selected Organic Items</p>
                  <a href="product-details.html" className="btn btn-secondary">
                    Shop Now
                    <span className="ms-2">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </a>
                </div>
                <div className="thumbnail position-relative z-1">
                  <img src="img/products/pago.png" alt="pago" className="img-fluid p-4" />
                  <img
                    src="img/shapes/circle-md.png"
                    alt="circle"
                    className="position-absolute end-0 bottom-0 z--1 d-none d-sm-block"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-lg-6 order-2 order-xxl-3">
            <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 gap-4">
              <div className="thumbnail position-relative rounded-2">
                <a href="product-details.html">
                  <img src="img/products/p-sm-1.png" alt="product" className="img-fluid" />
                </a>
                <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                  <a href="#" className="rounded-btn">
                    <FontAwesomeIcon icon={farHeart} />
                  </a>
                  <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn">
                    <FontAwesomeIcon icon={fasEye} />
                  </a>
                  <a href="#" className="rounded-btn">
                    <FontAwesomeIcon icon={faArrowUp} />
                  </a>
                </div>
              </div>
              <div className="card-content mt-4 mt-sm-0">
                <div className="d-flex align-items-center flex-nowrap star-rating">
                  <ul className="d-flex align-items-center me-2">
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="text-warning">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                  </ul>
                  <span className="flex-shrink-0">(5.2k Reviews)</span>
                </div>
                <a href="product-details.html" className="fw-bold text-heading title d-block">
                  Strawberry Fruit
                </a>
                <div className="pricing mt-2">
                  <span className="fw-bold h4 deleted me-1 text-muted">$230.00</span>
                  <span className="fw-bold h4 text-danger">$180.00</span>
                </div>
                <a href="product-details.html" className="fs-xs fw-bold mt-3 d-inline-block explore-btn">
                  Shop Now
                  <span className="ms-1">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </a>
              </div>
              
            </div>
            
          
            </div>
      </div>
      </div>
      <img
        src="img/shapes/bg-shape-2.png"
        alt="bg shape"
        className="position-absolute start-0 bottom-0 w-100 z--1"
      />
    </section>
  )
}

export  {Featured}