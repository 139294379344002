import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../style/global.css";

function CategoryStart() {
  return (
    <section className="gshop-category-section bg-white pt-120 position-relative z-1 overflow-hidden">
    <img src="img/shapes/bg-shape.png" alt="bg shape" className="position-absolute bottom-0 start-0 w-100 z--1" />
    <div className="container">
      <div className="gshop-category-box rounded-3 bg-white" style={{borderColor:"#ff7c08"}}>
        <div className="text-center section-title">
          <h4 className="d-inline-block px-2 bg-white mb-4 " style={{ color: "#191d28", fontWeight:"700"}}>Nuestra categoría principal</h4>
        </div>
        <div className="row justify-content-center g-4">
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img src="img/category/vegetable.png" alt="vegetable" className="img-fluid" />
              </div>
              <a href="#" className="text-dark fs-sm fw-bold d-block mt-3" style={{textDecoration: "none"}}>Verduras</a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">25 Artículos</span>
              <a href="#" className="explore-btn position-absolute">
                <FontAwesomeIcon icon="arrow-up" />
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-2">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img src="img/category/fresh-fruits.png" alt="fresh fruits" className="img-fluid" />
              </div>
              <a href="#" className="text-dark fs-sm fw-bold d-block mt-3" style={{textDecoration: "none"}}>Frutas frescas</a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">25 Artículos</span>
              <a href="#" className="explore-btn position-absolute">
                <FontAwesomeIcon icon="arrow-up" />
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-3">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img src="img/category/milk.png" alt="milk" className="img-fluid" />
              </div>
              <a href="#" className="text-dark fs-sm fw-bold d-block mt-3" style={{textDecoration: "none"}}>Leche &amp; Lácteos</a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">25 Artículos</span>
              <a href="#" className="explore-btn position-absolute">
                <FontAwesomeIcon icon="arrow-up" />
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-4">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img src="img/category/coffee-drinks.png" alt="coffee drinks" className="img-fluid" />
              </div>
              <a href="#" className="text-dark fs-sm fw-bold d-block mt-3" style={{textDecoration: "none"}}>Café &amp; Bebidas</a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">25 Artículos</span>
              <a href="#" className="explore-btn position-absolute">
                <FontAwesomeIcon icon="arrow-up" />
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-5">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img src="img/category/meat.png" alt="meat" className="img-fluid" />
              </div>
              <a href="#" className="text-dark fs-sm fw-bold d-block mt-3" style={{textDecoration: "none"}}>Carne</a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">25 Artículos</span>
              <a href="#" className="explore-btn position-absolute">
                <FontAwesomeIcon icon="arrow-up" />
              </a>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-6">
            <div className="gshop-animated-iconbox py-5 px-4 text-center border rounded-3 position-relative overflow-hidden color-3">
              <div className="animated-icon d-inline-flex align-items-center justify-content-center rounded-circle position-relative">
                <img src="img/category/cleaning.png" alt="cleaning essential" className="img-fluid" />
              </div>
              <a href="#" className="text-dark fs-sm fw-bold d-block mt-3" style={{textDecoration: "none"}}>Limpieza esencial</a>
              <span className="total-count position-relative ps-3 fs-sm fw-medium doted-primary">25 Artículos</span>
              <a href="#" className="explore-btn position-absolute">
                <FontAwesomeIcon icon="arrow-up" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export {CategoryStart}