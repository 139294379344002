import React from "react";
import { Header } from "../../components/Home/header/Header"
import { BreadCrumb } from "../../components/productsView/breadCrumb/BreadCrumb"
import mango from "../../../public/img/shapes/mango.png";
import ab1 from "../../../public/img/about/ab-1.png";
import ab2 from "../../../public/img/about/ab-2.png";
import handIcon from "../../../public/img/icons/hand-icon.svg";
import brand2 from "../../../public/img/brands/brand-2.png";
import brand1 from "../../../public/img/brands/brand-1.png";
import brand3 from "../../../public/img/brands/brand-3.png";
import brand4 from "../../../public/img/brands/brand-4.png";
import brand5 from "../../../public/img/brands/brand-5.png";
import bgShape4 from "../../../public/img/shapes/bg-shape-4.png";
import bgShape5 from "../../../public/img/shapes/bg-shape-5.png";
import rollColor from "../../../public/img/shapes/roll-color.png";
import rollColorCurve from "../../../public/img/shapes/roll-color-curve.png";
import onionColor from "../../../public/img/shapes/onion-color.png";
import icon1 from "../../../public/img/icons/icon-1.png";
import icon2 from "../../../public/img/icons/icon-2.png";
import icon3 from "../../../public/img/icons/icon-3.png";
import icon4 from "../../../public/img/icons/icon-4.png";
import clients from "../../../public/img/about/clients.png";
import client3 from "../../../public/img/authors/client-3.png";
import client1 from "../../../public/img/authors/client-1.png";
import client2 from "../../../public/img/authors/client-2.png";
import team1 from "../../../public/img/authors/team-1.jpg";
import team2 from "../../../public/img/authors/team-2.jpg";
import ctaBanner from "../../../public/img/banner/cta-banner.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Footer } from "../../components/Home/footer/Footer";

interface CounterProps {
  end: number;
}

const Counter: React.FC<CounterProps> = ({ end }) => {
    const [count, setCount] = React.useState(0);
  
    React.useEffect(() => {
      let start = 0;
      const endNumber = end;
      const duration = 2000; // Duración de la animación en milisegundos
      const stepTime = Math.abs(Math.floor(duration / endNumber));
      
      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start === endNumber) clearInterval(timer);
      }, stepTime);
  
      return () => clearInterval(timer);
    }, [end]);
  
    return <h3 className="mb-1">{count}k+</h3>;
  };

  const teamMembers = [
    {
      imgUrl: team1,
      name: 'Frances Gilmartin',
      role: 'CEO & Fundador',
      socialLinks: [
        { url: '#', icon: 'fab fa-facebook-f' },
        { url: '#', icon: 'fab fa-twitter' },
        { url: '#', icon: 'fab fa-behance' },
      ],
    },
    {
      imgUrl: team2,
      name: 'Frances Gilmartin',
      role: 'CEO & Fundadora',
      socialLinks: [
        { url: '#', icon: 'fab fa-facebook-f' },
        { url: '#', icon: 'fab fa-twitter' },
        { url: '#', icon: 'fab fa-behance' },
      ],
    },
    {
      imgUrl: team1,
      name: 'Frances Gilmartin',
      role: 'CEO & Fundadora',
      socialLinks: [
        { url: '#', icon: 'fab fa-facebook-f' },
        { url: '#', icon: 'fab fa-twitter' },
        { url: '#', icon: 'fab fa-behance' },
      ],
    },
    
  ];

function About() {
  return (
    <div>
      <Header />
      <BreadCrumb  title="Sobre nosotros" pageName="Página" currentPage="Acerca de" />

      <section className="pt-120 ab-about-section position-relative z-1 overflow-hidden">
        <img src={mango} alt="mango" className="position-absolute mango z--1" />
        <div className="container">
          <div className="row g-5 g-xl-4 align-items-center">
            <div className="col-xl-6">
              <div className="ab-left position-relative">
                <img src={ab1} alt="imagen" className="img-fluid" />
                <div className="text-end">
                  <div className="ab-quote p-4 text-start">
                    <h4 className="mb-0 fw-normal text-white">“Objetivamente apuntar al mercado. Lorem ipsum es simplemente un texto libre, no elit. Sed do eiusmod.” <span className="fs-md fw-medium position-relative">George Scholll</span></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="ab-about-right">
                <div className="subtitle d-flex align-items-center gap-3 flex-wrap">
                  <span className="gshop-subtitle">100% Alimentos Orgánicos</span>
                  <span>
                    <svg width="78" height="16" viewBox="0 0 78 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="0.0138875" y1="7.0001" x2="72.0139" y2="8.0001" stroke="#FF7C08" strokeWidth="2"></line>
                      <path d="M78 8L66 14.9282L66 1.0718L78 8Z" fill="#FF7C08"></path>
                    </svg>    
                  </span>
                </div>
                <h2 className="mb-4">Sé saludable y come<br /> alimentos orgánicos frescos</h2>
                <p className="mb-8">Objetivamente apuntar al mercado. Lorem ipsum es simplemente texto libre disponible. Dolor sit amet, consectetur adipisicing elit. Sed do eiusmod tempor incididunt labore y dolore.</p>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="image-box py-6 px-4 image-box-border">
                      <div className="icon position-relative">
                        <img src={handIcon} alt="icono de mano" className="img-fluid" />
                      </div>
                      <h4 className="mt-3">Nuestra Misión</h4>
                      <p className="mb-0">Transformar continuamente las metodologías virtuales meta. Aprovechar las alineaciones existentes.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="image-box py-6 px-4 image-box-border">
                      <div className="icon position-relative">
                        <img src={handIcon} alt="icono de mano" className="img-fluid" />
                      </div>
                      <h4 className="mt-3">Nuestra Visión</h4>
                      <p className="mb-0">Transformar continuamente las metodologías virtuales meta. Aprovechar las alineaciones existentes.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    <section className="brands-section ptb-120 position-relative z-1 overflow-hidden service-section">
      <img src={bgShape4} alt="bg shape" className="position-absolute start-0 bottom-0 w-100 z--1 bg-shape" />
      <div className="container">
        <div className="brand-wrapper px-5 rounded-4">
          <h4 className="section-title mb-0">Las Marcas Más Populares</h4>
          <div className="brands-slider swiper px-2 pt-4 pb-7 swiper-initialized swiper-horizontal swiper-pointer-events">
            <div className="swiper-wrapper" aria-live="off">
              <div className="swiper-slide brand-item  swiper-slide-duplicate" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand2} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item  swiper-slide-duplicate" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand3} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item  swiper-slide-duplicate" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand4} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item  swiper-slide-duplicate" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand5} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item  swiper-slide-duplicate-prev" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand2} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item  swiper-slide-duplicate-active" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand1} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item  swiper-slide-duplicate-next" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand2} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item border-none" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand3}alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item rounded" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand4}alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item rounded" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand5} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item rounded swiper-slide-prev" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand2} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item rounded swiper-slide-active" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand1} alt="brand" className="img-fluid py-4" />
              </div>
              <div className="swiper-slide brand-item  swiper-slide-next" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand2} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item rounded" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand3} alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item rounded" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand4}alt="brand" className="img-fluid border-none py-4" />
              </div>
              <div className="swiper-slide brand-item rounded" style={{ width: '233.2px', marginRight: '24px' }}>
                <img src={brand5} alt="brand" className="img-fluid border-none py-4" />
              </div>
            </div>
            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
          </div>
        </div>
      </div>
    </section>

    <section className="about-section bg-shade position-relative z-1">
        <img src={bgShape5} alt="bg shape" className="position-absolute start-0 bottom-0 z--1 w-100" />
        <img src={rollColor} alt="roll" className="position-absolute roll-color z--1" data-parallax="{&quot;y&quot;: -50}" style={{ transform: 'translate3d(0px, -34.252px, 0px)' }} />
        <img src={rollColorCurve} alt="roll" className="position-absolute roll-color-curve z--1" data-parallax="{&quot;y&quot;: 50}" style={{ transform: 'translate3d(0px, 35.876px, 0px)' }} />
        <img src={onionColor} alt="onion" className="position-absolute onion-color z--1" data-parallax="{&quot;x&quot;: -30}" style={{ transform: 'translate3d(-13.186px, 0px, 0px)' }} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="section-title text-center">
                <h2 className="mb-3">Nuestra Capacidad de Trabajo</h2>
                <p className="mb-0">Apuntar al mercado de manera asertiva, Lorem ipsum es simplemente un texto libre disponible dolor incididunt simplemente libre ut labore y dolore.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4 mt-4">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="horizontal-counter d-flex align-items-center gap-3 bg-white rounded p-4">
                <span className="icon-wrapper d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <img src={icon1} alt="icon" className="img-fluid" />
                </span>
                <div className="numbers">
                  <Counter end={3256} />
                  <h6 className="mb-0 text-gray fs-sm">Productos Totales</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="horizontal-counter d-flex align-items-center gap-3 bg-white rounded p-4">
                <span className="icon-wrapper d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <img src={icon2} alt="icon" className="img-fluid" />
                </span>
                <div className="numbers">
                  <Counter end={2456} />
                  <h6 className="mb-0 text-gray fs-sm">Pedidos Totales</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="horizontal-counter d-flex align-items-center gap-3 bg-white rounded p-4">
                <span className="icon-wrapper d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <img src={icon3} alt="icon" className="img-fluid" />
                </span>
                <div className="numbers">
                  <Counter end={1250} />
                  <h6 className="mb-0 text-gray fs-sm">Visitantes Totales</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="horizontal-counter d-flex align-items-center gap-3 bg-white rounded p-4">
                <span className="icon-wrapper d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <img src={icon4} alt="icon" className="img-fluid" />
                </span>
                <div className="numbers">
                  <Counter end={1245} />
                  <h6 className="mb-0 text-gray fs-sm">Entregas Totales</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feedback-section pt-100 pb-120 position-relative z-1 overflow-hidden service-section">
      <img src={bgShape4} alt="forma de fondo" className="position-absolute start-0 bottom-0 w-100 z--1 bg-shape" />
      <div className="container">
        <div className="row align-items-center g-4">
          <div className="col-xl-7">
            <div className="clients_thumb">
              <img src={clients} alt="clientes" className="img-fluid" />
            </div>
          </div>
          <div className="col-xl-5">
            <div className="swiper feedback-slider-2 swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
              <div className="swiper-wrapper" id="swiper-wrapper-2c9438d10d5bbcb1d" aria-live="off" style={{ transitionDuration: '700ms', transform: 'translate3d(-1120px, 0px, 0px)' }}>
                <div className="swiper-slide feedback-card bg-white rounded py-6 px-4 swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="3 / 3" style={{ width: '536px', marginRight: '24px' }}>
                  <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                    <img src={client3} alt="cliente" className="img-fluid rounded-circle flex-shrink-0" />
                    <div className="clients-info">
                      <h5 className="mb-1">George Nakashima</h5>
                      <ul className="d-flex align-items-center fs-xs text-warning">
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                      </ul>
                    </div>
                  </div>
                  <p className="mb-0">“Sinergiza convenientemente la colaboración premium y el intercambio de ideas con un pensamiento innovador. Producto interactivo con paradigmas distintivos mientras capital intelectual uno a uno.”</p>
                </div>
                <div className="swiper-slide feedback-card bg-white rounded py-6 px-4 swiper-slide-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 3" style={{ width: '536px', marginRight: '24px' }}>
                  <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                    <img src={client1} alt="cliente" className="img-fluid rounded-circle flex-shrink-0" />
                    <div className="clients-info">
                      <h5 className="mb-1">George Nakashima</h5>
                      <ul className="d-flex align-items-center fs-xs text-warning">
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                      </ul>
                    </div>
                  </div>
                  <p className="mb-0">“Sinergiza convenientemente la colaboración premium y el intercambio de ideas con un pensamiento innovador. Producto interactivo con paradigmas distintivos mientras capital intelectual uno a uno.”</p>
                </div>
                <div className="swiper-slide feedback-card bg-white rounded py-6 px-4 swiper-slide-active" data-swiper-slide-index="1" role="group" aria-label="2 / 3" style={{ width: '536px', marginRight: '24px' }}>
                  <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                    <img src={client2} alt="cliente" className="img-fluid rounded-circle flex-shrink-0" />
                    <div className="clients-info">
                      <h5 className="mb-1">George Nakashima</h5>
                      <ul className="d-flex align-items-center fs-xs text-warning">
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                        <li><i className="fas fa-star"></i></li>
                      </ul>
                    </div>
                  </div>
                  <p className="mb-0">“Sinergiza convenientemente la colaboración premium y el intercambio de ideas con un pensamiento innovador. Producto interactivo con paradigmas distintivos mientras capital intelectual uno a uno.”</p>
                </div>
              </div>
              <div className="slider-arrows text-end mt-5">
                <button type="button" className="fd2-arrow-left"  aria-label="Diapositiva anterior" aria-controls="swiper-wrapper-2c9438d10d5bbcb1d">
                  <i className="fas fa-angle-left"></i>
                </button>
                <button type="button" className="fd2-arrow-right"  aria-label="Diapositiva siguiente" aria-controls="swiper-wrapper-2c9438d10d5bbcb1d">
                  <i className="fas fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="grostore-team-section pt-6 bg-shade position-relative z-1 overflow-hidden">
      <img
        src={bgShape5}
        alt="Forma de fondo"
        className="position-absolute start-0 bottom-0 z--1 w-100"
      />
      <div className="container">
        <div className="row align-items-center g-3">
          <div className="col-xl-3">
            <div className="section-title">
              <div className="d-flex align-items-center gap-2 flex-wrap">
                <h6 className="mb-0 gshop-subtitle text-secondary">
                  Miembros del equipo
                </h6>
                <span>
                  <svg
                    width="58"
                    height="10"
                    viewBox="0 0 58 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0"
                      y1="5.2"
                      x2="52"
                      y2="5.2"
                      stroke="#FF7C08"
                      strokeWidth="1.6"
                    />
                    <path
                      d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z"
                      fill="#FF7C08"
                    />
                  </svg>
                </span>
              </div>
              <h2 className="mb-3">
                ¡Nuestro equipo de atención al cliente en línea!
              </h2>
              <p className="mb-7">
                Encuentra racionalmente extremadamente doloroso a cualquiera.
              </p>
              <div className="d-flex align-items-center gap-3">
                <button
                  type="button"
                  className="team-slider-prev-btn team-slider-btn"
                  aria-label="Diapositiva anterior"
                >
                  <i className="fas fa-angle-left"></i>
                </button>
                <button
                  type="button"
                  className="team-slider-next-btn team-slider-btn"
                  aria-label="Siguiente diapositiva"
                >
                  <i className="fas fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <Swiper
              spaceBetween={24}
              slidesPerView={3} // Esto permite que se vean las 3 tarjetas
              loop={false}
            >
              {teamMembers.map((member, index) => (
                <SwiperSlide key={index}>
                  <div className="team-card text-center bg-white rounded py-7 px-4 swiper-slide">
                    <div className="team-thumb mb-5">
                      <img
                        src={member.imgUrl}
                        alt="Miembro del equipo"
                        className="img-fluid rounded-circle"
                      />
                      <div className="team-social">
                        {member.socialLinks.map((link, i) => (
                          <a href={link.url} key={i}>
                            <i className={link.icon}></i>
                          </a>
                        ))}
                      </div>
                    </div>
                    <h5>{member.name}</h5>
                    <span>{member.role}</span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>

    <section className="cta-section pb-30">
      <div className="container">
        <div
          className="cta-box rounded text-center bg-cover bg-center"
          style={{ backgroundImage: `url(${ctaBanner})` }}
        >
          <div className="flex items-center justify-center flex-wrap gap-2 mb-2">
            <h6 className="mb-0 text-secondary gshop-subtitle">Oferta de Fin de Semana</h6>
            <span>
              <svg
                width="58"
                height="10"
                viewBox="0 0 58 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0"
                  y1="5.2"
                  x2="52"
                  y2="5.2"
                  stroke="#FF7C08"
                  strokeWidth="1.6"
                />
                <path
                  d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z"
                  fill="#FF7C08"
                />
              </svg>
            </span>
          </div>
          <h3 className="mb-5">Alimentos Orgánicos Hasta el 40% de Descuento</h3>
          <a href="shop-grid.html" className="btn btn-secondary">
            Comprar Ahora
            <span className="ml-4">
              <i className="fas fa-arrow-right"></i>
            </span>
          </a>
        </div>
      </div>
    </section>

    <section className="about-us-section pb-120">
      <div className="container">
        <div className="row g-4 align-items-center">
          <div className="col-xl-5">
            <div className="about-us-left position-relative">
              <img
                src={ab2}
                alt="Imagen no encontrada"
                className="img-fluid"
              />
              <div className="exp-box p-3 bg-white rounded-circle position-absolute">
                <div className="bg-secondary w-100 h-100 rounded-circle d-flex align-items-center justify-content-center flex-column">
                  <h2 className="text-white">14+</h2>
                  <span className="h6 text-white">Años de Experiencia</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7">
            <div className="about-us-right">
              <div className="section-title-mx mb-6">
                <div className="d-flex align-items-center gap-2 flex-wrap mb-2">
                  <h6 className="mb-0 gshop-subtitle text-secondary">Por qué elegirnos</h6>
                  <span>
                    <svg
                      width="58"
                      height="10"
                      viewBox="0 0 58 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0"
                        y1="5.2"
                        x2="52"
                        y2="5.2"
                        stroke="#FF7C08"
                        strokeWidth="1.6"
                      />
                      <path
                        d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z"
                        fill="#FF7C08"
                      />
                    </svg>
                  </span>
                </div>
                <h2 className="mb-3">No compramos en el mercado abierto</h2>
                <p className="mb-0">
                  Aprovechamos oportunidades intermandadas y negocios electrónicos
                  multimedia.
                </p>
              </div>
              <div className="row g-3">
                {[
                  {
                    title: "Socio de confianza",
                    description:
                      "Aprovechamos oportunidades intermandadas en e-business.",
                  },
                  {
                    title: "Política de devoluciones",
                    description:
                      "Aprovechamos oportunidades intermandadas en e-business.",
                  },
                  {
                    title: "100% orgánico y fresco",
                    description:
                      "Aprovechamos oportunidades intermandadas en e-business.",
                  },
                  {
                    title: "Pago seguro",
                    description:
                      "Aprovechamos oportunidades intermandadas en e-business.",
                  },
                ].map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="horizontal-icon-box d-flex align-items-center gap-4 bg-white rounded p-4 hover-shadow flex-wrap flex-xxl-nowrap">
                      <span className="icon-wrapper position-relative flex-shrink-0">
                        <img
                          src={handIcon}
                          alt="Icono"
                          className="img-fluid"
                        />
                      </span>
                      <div className="content-right">
                        <h5 className="mb-3">{item.title}</h5>
                        <p className="mb-0">{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
    </div>
  )
}

export {About}