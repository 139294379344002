import { Blog } from "../Home/blog/Blog";
import { Footer } from "../Home/footer/Footer";
import { Header } from "../Home/header/Header";
import { Slider } from "../HomeNatural/slider/Slider";
import { AboutNatural } from "./about/AboutNatural";
import { BannerNatural } from "./banner/BannerNatural";
import { CallNatural } from "./callNatural/CallNatural";
import { CategoriesNatural } from "./categories/CategoriesNatural";
import { FilterNatural } from "./filterNatural/FilterNatural";
import { Offer } from "./offer/Offer";
import { ProductNatural } from "./product/ProductNatural";
import { TeamNatural } from "./teamNatural/TeamNatural";




function HomeNatural() {
  return (
    <>
        <Header/>
        <Slider />
        <BannerNatural />
        <AboutNatural />
        <CategoriesNatural />
        <ProductNatural />
        <Offer />
        <FilterNatural />
        <TeamNatural />
        <CallNatural />
        <Blog />
        <Footer />
    </>
  )
}

export {HomeNatural}