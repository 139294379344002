

function UpdateProfile() {
    return (
        <div className="tab-pane fade active show" id="update-profile" role="tabpanel">
            <div className="update-profile bg-white py-5 px-4">
                <h6 className="mb-4">Actualizar Perfil</h6>
                <form className="profile-form">
                    <div className="file-upload text-center rounded-3 mb-5">
                        <input type="file" name="dp" />
                        <img src="img/icons/image.svg" alt="dp" className="img-fluid" />
                        <p className="text-dark fw-bold mb-2 mt-3">
                            Sube tus archivos aquí o <a href="#" className="enlace colorIcon">examinar</a>
                        </p>
                        <p className="mb-0 file-name">
                            (Solo se aceptarán imágenes *.jpeg y *.png)
                        </p>
                    </div>
                    <div className="row g-4">
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Nombre</label>
                                <input type="text" placeholder="Gene J." />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Apellido</label>
                                <input type="text" placeholder="Larose" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Teléfono/Móvil</label>
                                <input type="tel" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Correo Electrónico</label>
                                <input type="email" placeholder="themetags@gmail.com" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Fecha de Nacimiento</label>
                                <input type="date" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Nombre de Usuario</label>
                                <input type="text" placeholder="Nombre de usuario" />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn  mt-6" style={{backgroundColor: "#6eb356", color:"white", fontWeight: "500", padding: "13px"}}>
                        Actualizar Perfil
                    </button>
                </form>
            </div>
            <div className="change-password bg-white py-5 px-4 mt-4 rounded">
                <h6 className="mb-4">Cambiar Contraseña</h6>
                <form className="password-reset-form">
                    <div className="row g-4">
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Correo Electrónico</label>
                                <input type="email" placeholder="themetags@gmail.com" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Contraseña Actual</label>
                                <input type="password" placeholder="Contraseña actual" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Nueva Contraseña</label>
                                <input type="password" placeholder="Nueva contraseña" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                                <label>Repite la Contraseña</label>
                                <input type="password" placeholder="Confirmar contraseña" />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn  mt-6" style={{backgroundColor: "#6eb356", color:"white", fontWeight: "500", padding: "13px"}}>
                        Cambiar Contraseña
                    </button>
                </form>
            </div>
        </div>

    )
}

export { UpdateProfile }