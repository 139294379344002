import blogPost1 from "../../../../public/img/home-7/blog-post-1.png"; 
import blogPost2 from "../../../../public/img/home-7/blog-post-2.png"; 
import blogPost3 from "../../../../public/img/home-7/blog-post-3.png"; 

function BlogMueble() {
  return (
    <div className="section-space-sm-top section-space-bottom three-bg">
    <div className="section-space-sm-bottom">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-xl-6">
                    <h2 className="display-6 text-center">Explora Publicaciones Recientes</h2>
                    <p className="mb-0 text-center">
                        Interactivamente producto distintos paradigmas mientras capital intelectual uno a uno.
                        servicios de recursos chupadores.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="row g-4">
            <div className="col-md-6 col-lg-4">
                <article className="blog-card rounded-2 overflow-hidden bg-white">
                    <div className="thumbnail overflow-hidden">
                        <a href="#"><img src={blogPost1} alt="blog thumb" className="img-fluid w-100" /></a>
                    </div>
                    <div className="blog-card-content">
                        <div className="blog-meta d-flex flex-wrap align-items-center gap-3 mb-4">
                            <span className="fs-xs">
                                <i className="fa-solid fa-tags me-1"></i>
                                Verduras Orgánicas
                            </span>
                            <span className="fs-xs">
                                <i className="fa-regular fa-clock me-1"></i>
                                24 de mayo de 2022
                            </span>
                        </div>
                        <h4 className="mb-3">
                            <a href="#" className="clr-heading :clr-secondary">
                                Entrega de Vacaciones en Casa Hemos Pedido Recientemente
                            </a>
                        </h4>
                        <p className="mb-6">Explotar holísticamente estrategias de crecimiento de capital invertido mientras empresas</p>
                        <a href="#" className="link d-inline-flex align-items-center gap-1 py-2 px-3 text-center rounded-1 secondary-bg bg-opacity-1 :bg-opacity-100 clr-secondary :clr-light fw-semibold" style={{background:"#FFF2E6", color:"#ff7c0a"}}>
                            <span className="d-inline-block"> Explorar Más </span>
                            <span className="d-inline-block fs-14">
                                <i className="fas fa-arrow-right"></i>
                            </span>
                        </a>
                    </div>
                </article>
            </div>
            <div className="col-md-6 col-lg-4">
                <article className="blog-card rounded-2 overflow-hidden bg-white">
                    <div className="thumbnail overflow-hidden">
                        <a href="#"><img src={blogPost2} alt="blog thumb" className="img-fluid w-100" /></a>
                    </div>
                    <div className="blog-card-content">
                        <div className="blog-meta d-flex flex-wrap align-items-center gap-3 mb-4">
                            <span className="fs-xs">
                                <i className="fa-solid fa-tags me-1"></i>
                                Verduras Orgánicas
                            </span>
                            <span className="fs-xs">
                                <i className="fa-regular fa-clock me-1"></i>
                                24 de mayo de 2022
                            </span>
                        </div>
                        <h4 className="mb-3">
                            <a href="#" className="clr-heading :clr-secondary">
                                Entrega de Vacaciones en Casa Hemos Pedido Recientemente
                            </a>
                        </h4>
                        <p className="mb-6">Explotar holísticamente estrategias de crecimiento de capital invertido mientras empresas</p>
                        <a href="#" className="link d-inline-flex align-items-center gap-1 py-2 px-3 text-center rounded-1 secondary-bg bg-opacity-1 :bg-opacity-100 clr-secondary :clr-light fw-semibold" style={{background:"#FFF2E6", color:"#ff7c0a"}}>
                            <span className="d-inline-block"> Explorar Más </span>
                            <span className="d-inline-block fs-14">
                                <i className="fas fa-arrow-right"></i>
                            </span>
                        </a>
                    </div>
                </article>
            </div>
            <div className="col-md-6 col-lg-4">
                <article className="blog-card rounded-2 overflow-hidden bg-white">
                    <div className="thumbnail overflow-hidden">
                        <a href="#"><img src={blogPost3} alt="blog thumb" className="img-fluid w-100" /></a>
                    </div>
                    <div className="blog-card-content">
                        <div className="blog-meta d-flex flex-wrap align-items-center gap-3 mb-4">
                            <span className="fs-xs">
                                <i className="fa-solid fa-tags me-1"></i>
                                Verduras Orgánicas
                            </span>
                            <span className="fs-xs">
                                <i className="fa-regular fa-clock me-1"></i>
                                24 de mayo de 2022
                            </span>
                        </div>
                        <h4 className="mb-3">
                            <a href="#" className="clr-heading :clr-secondary">
                                Entrega de Vacaciones en Casa Hemos Pedido Recientemente
                            </a>
                        </h4>
                        <p className="mb-6">Explotar holísticamente estrategias de crecimiento de capital invertido mientras empresas</p>
                        <a href="#" className="link d-inline-flex align-items-center gap-1 py-2 px-3 text-center rounded-1 secondary-bg bg-opacity-1 :bg-opacity-100 clr-secondary:clr-light fw-semibold" style={{background:"#FFF2E6", color:"#ff7c0a"}}>
                            <span className="d-inline-block" > Explorar Más </span>
                            <span className="d-inline-block fs-14">
                                <i className="fas fa-arrow-right"></i>
                            </span>
                        </a>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>
  )
}

export {BlogMueble}
