
import { Banner } from '../components/Home/banner/Banner'
import { BannerPromo } from '../components/Home/bannerPromo/BannerPromo'
import { BestDeals } from '../components/Home/bestDeals/BestDeals'
import { CategoryStart } from '../components/Home/category/CategoryStart'
import { Featured } from '../components/Home/featured/Featured'
import { Header } from '../components/Home/header/Header'
import { Slider } from '../components/Home/slider/Slider'
import { Trending } from '../components/Home/trending/Trending'
import { Feedblack } from '../components/Home/feedblack/Feedblack'
import { Products } from '../components/Admin/products/Products'
import { Blog } from '../components/Home/blog/Blog'
import { Footer } from '../components/Home/footer/Footer'

function Home() {
  return (
    <>
        <Header />
        <Slider />
        <CategoryStart />
        <Featured />
        <Trending />
        <Banner />
        <BestDeals />
        <BannerPromo />
        <Feedblack />
        <Products />
        <Blog />
        <Footer />
    </>
  )
}

export  {Home}