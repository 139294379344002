import ctaBanner from "../../../../public/img/banner/cta-banner-2.jpg";

function Offer() {
  return (
    <section className="offer-section bg-none-small ptb-120" data-background="assets/img/banner/cta-banner-2.jpg" style={{ backgroundImage:  `url(${ctaBanner})` }}>
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-8">
            <div className="offer-content">
              <div className="d-flex align-items-center gap-2 mb-2">
                <h6 className="fw-normal text-secondary gshop-subtitle">Disfrute de hasta un 20%</h6>
                <span>
                  <svg width="58" height="10" viewBox="0 0 58 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0" y1="5.2" x2="52" y2="5.2" stroke="#FF7C08" strokeWidth="1.6"></line>
                    <path d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z" fill="#FF7C08"></path>
                  </svg>
                </span>
              </div>
              <h2 className="mb-3">Special <span className="text-secondary">Descuentos para todos</span> <br />Productos de alimentación</h2>
              <p className="mb-5">Oportunidades intermandatarias de moda y negocio electrónico transparente basado en multimedia.</p>
              <ul className="offer-countdown countdown-timer d-flex align-items-center flex-wrap gap-3 gap-sm-4" data-date="06/30/2023 23:59:59">
                <li className="bg-white rounded-3 text-center">
                  <h3 className="mb-0 days">45</h3>
                  <p className="mb-0 gshop-subtitle">Hora</p>
                </li>
                <li className="bg-white rounded-3 text-center">
                  <h3 className="mb-0 hours">45</h3>
                  <p className="mb-0 gshop-subtitle">Días</p>
                </li>
                <li className="bg-white rounded-3 text-center">
                  <h3 className="mb-0 minutes">45</h3>
                  <p className="mb-0 gshop-subtitle">Mes</p>
                </li>
                <li className="bg-white rounded-3 text-center">
                  <h3 className="mb-0 seconds">45</h3>
                  <p className="mb-0 gshop-subtitle">Año</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {Offer}
