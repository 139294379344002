import { Header } from "../../Home/header/Header"
import { BreadCrumb } from "../breadCrumb/BreadCrumb"
import pLg1 from "../../../../public/img/products/p-lg-1.png";
import pLg2 from "../../../../public/img/products/p-lg-2.png";
import pLg3 from "../../../../public/img/products/p-lg-3.png";
import pLg4 from "../../../../public/img/products/p-lg-4.png";
import thumbSm1 from "../../../../public/img/products/thumb-sm-1.png";
import thumbSm2 from "../../../../public/img/products/thumb-sm-2.png";
import thumbSm3 from "../../../../public/img/products/thumb-sm-3.png";
import thumbSm4 from "../../../../public/img/products/thumb-sm-4.png";
import user1 from "../../../../public/img/authors/user-1.jpg";
import user2 from "../../../../public/img/authors/user-2.jpg";
import banner4 from "../../../../public/img/banner/banner-4.jpg";
import pSm1 from "../../../../public/img/products/p-sm-1.png";
import pSm2 from "../../../../public/img/products/p-sm-2.png";
import pSm3 from "../../../../public/img/products/p-sm-3.png";
import pSm4 from "../../../../public/img/products/p-sm-4.png";
import apple from "../../../../public/img/products/apple.png";
import orangeSlice from "../../../../public/img/products/orange-slice.png";
import lemon from "../../../../public/img/products/lemon.png";
import banana from "../../../../public/img/products/banana.png";
import { Footer } from "../../Home/footer/Footer";


function ProductDetails() {
  return (
    <>
        <Header />
        <BreadCrumb title="Detalles del producto" pageName="Página" currentPage="Detalles del producto" />
        <section className="product-details-area ptb-120">
            <div className="container">
                <div className="row g-4">
                    <div className="col-xl-9">
                        <div className="product-details">
                            <div className="gstore-product-quick-view bg-white rounded-3 py-6 px-4">
                                <div className="row align-items-center g-4">
                                    <div className="col-xl-6 align-self-end">
                                        <div className="quickview-double-slider">
                                            <div className="quickview-product-slider swiper swiper-initialized swiper-horizontal swiper-pointer-events">
                                                <div className="swiper-wrapper" id="swiper-wrapper-207d310bac76a5e7d" aria-live="polite" style={{transform: "translate3d(-2320px, 0px, 0px)", transitionDuration: "0ms"}}><div className="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "464px"}}>
                                                        <img src={pLg1} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "464px"}}>
                                                        <img src={pLg2} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "464px"}}>
                                                        <img src={pLg3} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "464px"}}>
                                                        <img src={pLg4} alt="jam" className="img-fluid"/>
                                                    </div>
                                                    <div className="swiper-slide text-center swiper-slide-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "464px"}}>
                                                        <img src={pLg1} alt="jam" className="img-fluid"/>
                                                    </div>
                                                    <div className="swiper-slide text-center swiper-slide-active" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "464px"}}>
                                                        <img src={pLg2} alt="jam" className="img-fluid"/>
                                                    </div>
                                                    <div className="swiper-slide text-center swiper-slide-next" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "464px"}}>
                                                        <img src={pLg3} alt="jam" className="img-fluid"/>
                                                    </div>
                                                    <div className="swiper-slide text-center" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "464px"}}>
                                                        <img src={pLg4} alt="jam" className="img-fluid"/>
                                                    </div>
                                                <div className="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "464px"}}>
                                                        <img src={pLg1} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "464px"}}>
                                                        <img src={pLg2} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide text-center swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "464px"}}>
                                                        <img src={pLg3} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide text-center swiper-slide-duplicate" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "464px"}}>
                                                        <img src={pLg4} alt="jam" className="img-fluid"/>
                                                    </div></div>
                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                                            <div className="product-thumbnail-slider swiper mt-80 swiper-initialized swiper-horizontal swiper-pointer-events">
                                                <div className="swiper-wrapper" id="swiper-wrapper-be69c9db8414a07a" aria-live="polite" style={{transitionDuration: "0ms", transform: "translate3d(-423.5px, 0px, 0px)"}}><div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm1} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm2} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm3} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-duplicate" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm4} alt="jam" className="img-fluid"/>
                                                    </div>
                                                    <div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm1} alt="jam" className="img-fluid"/>
                                                    </div>
                                                    <div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-active" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm2} alt="jam" className="img-fluid"/>
                                                    </div>
                                                    <div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-next" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm3} alt="jam" className="img-fluid"/>
                                                    </div>
                                                    <div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm4} alt="jam" className="img-fluid"/>
                                                    </div>
                                                <div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm1} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm2} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm3} alt="jam" className="img-fluid"/>
                                                    </div><div className="swiper-slide product-thumb-single rounded-2 d-flex align-items-center justify-content-center swiper-slide-duplicate" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "101px", marginRight: "20px"}}>
                                                        <img src={thumbSm4} alt="jam" className="img-fluid"/>
                                                    </div></div>
                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="product-info">
                                            <h4 className="mt-1 mb-3">Tres verduras de zanahoria <br/> Cocina peruana</h4>
                                            <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                                <span className="fw-bold fs-xs deleted ms-1">$240.00</span>
                                            </div>
                                            <div className="widget-title d-flex mt-4">
                                                <h6 className="mb-1 flex-shrink-0">Descripción</h6>
                                                <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                                            </div>
                                            <p className="mb-3">Clicks y mortero «fuera del pensamiento». Difundir interactivamente relaciones intelectuales innovadoras. </p>
                                            <ul className="d-flex flex-column gap-2">
                                                <li><span className="me-2 text-primary"><i className="fa-solid fa-circle-check"></i></span>Ingredientes naturales</li>
                                                <li><span className="me-2 text-primary"><i className="fa-solid fa-circle-check"></i></span>Sabe mejor con leche</li>
                                                <li><span className="me-2 text-primary"><i className="fa-solid fa-circle-check"></i></span>Vitaminas B2, B3, B5 y B6</li>
                                                <li><span className="me-2 text-primary"><i className="fa-solid fa-circle-check"></i></span>Refrigerar para conservar la frescura</li>
                                            </ul>
                                            <h6 className="fs-md mb-2 mt-3">Weight:</h6>
                                            <ul className="product-radio-btn mb-4 d-flex align-items-center gap-2">
                                                <li>
                                                    <input type="radio" name="weight" value="250g" checked={true }/>
                                                    <label>150g</label>
                                                </li>
                                                <li>
                                                    <input type="radio" name="weight" value="250g"/>
                                                    <label>500g</label>
                                                </li>
                                                <li>
                                                    <input type="radio" name="weight" value="250g"/>
                                                    <label>1kg</label>
                                                </li>
                                            </ul>
                                            <div className="d-flex align-items-center gap-4 flex-wrap">
                                                <div className="product-qty d-flex align-items-center">
                                                    <button className="decrese">-</button>
                                                    <input type="text" value="01"/>
                                                    <button className="increase">+</button>
                                                </div>
                                                <a href="#" className="btn btn-secondary btn-md"><span className="me-2"><i className="fa-solid fa-bag-shopping"></i></span>Añadir a la cesta</a>
                                            </div>
                                            <div className="tt-category-tag mt-4">
                                                <a href="#" className="text-muted fs-xxs">Verduras</a>
                                                <a href="#" className="text-muted fs-xxs">Saludable</a>
                                                <a href="#" className="text-muted fs-xxs">Ecológico</a>
                                                <a href="#" className="text-muted fs-xxs">Verduras</a>
                                                <a href="#" className="text-muted fs-xxs">Saludable</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product-info-tab bg-white rounded-2 overflow-hidden pt-6 mt-4">
                                <ul className="nav nav-tabs border-bottom justify-content-center gap-5 pt-info-tab-nav" role="tablist">
                                    <li><a href="#Descripción" className="active" data-bs-toggle="tab" aria-selected="true" role="tab">Descripción</a></li>
                                    <li><a href="#info" data-bs-toggle="tab" aria-selected="false" role="tab">Información adicional</a></li>
                                    <li><a href="#review" data-bs-toggle="tab" aria-selected="false" role="tab">Reseñas(02)</a></li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active px-4 py-5" id="Descripción" role="tabpanel">
                                        <h6 className="mb-2">Detalles:</h6>
                                        <p className="mb-4">Lo mejor de Italia, reimaginado. En una Osteria rústica, nos inspiramos para hacer esta receta cuando nos dimos cuenta de lo suave que se vuelve un diente de ajo cuando se cocina a fuego lento durante todo el día. Mezclamos los dientes de ajo y la albahaca dulce con tomates ciruela de la región italiana de San Marzano y el mejor aceite de oliva virgen extra para obtener un sabor único a albahaca fresca y brillante.</p>
                                        <ul className="list-style-type-disc mb-4">
                                            <li>Ingredientes naturales</li>
                                            <li>Sabe mejor con leche</li>
                                            <li>Vitaminas B2, B3, B5 y B6</li>
                                            <li>Refrigerar para conservar la frescura</li>
                                        </ul>
                                        <h6 className="mb-2">ingredients:</h6>
                                        <p className="mb-0">Tomates ciruela italianos de la región de San Marzano, aceite de oliva virgen extra, ajo fresco, cebolla fresca, sal marina, albahaca fresca, especias, ácido cítrico.</p>
                                    </div>
                                    <div className="tab-pane fade px-4 py-5" id="info" role="tabpanel">
                                        <h6 className="mb-2">Información adicional:</h6>
                                        <table className="w-100 product-info-table">
                                            <tbody><tr>
                                                <td className="text-dark fw-semibold">Colores</td>
                                                <td>Negro, Verde, Naranja, Rojo</td>
                                            </tr>
                                            <tr>
                                                <td className="text-dark fw-semibold">Weight</td>
                                                <td>0.5Kg, 1Kg, 2Kg, 5Kg</td>
                                            </tr>
                                        </tbody></table>
                                    </div>
                                    <div className="tab-pane fade px-4 py-5" id="review" role="tabpanel">
                                        <div className="review-tab-box bg-white rounded pt-30 pb-40 px-4">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="top-left">
                                                    <h5 className="mb-2">Reseñas (1)</h5>
                                                    <p className="mb-0">Obtenga detalles específicos sobre este producto de clientes que lo poseen.</p>
                                                    <ul className="fs-xs text-warning d-flex mt-1">
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="far fa-star"></i></li>
                                                        <li className="ms-2 text-dark">(4 sobre 5)</li>
                                                    </ul>
                                                </div>
                                                <a href="#" className="btn btn-outline-secondary border-secondary btn-md mt-3 mt-lg-0">Escriba un comentario</a>
                                            </div>
                                            <hr className="mt-4 mb-4" />
                                            <div className="users_review">
                                                <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap">
                                                    <div className="top-left d-flex align-items-center">
                                                        <img src={user1} alt="user" className="flex-shrink-0 rounded"/>
                                                        <div className="ms-3">
                                                            <h6 className="mb-1">Helena Garcia</h6>
                                                            <span>2 Enero, 2018</span>
                                                        </div>
                                                    </div>
                                                    <ul className="fs-xs text-warning d-flex">
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                    </ul>
                                                </div>
                                                <p className="mt-3 mb-0">La información sobre las características técnicas, el conjunto de entrega, el país de fabricación y la apariencia de las mercancías es sólo para referencia y se basa en la información más reciente de la publicación.</p>
                                            </div>
                                            <div className="users_review mt-4">
                                                <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap">
                                                    <div className="top-left d-flex align-items-center">
                                                        <img src={user2} alt="user" className="flex-shrink-0 rounded"/>
                                                        <div className="ms-3">
                                                            <h6 className="mb-1">Helena Garcia</h6>
                                                            <span>2 Enero, 2018</span>
                                                        </div>
                                                    </div>
                                                    <ul className="fs-xs text-warning d-flex">
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="fas fa-star"></i></li>
                                                        <li><i className="far fa-star"></i></li>
                                                    </ul>
                                                </div>
                                                <p className="mt-3 mb-0">La información sobre las características técnicas, el conjunto de entrega, el país de fabricación y la apariencia de las mercancías es sólo para referencia y se basa en la información más reciente de la publicación.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-8">
                        <div className="gshop-sidebar">
                            <div className="sidebar-widget info-sidebar bg-white rounded-3 py-3">
                                <div className="sidebar-info-list d-flex align-items-center gap-3 p-4">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle text-primary">
                                    <i className="fa-solid fa-truck-fast"></i>
                                </span>
                                    <div className="info-right">
                                        <h6 className="mb-1 fs-md">Envío gratuito</h6>
                                        <span className="fw-medium fs-xs">Para pedidos a partir de $50</span>
                                    </div>
                                </div>
                                <div className="sidebar-info-list d-flex align-items-center gap-3 p-4 border-top">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle text-primary">
                                    <i className="fa-solid fa-circle-dollar-to-slot"></i>
                                </span>
                                    <div className="info-right">
                                        <h6 className="mb-1 fs-md">Devolución del 100% del dinero</h6>
                                        <span className="fw-medium fs-xs">Garantía del producto</span>
                                    </div>
                                </div>
                                <div className="sidebar-info-list d-flex align-items-center gap-3 p-4 border-top">
                                    <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle text-primary">
                                    <i className="fa-regular fa-heart"></i>
                                </span>
                                    <div className="info-right">
                                        <h6 className="mb-1 fs-md">Seguridad &amp; Asegure</h6>
                                        <span className="fw-medium fs-xs">Llámenos cuando quiera &amp; En cualquier lugar</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget banner-widget mt-4 p-0 border-0">
                                <div className="vertical-banner text-center bg-white rounded-2" data-background="assets/img/banner/banner-4.jpg" style={{backgroundImage: `url(${banner4})`}}>
                                    <h5 className="mb-1">Fresco &amp; Especias ecológicas</h5>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        <span className="hot-badge bg-danger fw-bold fs-xs position-relative text-white">CALIENTE</span>
                                        <span className="offer-title text-danger fw-bold">30% Off</span>
                                    </div>
                                    <a href="#" className="explore-btn text-primary fw-bold">Shop Now<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                                </div>
                            </div>
                            <div className="sidebar-widget products-widget py-5 px-4 bg-white mt-4">
                                <div className="widget-title d-flex">
                                    <h6 className="mb-0 flex-shrink-0">Productos destacados</h6>
                                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                                </div>
                                <div className="sidebar-products-list">
                                    <div className="horizontal-product-card card-md d-sm-flex align-items-center bg-white rounded-2 gap-3 mt-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <a href="#"><img src={pSm1} alt="product" className="img-fluid"/></a>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                                                <a href="product-details.html" className="rounded-btn"><i className="fa-solid fa-eye"></i></a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-3 mt-sm-0">
                                            <a href="#" className="d-block fs-sm fw-bold text-heading title d-block">Zumo de fresa Fruta</a>
                                            <div className="pricing mt-0">
                                                <span className="fw-bold fs-xxs text-danger">$140.00</span>
                                            </div>
                                            <div className="d-flex align-items-center flex-nowrap star-rating mt-1">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card card-md d-sm-flex align-items-center bg-white rounded-2 gap-3 mt-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <a href="#"><img src={pSm2} alt="product" className="img-fluid"/></a>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                                                <a href="product-details.html" className="rounded-btn"><i className="fa-solid fa-eye"></i></a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-3 mt-sm-0">
                                            <a href="#" className="d-block fs-sm fw-bold text-heading title d-block">Zumo de fresa Fruta</a>
                                            <div className="pricing mt-0">
                                                <span className="fw-bold fs-xxs text-danger">$140.00</span>
                                            </div>
                                            <div className="d-flex align-items-center flex-nowrap star-rating mt-1">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card card-md d-sm-flex align-items-center bg-white rounded-2 gap-3 mt-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <a href="#"><img src={pSm3} alt="product" className="img-fluid"/></a>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                                                <a href="product-details.html" className="rounded-btn"><i className="fa-solid fa-eye"></i></a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-3 mt-sm-0">
                                            <a href="#" className="d-block fs-sm fw-bold text-heading title d-block">Zumo de fresa Fruta</a>
                                            <div className="pricing mt-0">
                                                <span className="fw-bold fs-xxs text-danger">$140.00</span>
                                            </div>
                                            <div className="d-flex align-items-center flex-nowrap star-rating mt-1">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card card-md d-sm-flex align-items-center bg-white rounded-2 gap-3 mt-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <a href="#"><img src={pSm4} alt="product" className="img-fluid"/></a>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                                                <a href="product-details.html" className="rounded-btn"><i className="fa-solid fa-eye"></i></a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-3 mt-sm-0">
                                            <a href="#" className="d-block fs-sm fw-bold text-heading title d-block">Zumo de fresa Fruta</a>
                                            <div className="pricing mt-0">
                                                <span className="fw-bold fs-xxs text-danger">$140.00</span>
                                            </div>
                                            <div className="d-flex align-items-center flex-nowrap star-rating mt-1">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vertical-product-card rounded-bottom-2 position-relative border-0 border-top bg-white shadow-none">
                                <div className="thumbnail position-relative text-center p-4">
                                    <img src={apple} alt="apple" className="img-fluid"/>
                                    <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                        <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                        <a href="#" className="rounded-btn">
                                            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                            </svg>
                                        </a>
                                        <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresh Organic</a>
                                    <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                    <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                        <ul className="d-flex align-items-center me-2">
                                            <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                            <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        </ul>
                                        <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                    </div>
                                    <h6 className="price text-danger mb-4">$140.00</h6>
                                    <a href="#" className="btn btn-outline-secondary d-block btn-md border-secondary">Añadir a la cesta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="related-product-slider pb-120">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-sm-8">
                        <div className="section-title text-center text-sm-start">
                            <h2 className="mb-0">Le puede interesar</h2>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="rl-slider-btns text-center text-sm-end mt-3 mt-sm-0">
                            <button className="rl-slider-btn slider-btn-prev"  aria-label="Previous slide" aria-controls="swiper-wrapper-d4a97177facc6977"><i className="fas fa-arrow-left"></i></button>
                            <button className="rl-slider-btn slider-btn-next ms-3" aria-label="Next slide" aria-controls="swiper-wrapper-d4a97177facc6977"><i className="fas fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
                <div className="rl-products-slider swiper mt-8 swiper-initialized swiper-horizontal swiper-pointer-events">
                    <div className="swiper-wrapper" id="swiper-wrapper-d4a97177facc6977" aria-live="off" style={{transform: "translate3d(-2688px, 0px, 0px)", transitionDuration: "0ms"}}><div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={apple} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div><div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={banana} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div><div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={lemon} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div><div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={orangeSlice} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div>
                        <div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate-active" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={apple} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div>
                        <div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={banana} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div>
                        <div className="vertical-product-card rounded-2 position-relative swiper-slide" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={lemon} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div>
                        <div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-prev" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={orangeSlice} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div>
                    <div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={apple} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div><div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="1" role="group" aria-label="2 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={banana} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div><div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" role="group" aria-label="3 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={lemon} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div><div className="vertical-product-card rounded-2 position-relative swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="3" role="group" aria-label="4 / 4" style={{width: "312px", marginRight: "24px"}}>
                            <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute start-0 top-0">-12% OFF</span>
                            <div className="thumbnail position-relative text-center p-4">
                                <img src={orangeSlice} alt="apple" className="img-fluid"/>
                                <div className="product-btns position-absolute d-flex gap-2 flex-column">
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                    <a href="#" className="rounded-btn">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.705 0.201222C10.4317 0.469526 10.4317 0.904522 10.705 1.17283L11.6101 2.06107H7.70001C6.15364 2.06107 4.90001 3.29144 4.90001 4.80917V5.49619C4.90001 5.87564 5.21341 6.18322 5.60001 6.18322C5.98662 6.18322 6.30001 5.87564 6.30001 5.49619V4.80917C6.30001 4.0503 6.92679 3.43512 7.70001 3.43512H11.6101L10.705 4.32337C10.4317 4.59166 10.4317 5.02668 10.705 5.29496C10.9784 5.56325 11.4216 5.56325 11.695 5.29496L13.795 3.2339C14.0683 2.96559 14.0683 2.5306 13.795 2.26229L11.695 0.201222C11.4216 -0.0670741 10.9784 -0.0670741 10.705 0.201222ZM8.40001 4.80917C8.0134 4.80917 7.70001 5.11675 7.70001 5.49619V6.18322C7.70001 6.9421 7.07323 7.55726 6.30001 7.55726H2.38995L3.29498 6.66901C3.56835 6.40073 3.56835 5.9657 3.29498 5.69742C3.02161 5.42914 2.5784 5.42914 2.30503 5.69742L0.205023 7.75849C-0.0683411 8.02678 -0.0683411 8.4618 0.205023 8.73008L2.30503 10.7912C2.5784 11.0594 3.02161 11.0594 3.29498 10.7912C3.56835 10.5229 3.56835 10.0878 3.29498 9.81957L2.38995 8.93131H6.30001C7.84638 8.93131 9.10001 7.70092 9.10001 6.18322V5.49619C9.10001 5.11675 8.78662 4.80917 8.40001 4.80917Z" fill="#AEB1B9"></path>
                                        </svg>
                                    </a>
                                    <a href="#" className="rounded-btn"><i className="fa-regular fa-eye"></i></a>
                                </div>
                            </div>
                            <div className="card-content">
                                <a href="#" className="mb-2 d-inline-block text-secondary fw-semibold fs-xxs">Fresco Ecológico</a>
                                <a href="#" className="card-title fw-bold d-block mb-2">Aperitivos de arroz crujiente con chocolate.</a>
                                <div className="d-flex align-items-center flex-nowrap star-rating fs-xxs mb-2">
                                    <ul className="d-flex align-items-center me-2">
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                        <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                </div>
                                <h6 className="price text-danger mb-3">$140.00</h6>
                                <div className="card-progressbar mb-2 rounded-pill">
                                    <span className="card-progress bg-primary" data-progress="60%" style={{width: "60%"}}></span>
                                </div>
                                <p className="mb-0 fw-semibold">Disponible: <span className="fw-bold text-secondary">40/100</span></p>
                                <a href="#" className="btn btn-outline-secondary btn-md border-secondary d-block mt-4">Añadir a la cesta</a>
                            </div>
                        </div></div>
                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
        </section>
        <Footer />
    </>
  )
}

export {ProductDetails}
