import "../.././style/global.css";

function OrderHistory() {
    return (
        <div className="tab-pane fade active show" id="order-history" role="tabpanel">
            <div className="recent-orders bg-white rounded py-5">
                <h6 className="mb-4 px-4">Pedidos Recientes</h6>
                <div className="table-responsive">
                    <table className="order-history-table table">
                        <tbody>
                            <tr>
                                <th>Número de Orden</th>
                                <th>Realizado el</th>
                                <th>Método</th>
                                <th>Artículos</th>
                                <th>Total</th>
                                <th className="text-center">Acción</th>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>635981586200289</td>
                                <td>03/12/2022</td>
                                <td>COD</td>
                                <td className="thumbnail">
                                    <img src="img/products/cauliflower-xs.png" alt="producto" />
                                </td>
                                <td className="text-secondary">$105.60</td>
                                <td className="text-center">
                                    <a href="#" className="view-invoice fs-xs">
                                    <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                    <a href="#" className="delete-item fs-xs ml-3">
                                    <i className="fas fa-trash colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export { OrderHistory }