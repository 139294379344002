

function PaymentMethods() {
    return (
        <div className="tab-pane fade active show" id="payments" role="tabpanel">
            <div className="payment-methods bg-white rounded py-5 px-4">
                <h6 className="mb-4">Métodos de Pago Predeterminados</h6>
                <div className="table-responsive">
                    <table className="payments-list-table table">
                        <tbody>
                            <tr>
                                <th>Información de Tarjetas de Crédito / Débito</th>
                                <th>Nombre</th>
                                <th>Vence en</th>
                                <th className="text-center">Acción</th>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center gap-3">
                                    <span className="icon d-inline-flex align-items-center justify-content-center rounded-circle">
                                        <img src="img/brands/paypal-icon.png" alt="icon" />
                                    </span>
                                    <p className="d-inline-block mb-0">
                                        <strong>PayPal</strong>
                                        youremail@domain.com
                                        <span className="badge bg-secondary-light  fw-normal ms-1 orange-text" >
                                            Activo
                                        </span>
                                    </p>
                                </td>
                                <td>Talukdar</td>
                                <td>25/2024</td>
                                <td className="text-center">
                                    <a href="#" className="view-more">
                                        <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center gap-3">
                                    <span className="icon d-inline-flex align-items-center justify-content-center rounded-circle">
                                        <img src="img/brands/paypal-icon.png" alt="icon" />
                                    </span>
                                    <p className="d-inline-block mb-0">
                                        <strong>PayPal</strong>
                                        youremail@domain.com
                                    </p>
                                </td>
                                <td>Talukdar</td>
                                <td>25/2024</td>
                                <td className="text-center">
                                    <a href="#" className="view-more">
                                        <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center gap-3">
                                    <span className="icon d-inline-flex align-items-center justify-content-center rounded-circle">
                                        <img src="img/brands/paypal-icon.png" alt="icon" />
                                    </span>
                                    <p className="d-inline-block mb-0">
                                        <strong>PayPal</strong>
                                        youremail@domain.com
                                    </p>
                                </td>
                                <td>Talukdar</td>
                                <td>25/2024</td>
                                <td className="text-center">
                                    <a href="#" className="view-more">
                                        <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center gap-3">
                                    <span className="icon d-inline-flex align-items-center justify-content-center rounded-circle">
                                        <img src="img/brands/paypal-icon.png" alt="icon" />
                                    </span>
                                    <p className="d-inline-block mb-0">
                                        <strong>PayPal</strong>
                                        youremail@domain.com
                                    </p>
                                </td>
                                <td>Talukdar</td>
                                <td>25/2024</td>
                                <td className="text-center">
                                    <a href="#" className="view-more">
                                        <i className="fas fa-eye colorIcon"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <a href="#" className="btn  mt-4" style={{backgroundColor: "#6eb356", color:"white", fontWeight: "500", padding: "13px"}}>
                    <span className="me-2">
                    <i className="fas fa-plus"></i>
                    </span>
                    Agregar Método de Pago
                </a>
            </div>
        </div>
    )
}

export { PaymentMethods }