import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import heroEl1 from "../../../../public/img/home-6/hero-el-1.png";
import heroEl2 from "../../../../public/img/home-6/hero-el-2.png";
import heroEl3 from "../../../../public/img/home-6/hero-el-3.png";


function Slider() {
  return (
     <div className="hero-6" style={{position:"relative", top:"0", left:"0", right:"0",  }}>
      <div className="hero-6__content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-6__inner">
                <div className="row">
                  <div className="col-md-8 col-lg-6 col-xxl-5">
                    <span className="hero-6__subtitle d-inline-block mb-2" style={{color: "#00b1b8", fontWeight: "600", letterSpacing:".25rem"}}>
                      Productos 100% ecológicos
                    </span>
                    <h1 className="hero-6__title mb-4" style={{color:"#001242", fontWeight: "800"}}>Productos médicos para su familia</h1>
                    <p className="hero-6__para fs-18 mb-10" style={{color: "#212e50"}}>
                    Impulsando el conocimiento especializado del mercado con una visión integral del talento humano a nivel mundial.
                    </p>
                    <a href="#" className="link button button-effect button-effect--primary" style={{background: '#20275b', color:"white"}}>
                      <span className="d-inline-block fw-semibold"> Comprar Ahora </span>
                      <span className="d-inline-block">
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="hero-6__el-container">
                  <img
                    src={heroEl1}
                    alt="image"
                    className="img-fluid hero-6__el hero-6__el-1"
                    style={{ transform: 'translate3d(-58.6px, -4.72px, 0px)' }}
                  />
                  <img
                    src={heroEl2}
                    alt="image"
                    className="img-fluid hero-6__el hero-6__el-2"
                    style={{ transform: 'translate3d(-21.975px, -2.36px, 0px)' }}
                  />
                  <img
                    src={heroEl3}
                    alt="image"
                    className="img-fluid hero-6__el hero-6__el-3"
                    style={{ transform: 'translate3d(-11.72px, -0.944px, 0px)' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Slider}