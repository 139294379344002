import icon1 from "../../../../public/img/home-5/icon-1.png";
import icon2 from "../../../../public/img/home-5/icon-2.png";
import icon3 from "../../../../public/img/home-5/icon-3.png";
import icon4 from "../../../../public/img/home-5/icon-4.png";
import icon5 from "../../../../public/img/home-5/icon-5.png";

function CategoryCarniceria() {
  return (
    <section className="section-space-y ptb-120">
    <div className="container" >
        <div className="row justify-content-center">
            <div className="col-lg-6">
                <div className="section-heading text-center mb-5">
                    <h2 className="display-6 fw-bolder">
                        Proveemos Carne de la <br/>
                        Mejor <mark className="bg-transparent meat-primary">Categoría</mark>
                    </h2>
                </div>
            </div>
        </div>
        <div className="row" >
            <div className="col-12">
                <div className="meat-category-slider-container">
                    <div className="meat-category-slider" style={{display: "flex", overflow:"hidden"}}>
                        <div className="meat-category-card" style={{marginRight: "10px"}}>
                            <div className="meat-category-card__icon">
                                <div className="meat-category-card__icon-is">
                                    <img src={icon1} alt="imagen" className="img-fluid mx-auto"/>
                                </div>
                            </div>
                            <div className="meat-category-card__body">
                                <h5 className="meat-category-card__title">Pollo Fresco</h5>
                                <p className="meat-category-card__para">
                                    Procrastinar colaborativamente el capital intelectual centrado en el cliente mientras se piensa "fuera de la caja".
                                </p>
                                <a href="#" className="meat-category-card__btn animated-btn-icon">
                                    <span className="meat-category-card__btn-text"> Comprar Ahora </span>
                                    <span className="meat-category-card__btn-icon">
                                        <i className="fas fa-arrow-right-long"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="meat-category-card" style={{marginRight: "10px"}}>
                            <div className="meat-category-card__icon">
                                <div className="meat-category-card__icon-is">
                                    <img src={icon2} alt="imagen" className="img-fluid mx-auto"/>
                                </div>
                            </div>
                            <div className="meat-category-card__body">
                                <h5 className="meat-category-card__title">Pollo Fresco</h5>
                                <p className="meat-category-card__para">
                                    Procrastinar colaborativamente el capital intelectual centrado en el cliente mientras se piensa "fuera de la caja".
                                </p>
                                <a href="#" className="meat-category-card__btn animated-btn-icon">
                                    <span className="meat-category-card__btn-text"> Comprar Ahora </span>
                                    <span className="meat-category-card__btn-icon">
                                        <i className="fas fa-arrow-right-long"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="meat-category-card" style={{marginRight: "10px"}}>
                            <div className="meat-category-card__icon">
                                <div className="meat-category-card__icon-is">
                                    <img src={icon3} alt="imagen" className="img-fluid mx-auto"/>
                                </div>
                            </div>
                            <div className="meat-category-card__body">
                                <h5 className="meat-category-card__title">Pollo Fresco</h5>
                                <p className="meat-category-card__para">
                                    Procrastinar colaborativamente el capital intelectual centrado en el cliente mientras se piensa "fuera de la caja".
                                </p>
                                <a href="#" className="meat-category-card__btn animated-btn-icon">
                                    <span className="meat-category-card__btn-text"> Comprar Ahora </span>
                                    <span className="meat-category-card__btn-icon">
                                        <i className="fas fa-arrow-right-long"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="meat-category-card" style={{marginRight: "10px"}}>
                            <div className="meat-category-card__icon">
                                <div className="meat-category-card__icon-is">
                                    <img src={icon4} alt="imagen" className="img-fluid mx-auto"/>
                                </div>
                            </div>
                            <div className="meat-category-card__body">
                                <h5 className="meat-category-card__title">Pollo Fresco</h5>
                                <p className="meat-category-card__para">
                                    Procrastinar colaborativamente el capital intelectual centrado en el cliente mientras se piensa "fuera de la caja".
                                </p>
                                <a href="#" className="meat-category-card__btn animated-btn-icon">
                                    <span className="meat-category-card__btn-text"> Comprar Ahora </span>
                                    <span className="meat-category-card__btn-icon">
                                        <i className="fas fa-arrow-right-long"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="meat-category-card">
                            <div className="meat-category-card__icon">
                                <div className="meat-category-card__icon-is">
                                    <img src={icon5} alt="imagen" className="img-fluid mx-auto"/>
                                </div>
                            </div>
                            <div className="meat-category-card__body">
                                <h5 className="meat-category-card__title">Pollo Fresco</h5>
                                <p className="meat-category-card__para">
                                    Procrastinar colaborativamente el capital intelectual centrado en el cliente mientras se piensa "fuera de la caja".
                                </p>
                                <a href="#" className="meat-category-card__btn animated-btn-icon">
                                    <span className="meat-category-card__btn-text"> Comprar Ahora </span>
                                    <span className="meat-category-card__btn-icon">
                                        <i className="fas fa-arrow-right-long"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export {CategoryCarniceria}
