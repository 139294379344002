import aboutUsImage from '../../../../public/img/home-5/about-us.jpg';
import shippingIcon from '../../../../public/img/home-5/free-shipping-icon.png';
import refundedIcon from '../../../../public/img/home-5/refunded-icon.png';
import paymentSecureIcon from '../../../../public/img/home-5/payment-secure.png';
import safetyIcon from '../../../../public/img/home-5/safety.png';
import user1 from "../../../../public/img/user-1.png";

function AboutCarniceria() {
  return (
    <section className="meat-about-us">
    <div className="container">
        <div className="row justify-content-between align-items-center">
            <div className="col-lg-5">
                <div className="meat-about-info-left">
                    <img src={aboutUsImage} alt="about-us" className="img-fluid rounded-3" />
                    <div className="expert-box bg-meat-primary text-light rounded-circle shadow-lg">
                        <div className="expert-info">
                            <div className="fw-bolder mb-2 display-5 lh-0">25+</div>
                            <br />
                            <span>Experiencia de más de 25 años</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="meat-about-info-right">
                    <strong className="meat-primary mb-2 d-block">La calidad es nuestra fortaleza</strong>
                    <h2 className="fw-bolder display-6 mb-4">
                        Hacemos del mundo un lugar mejor <br />
                        <span className="meat-primary">en cada momento</span>
                    </h2>

                    <p className="mb-12">
                        Promovemos de manera proactiva tecnologías con altos estándares en productos manufacturados.
                        Mejoramos continuamente la conexión total confiable y las competencias fundamentales de construcción de equipos para la mejor calidad.
                    </p>

                    <div className="d-flex align-items-center flex-wrap gap-15 mt-6">
                        <a href="shop-grid.html" className="btn btn-dark animated-btn-icon">
                            Comprar ahora
                            <span className="ms-2">
                                <i className="fa-solid fa-arrow-right"></i>
                            </span>
                        </a>
                        <div className="d-flex align-items-center gap-4">
                            <div className="w-18 h-18 rounded-circle overflow-hidden flex-shrink-0">
                                <img src={user1} alt="imagen" className="img-fluid w-100 h-100 object-fit-cover" />
                            </div>
                            <div className="flex-grow-1">
                                <h6 className="mb-0 fs-18">Peter Parker</h6>
                                <p className="mb-0">Propietario</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-20">
            <div className="row bg-dark rounded-3 py-5 px-4">
                <div className="col-lg-3 col-md-6">
                    <div className="single-hilighted-feature d-flex align-items-center">
                        <div className="hilighted-icon me-3">
                            <img src={shippingIcon} alt="ícono de envío" className="shadow-lg" />
                        </div>
                        <div className="hilighted-info">
                            <h6 className="text-light mb-1">Envío gratuito</h6>
                            <span>En pedidos mayores a $100</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="single-hilighted-feature d-flex align-items-center">
                        <div className="hilighted-icon me-3">
                            <img src={refundedIcon} alt="ícono de garantía de devolución" className="shadow-lg" />
                        </div>
                        <div className="hilighted-info">
                            <h6 className="text-light mb-1">Satisfacción o reembolso</h6>
                            <span>Garantía de producto garantizada</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="single-hilighted-feature d-flex align-items-center">
                        <div className="hilighted-icon me-3">
                            <img src={paymentSecureIcon} alt="ícono de pago seguro" className="shadow-lg" />
                        </div>
                        <div className="hilighted-info">
                            <h6 className="text-light mb-1">Pago seguro</h6>
                            <span>Obtenga un pago 100% seguro</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="single-hilighted-feature d-flex align-items-center">
                        <div className="hilighted-icon me-3">
                            <img src={safetyIcon} alt="ícono de seguridad" className="shadow-lg" />
                        </div>
                        <div className="hilighted-info">
                            <h6 className="text-light mb-1">100% Seguro y protegido</h6>
                            <span>Llámenos en cualquier momento y lugar</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default AboutCarniceria
