import sbBanner from "../../../../public/img/banner/sb-banner.jpg";
import pSm1 from "../../../../public/img/products/p-sm-1.png";
import pSm2 from "../../../../public/img/products/p-sm-2.png";
import pSm3 from "../../../../public/img/products/p-sm-3.png";
import pSm4 from "../../../../public/img/products/p-sm-4.png";
import potato from "../../../../public/img/shapes/potato.png";

function FilterNatural() {
  return (
    <section className="filter-section ptb-120 position-relative overflow-hidden z-1">
            <img src={potato} alt="potato" className="potato position-absolute z--1" />
            <div className="container">
                <div className="row g-5 g-xl-4">
                    <div className="col-xl-4">
                        <div className="subscribe-box rounded pt-8 px-5 rounded" data-background="assets/img/banner/sb-banner.jpg" style={{ backgroundImage: `url(${sbBanner})` }}>
                            <h4 className="mb-2 text-white">Suscríbase a nuestro boletín  &amp; consiga 20 $ para su primer pedido</h4>
                            <p className="mb-4">Suscríbase para conocer las últimas<br/> promociónes.</p>
                            <form className="d-flex align-items-center banner-subscribe">
                                <input type="email" className="theme-input bg-transparent" placeholder="Enter email address"/>
                                <button type="submit" className="btn btn-secondary">Suscríbase</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="filter-right">
                            <div className="row g-4">
                                <div className="col-lg-4">
                                    <div className="section-title text-center text-lg-start">
                                        <h3 className="mb-0">Oferta especial</h3>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="filter-btns text-center text-lg-end filter-btns-underline">
                                        <button type="button" data-filter="*" className="filter-btn active">Novedades</button>
                                        <button type="button" data-filter=".trending" className="filter-btn">Productos de moda</button>
                                        <button type="button" data-filter=".best-selling" className="filter-btn">Los más vendidos</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mt-3 filter_group" style={{position: "relative", height: "321.438px"}}>
                                <div className="col-lg-6 grid_item" style={{position: "absolute", left: "0px", top: "0px"}}>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 shadow gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <a href="#"><img src={pSm1} alt="product" className="img-fluid" /></a>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                                                <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <a href="#" className="fw-bold text-heading title d-block">Cáscara de limón europeo</a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="product-details.html" className="fs-xs fw-bold mt-3 d-inline-block explore-btn">Comprar ahora<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 grid_item trending" style={{position: "absolute", left: "447.988px", top: "0px"}}>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 shadow gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <a href="#"><img src={pSm2} alt="product" className="img-fluid" /></a>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                                                <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <a href="#" className="fw-bold text-heading title d-block">Cáscara de limón europeo</a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-3 d-inline-block explore-btn">Comprar ahora<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 grid_item best-selling" style={{position: "absolute", left: "0px", top: "160.719px"}}>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 shadow gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <a href="#"><img src={pSm3} alt="product" className="img-fluid"/></a>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                                                <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block">Cáscara de limón europeo</a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="product-details.html" className="fs-xs fw-bold mt-3 d-inline-block explore-btn">Comprar ahora<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 grid_item trending best-selling" style={{position: "absolute", left: "447.988px", top: "160.719px"}}>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 shadow gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <a href="#"><img src={pSm4} alt="product" className="img-fluid"/></a>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-2 rounded-2">
                                                <a href="#" className="rounded-btn"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#quickview_modal" data-bs-toggle="modal" className="rounded-btn"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-warning"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block">Cáscara de limón europeo</a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-3 d-inline-block explore-btn">Comprar ahora<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )}

export {FilterNatural}