import pumkin from "../../../../public/img/shapes/pumkin.png";
import flower from "../../../../public/img/shapes/flower.png";
import team1 from "../../../../public/img/authors/team-1.jpg";
import team2 from "../../../../public/img/authors/team-2.jpg";

function TeamNatural() {
  return (
    <section className="team-section ptb-120 bg-floral-white position-relative overflow-hidden z-1">
      <img
        src={pumkin}
        alt="calabaza"
        className="position-absolute pumkin z--1"
      />
      <img
        src={flower}
        alt="flor"
        className="position-absolute flower z--1"
      />
      <div className="container">
        <div className="row align-items-center g-3">
          <div className="col-xl-3 col-lg-5">
            <div className="section-title">
              <div className="d-flex align-items-center gap-2 flex-wrap">
                <h6 className="mb-0 gshop-subtitle text-secondary">
                  Miembros del Equipo
                </h6>
                <span>
                  <svg
                    width="58"
                    height="10"
                    viewBox="0 0 58 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="-6.99382e-08"
                      y1="5.2"
                      x2="52"
                      y2="5.2"
                      stroke="#FF7C08"
                      strokeWidth="1.6"
                    ></line>
                    <path
                      d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z"
                      fill="#FF7C08"
                    ></path>
                  </svg>
                </span>
              </div>
              <h2 className="mb-3">
                Nuestro Miembro de Ayuda al Cliente en Línea
              </h2>
              <p className="mb-7">
                Encontrarse racionalmente con extremadamente doloroso
                cualquiera.
              </p>
              <div className="d-flex align-items-center gap-3">
                <button
                  type="button"
                  className="team-slider-prev-btn team-slider-btn"

                  aria-label="Diapositiva anterior"
                  aria-controls="swiper-wrapper-0737ddc786afd39d"
                  aria-disabled="false"
                >
                  <i className="fas fa-angle-left"></i>
                </button>
                <button
                  type="button"
                  className="team-slider-next-btn team-slider-btn swiper-button-disabled"

                  aria-label="Siguiente diapositiva"
                  aria-controls="swiper-wrapper-0737ddc786afd39d"
                  aria-disabled="true"

                >
                  <i className="fas fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="swiper team-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
              <div
                id="swiper-wrapper-0737ddc786afd39d"
                className="swiper-wrapper"
                aria-live="off"
                style={{
                  transform: "translate3d(-336.667px, 0px, 0px)",
                  transitionDuration: "0ms",
                }}
              >
                <div
                  className="team-card text-center bg-white rounded py-7 px-4 swiper-slide swiper-slide-prev"
                  role="group"
                  aria-label="1 / 4"
                  style={{ width: "312.667px", marginRight: "24px" }}
                >
                  <div className="team-thumb mb-5">
                    <img
                      src={team1}
                      alt="equipo"
                      className="img-fluid rounded-circle"
                    />
                    <div className="team-social">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </div>
                  </div>
                  <h5>Frances Gilmartin</h5>
                  <span>CEO y Fundador</span>
                </div>
                <div
                  className="team-card text-center bg-white rounded py-7 px-4 swiper-slide swiper-slide-active"
                  role="group"
                  aria-label="2 / 4"
                  style={{ width: "312.667px", marginRight: "24px" }}
                >
                  <div className="team-thumb mb-5">
                    <img
                      src={team2}
                      alt="equipo"
                      className="img-fluid rounded-circle"
                    />
                    <div className="team-social">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </div>
                  </div>
                  <h5>Frances Gilmartin</h5>
                  <span>CEO y Fundador</span>
                </div>
                <div
                  className="team-card text-center bg-white rounded py-7 px-4 swiper-slide swiper-slide-next"
                  role="group"
                  aria-label="3 / 4"
                  style={{ width: "312.667px", marginRight: "24px" }}
                >
                  <div className="team-thumb mb-5">
                    <img
                      src={team1}
                      alt="equipo"
                      className="img-fluid rounded-circle"
                    />
                    <div className="team-social">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </div>
                  </div>
                  <h5>Frances Gilmartin</h5>
                  <span>CEO y Fundador</span>
                </div>
                <div
                  className="team-card text-center bg-white rounded py-7 px-4 swiper-slide"
                  role="group"
                  aria-label="4 / 4"
                  style={{ width: "312.667px", marginRight: "24px" }}
                >
                  <div className="team-thumb mb-5">
                    <img
                      src={team2}
                      alt="equipo"
                      className="img-fluid rounded-circle"
                    />
                    <div className="team-social">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </div>
                  </div>
                  <h5>Frances Gilmartin</h5>
                  <span>CEO y Fundador</span>
                </div>
              </div>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export  {TeamNatural};
