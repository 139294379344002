import orange from "../../../../public/img/products/orange.png";
import orangeSlice from "../../../../public/img/products/orange-slice.png";
import thumbnail1 from "../../../../public/img/home-3/thumbnail-1.png";
import banner8 from "../../../../public/img/banner/banner-8.jpg";

function SliderOrganico() {
  return (
    <section className="healthcare-hero pt-7 pb-120 overflow-hidden">
      <div className="container">
        <div className="row g-5 g-xl-4 align-items-center justify-content-center">
          <div className="col-xl-8">
            <div className="healthcare-hero-slider-box overflow-hidden position-relative">
              {/* Slider de Swiper */}
              <div className="healthcare-hero-slider swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                <div className="swiper-wrapper">
                  {/* Slide 1 */}
                  <div className="healthcare-hero-single swiper-slide bg-white rounded">
                    <div className="left-content">
                      <div className="d-flex align-items-center gap-1 gap-sm-2 mb-2 flex-wrap">
                        <h5 className="mb-0 fw-normal text-primary gshop-subtitle">
                          Alimentos 100% Orgánicos
                        </h5>
                        <span>
                          <svg
                            width="138"
                            height="16"
                            viewBox="0 0 138 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line
                              y1="8"
                              x2="132"
                              y2="8"
                              stroke="#4EB529"
                              strokeWidth="2"
                            ></line>
                            <path
                              d="M138 8L126 14.9282L126 1.0718L138 8Z"
                              fill="#4EB529"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <h1 className="display-4 mb-3">
                        Cuidado de Salud Natural y Orgánico
                      </h1>
                      <p className="mb-6">
                        Enfoque de manera asertiva en el capital intelectual
                        impulsado por el mercado con un enfoque holístico en el
                        capital humano a nivel mundial.
                      </p>
                      <a
                        href="product-details.html"
                        className="btn btn-primary"
                      >
                        Comprar Ahora
                        <span className="ms-2">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                    <img
                      src={orange}
                      alt="orange"
                      className="slide-product img-fluid d-none d-md-block"
                    />
                    <img
                      src={orangeSlice}
                      alt="orange"
                      className="slide-product img-fluid d-md-none"
                    />
                  </div>
                  {/* Slide 2 */}
                  <div className="healthcare-hero-single swiper-slide bg-white rounded">
                    <div className="left-content">
                      <div className="d-flex align-items-center gap-1 gap-sm-2 mb-2 flex-wrap">
                        <h5 className="mb-0 fw-normal text-primary gshop-subtitle">
                          Alimentos 100% Orgánicos
                        </h5>
                        <span>
                          <svg
                            width="138"
                            height="16"
                            viewBox="0 0 138 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line
                              y1="8"
                              x2="132"
                              y2="8"
                              stroke="#4EB529"
                              strokeWidth="2"
                            ></line>
                            <path
                              d="M138 8L126 14.9282L126 1.0718L138 8Z"
                              fill="#4EB529"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <h1 className="display-4 mb-3">
                        Cuidado de Salud Natural y Orgánico
                      </h1>
                      <p className="mb-6">
                        Enfoque de manera asertiva en el capital intelectual
                        impulsado por el mercado con un enfoque holístico en el
                        capital humano a nivel mundial.
                      </p>
                      <a
                        href="product-details.html"
                        className="btn btn-primary"
                      >
                        Comprar Ahora
                        <span className="ms-2">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                    <img
                      src={orange}
                      alt="orange"
                      className="slide-product img-fluid d-none d-md-block"
                    />
                    <img
                      src={orangeSlice}
                      alt="orange"
                      className="slide-product img-fluid d-md-none"
                    />
                  </div>
                  {/* Slide 3 */}
                  <div className="healthcare-hero-single swiper-slide bg-white rounded">
                    <div className="left-content">
                      <div className="d-flex align-items-center gap-1 gap-sm-2 mb-2 flex-wrap">
                        <h5 className="mb-0 fw-normal text-primary gshop-subtitle">
                          Alimentos 100% Orgánicos
                        </h5>
                        <span>
                          <svg
                            width="138"
                            height="16"
                            viewBox="0 0 138 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line
                              y1="8"
                              x2="132"
                              y2="8"
                              stroke="#4EB529"
                              strokeWidth="2"
                            ></line>
                            <path
                              d="M138 8L126 14.9282L126 1.0718L138 8Z"
                              fill="#4EB529"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <h1 className="display-4 mb-3">
                        Cuidado de Salud Natural y Orgánico
                      </h1>
                      <p className="mb-6">
                        Enfoque de manera asertiva en el capital intelectual
                        impulsado por el mercado con un enfoque holístico en el
                        capital humano a nivel mundial.
                      </p>
                      <a
                        href="product-details.html"
                        className="btn btn-primary"
                      >
                        Comprar Ahora
                        <span className="ms-2">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                    <img
                      src={orange}
                      alt="orange"
                      className="slide-product img-fluid d-none d-md-block"
                    />
                    <img
                      src={orangeSlice}
                      alt="orange"
                      className="slide-product img-fluid d-md-none"
                    />
                  </div>
                </div>
                {/* Controles del Slider */}
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                ></span>
              </div>
              {/* Slider de miniaturas */}
              <div className="healthcare-hero-thumbnail-slider swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                <div className="swiper-wrapper">
                  {/* Miniatura 1 */}
                  <div className="swiper-slide thumbnail-single">
                    <img
                      src={thumbnail1}
                      alt="thumbnail"
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  {/* Miniatura 2 */}
                  <div className="swiper-slide thumbnail-single">
                    <img
                      src={thumbnail1}
                      alt="thumbnail"
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  {/* Miniatura 3 */}
                  <div className="swiper-slide thumbnail-single">
                    <img
                      src={thumbnail1}
                      alt="thumbnail"
                      className="img-fluid rounded-circle"
                    />
                  </div>
                </div>
                {/* Controles de miniaturas */}
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                ></span>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            {/* Banner lateral */}
            <div
              className="hm3-hero-banner position-relative overflow-hidden rounded-2"
              style={{ backgroundImage: `url(${banner8})` }}
            >
              <span className="offer-badge text-white fw-bold fs-xxs bg-danger position-absolute rounded-0 start-0 top-0">
                -12% OFF
              </span>
              <div className="d-flex align-items-center gap-2 flex-wrap">
                <h6 className="gshop-subtitle text-danger fw-normal mb-0">
                  Oferta Viernes Negro
                </h6>
                <span>
                  <svg
                    width="47"
                    height="8"
                    viewBox="0 0 47 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 3.99999L43.3478 4"
                      stroke="#FE4343"
                      strokeWidth="1.21739"
                    ></path>
                    <path
                      d="M47 4L41 0.535898L41 7.4641L47 4Z"
                      fill="#FE4343"
                    ></path>
                  </svg>
                </span>
              </div>
              <h3 className="mb-5 mt-2 text-white">
                Alimentos Orgánicos
                <br /> Hasta 50% de descuento
              </h3>
              <a href="shop-grid.html" className="btn btn-secondary">
                Comprar Ahora{" "}
                <span className="ms-2">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { SliderOrganico };
