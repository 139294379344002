import { Footer } from "../../components/Home/footer/Footer"
import { Header } from "../../components/Home/header/Header"
import frameCircle from "../../../public/img/shapes/frame-circle.svg"; 
import cauliflower from "../../../public/img/shapes/cauliflower.png";
import leaf from "../../../public/img/shapes/leaf.svg";
import pataXs from "../../../public/img/shapes/pata-xs.svg";
import tomatoHalf from "../../../public/img/shapes/tomato-half.svg";
import garlicWhite from "../../../public/img/shapes/garlic-white.png";
import tomatoSlice from "../../../public/img/shapes/tomato-slice.svg";
import onion from "../../../public/img/shapes/onion.png";
import error from "../../../public/img/error-img.png";

function Page500() {
  return (
    <div>
      <Header />
      <section className="section-404 ptb-120 position-relative overflow-hidden z-1">
      <img
          src={frameCircle}
          alt="círculo de marco"
          className="position-absolute z--1 frame-circle d-none d-sm-block"
        />
        <img
          src={cauliflower}
          alt="coliflor"
          className="position-absolute cauliflower z--1 d-none d-sm-block"
        />
        <img
          src={leaf}
          alt="hoja"
          className="position-absolute leaf z--1 d-none d-sm-block"
        />
        <img
          src={pataXs}
          alt="pata"
          className="position-absolute pata z--1 d-none d-sm-block"
        />
        <img
          src={tomatoHalf}
          alt="tomate"
          className="position-absolute tomato-half z--1 d-none d-sm-block"
        />
        <img
          src={garlicWhite}
          alt="ajo"
          className="position-absolute garlic-white z--1 d-none d-sm-block"
        />
        <img
          src={tomatoSlice}
          alt="rodaja de tomate"
          className="position-absolute tomato-slice z--1 d-none d-sm-block"
        />
        <img
          src={onion}
          alt="cebolla"
          className="position-absolute onion z--1 d-none d-sm-block"
        />
        <div className="container">
            <div className="row align-items-center justify-content-center">
            <div className="col-xl-6">
                <div className="content-404 text-center">
                <img
                    src={error}
                    width="50%"
                    alt="error 500"
                    className="img-fluid mb-5 d-none d-md-inline-block"
                />
                <h3 className="fw-bold display-1 mb-0">500</h3>
                <h2 className="mt-3">Lo sentimos, Error Interno del Servidor</h2>
                <p className="mb-6">
                    La página que estás buscando podría haber sido eliminada, haber cambiado de nombre o estar temporalmente
                    fuera de servicio.
                </p>
                <a href="/" className="btn btn-secondary btn-md rounded-1">
                    Volver a la página de inicio
                </a>
                </div>
            </div>
            </div>
        </div>
        </section>

      <Footer />
    </div>
  )
}

export {Page500}
