import chooseIcon1 from "../../../../public/img/home-5/choose-icon-1.png";
import chooseIcon2 from "../../../../public/img/home-5/choose-icon-2.png";
import chooseIcon3 from "../../../../public/img/home-5/choose-icon-3.png";

function ChooseUsCarniceria() {
  return (
    <div className="section-space-sm-y">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="choose-us-section section-space-sm-y">
              <div className="section-space-sm-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-xl-6 col-xxl-5">
                      <h2 className="mb-4 display-6">
                        Por qué la gente elige{" "}
                        <span className="meat-primary">Grostore Meat</span>
                      </h2>
                      <p className="mb-0">
                        Promovemos tecnologías proactivas con altos estándares
                        en la fabricación de productos y en la construcción de
                        equipos de competencias básicas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row g-4">
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <div className="choose-card">
                      <div className="mb-6">
                        <img
                          src={chooseIcon1}
                          alt="imagen"
                          className="img-fluid"
                        />
                      </div>
                      <h5 className="mb-2 fs-20">Control de Temperatura</h5>
                      <p className="mb-0 fs-14">
                        Fabricamos productos con estándares de construcción de
                        equipos de competencias básicas.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <div className="choose-card">
                      <div className="mb-6">
                        <img
                          src={chooseIcon2}
                          alt="imagen"
                          className="img-fluid"
                        />
                      </div>
                      <h5 className="mb-2 fs-20">Pruebas de Laboratorio</h5>
                      <p className="mb-0 fs-14">
                        Fabricamos productos con estándares de construcción de
                        equipos de competencias básicas.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <div className="choose-card">
                      <div className="mb-6">
                        <img
                          src={chooseIcon3}
                          alt="imagen"
                          className="img-fluid"
                        />
                      </div>
                      <h5 className="mb-2 fs-20">Tratamiento Antibacterial</h5>
                      <p className="mb-0 fs-14">
                        Fabricamos productos con estándares de construcción de
                        equipos de competencias básicas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUsCarniceria;
