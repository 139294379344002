import pSm1 from "../../../../public/img/products/p-sm-1.png";
import pSm2 from "../../../../public/img/products/p-sm-2.png";
import pSm3 from "../../../../public/img/products/p-sm-3.png";
import pSm4 from "../../../../public/img/products/p-sm-4.png";
import thumbsUp from "../../../../public/img/icons/thumbs-up.svg";

function ProductsOrganicos() {
  return (
    <section className="pt-120">
            <div className="container">
                <div className="row g-4">
                    <div className="col-xl-6">
                        <div className="hm3-product-tabs bg-white rounded py-6 px-5">
                            <ul className="nav nav-tabs hm3-tab-nav" role="tablist">
                                <li><a href="#new_arrivals" className="active" data-bs-toggle="tab" aria-selected="true" role="tab">Novedades</a></li>
                                <li><a href="#trending_products" data-bs-toggle="tab" aria-selected="false" role="tab" className="">Productos de moda</a></li>
                                <li><a href="#best_selling" data-bs-toggle="tab" aria-selected="false" role="tab" className="">Los más vendidos</a></li>
                            </ul>
                            <div className="tab-content mt-5">
                                <div className="tab-pane fade active show" id="new_arrivals" role="tabpanel">
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src={pSm1} alt="product" className="img-fluid" />
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">Cáscara de limón europeo</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Comprar ahora<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src={pSm2} alt="product" className="img-fluid"/>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">Cáscara de limón europeo</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Comprar ahora<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src={pSm3} alt="product" className="img-fluid"/>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">Cáscara de limón europeo</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Comprar ahora<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="trending_products" role="tabpanel">
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src={pSm4} alt="product" className="img-fluid"/>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reseñas)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">Cáscara de limón europeo</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Comprar ahora<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src="assets/img/products/p-sm-5.png" alt="product" className="img-fluid"/>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reviews)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">European Lemon Zest</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Shop Now<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src="assets/img/products/p-sm-1.png" alt="product" className="img-fluid"/>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reviews)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">European Lemon Zest</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Shop Now<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="best_selling" role="tabpanel">
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src="assets/img/products/p-sm-2.png" alt="product" className="img-fluid"/>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reviews)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">European Lemon Zest</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Shop Now<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src="assets/img/products/p-sm-3.png" alt="product" className="img-fluid"/>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reviews)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">European Lemon Zest</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Shop Now<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                    <div className="horizontal-product-card d-sm-flex align-items-center p-3 bg-white rounded-2 mt-3 border gap-4">
                                        <div className="thumbnail position-relative rounded-2">
                                            <img src="assets/img/products/p-sm-4.png" alt="product" className="img-fluid"/>
                                            <div className="product-overlay position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center gap-1 rounded-2">
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-regular fa-heart"></i></a>
                                                <a href="#" className="rounded-btn fs-xs"><i className="fa-solid fa-eye"></i></a>
                                                <a href="#" className="rounded-btn fs-xs">
                                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86193 0.189422C9.62476 0.422214 9.62476 0.799637 9.86193 1.03243L10.6472 1.80311H7.25462C5.91292 1.80311 4.82521 2.87064 4.82521 4.18749V4.78359C4.82521 5.11281 5.09713 5.37968 5.43256 5.37968C5.768 5.37968 6.03991 5.11281 6.03991 4.78359V4.18749C6.03991 3.52906 6.58374 2.9953 7.25462 2.9953H10.6472L9.86193 3.76599C9.62476 3.99877 9.62476 4.37622 9.86193 4.60899C10.0991 4.84177 10.4837 4.84177 10.7208 4.60899L12.5429 2.82071C12.7801 2.58792 12.7801 2.2105 12.5429 1.9777L10.7208 0.189422C10.4837 -0.0433652 10.0991 -0.0433652 9.86193 0.189422ZM7.86197 4.18749C7.52653 4.18749 7.25462 4.45436 7.25462 4.78359V5.37968C7.25462 6.03813 6.7108 6.57187 6.03991 6.57187H2.64736L3.43261 5.80118C3.66979 5.5684 3.66979 5.19096 3.43261 4.95818C3.19542 4.72541 2.81087 4.72541 2.57368 4.95818L0.751618 6.74647C0.514435 6.97924 0.514435 7.35669 0.751618 7.58946L2.57368 9.37775C2.81087 9.61052 3.19542 9.61052 3.43261 9.37775C3.66979 9.14497 3.66979 8.76752 3.43261 8.53475L2.64736 7.76406H6.03991C7.38162 7.76406 8.46933 6.69651 8.46933 5.37968V4.78359C8.46933 4.45436 8.19742 4.18749 7.86197 4.18749Z" fill="#5D6374"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-content mt-4 mt-sm-0">
                                            <div className="d-flex align-items-center flex-nowrap star-rating">
                                                <ul className="d-flex align-items-center me-2">
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                    <li className="text-secondary"><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <span className="flex-shrink-0">(5.2k Reviews)</span>
                                            </div>
                                            <a href="product-details.html" className="fw-bold text-heading title d-block fs-sm">
                                                <h6 className="mb-0">European Lemon Zest</h6>
                                            </a>
                                            <div className="pricing mt-2">
                                                <span className="fw-bold fs-xs deleted me-1">$240.00</span>
                                                <span className="fw-bold fs-xs text-danger">$140.00</span>
                                            </div>
                                            <a href="#" className="fs-xs fw-bold mt-10 d-inline-block explore-btn">Shop Now<span className="ms-1"><i className="fa-solid fa-arrow-right"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="section-right pt-8">
                            <div className="section-title mx-400">
                                <h2 className="mb-3">Get <span className="text-secondary">20% de descuento en </span>Su primera venta</h2>
                                <p className="mb-0">Compellingly fashion intermandated opportunities and multimedia based fnsparent e-business.</p>
                            </div>
                            <div className="row g-4 justify-content-center mt-3">
                                <div className="col-xl-4 col-md-4 col-sm-6">
                                    <div className="bg-white rounded py-6 px-3 text-center">
                                        <span className="py-3 px-4 bg-glimpse-pink d-inline-block rounded">
                                          <img src={thumbsUp} alt="hand" className="img-fluid"/>
                                      </span>
                                        <h3 className="mt-4 mb-0"><span className="counter">63</span><span>k+</span></h3>
                                        <p className="mb-0 fs-sm">Clientes activos</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-6">
                                    <div className="bg-white rounded py-6 px-3 text-center">
                                        <span className="py-3 px-4 bg-glimpse-pink d-inline-block rounded">
                                          <img src={thumbsUp} alt="hand" className="img-fluid"/>
                                      </span>
                                        <h3 className="mt-4 mb-0"><span className="counter">6</span><span>+</span></h3>
                                        <p className="mb-0 fs-sm">Cliente satisfechos</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-6">
                                    <div className="bg-white rounded py-6 px-3 text-center">
                                        <span className="py-3 px-4 bg-glimpse-pink d-inline-block rounded">
                                          <img src={thumbsUp} alt="hand" className="img-fluid"/>
                                      </span>
                                        <h3 className="mt-4 mb-0"><span className="counter">39</span><span>k+</span></h3>
                                        <p className="mb-0 fs-sm">Activar productos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export {ProductsOrganicos}
