import categoryIcon1 from "../../../../public/img/home-7/category-icon-1.png";
import categoryIcon2 from "../../../../public/img/home-7/category-icon-2.png";
import categoryIcon3 from "../../../../public/img/home-7/category-icon-3.png";
import categoryIcon4 from "../../../../public/img/home-7/category-icon-4.png";
import categoryIcon5 from "../../../../public/img/home-7/category-icon-5.png";

function CategoriesMueble() {
  return (
    <div className="section-space-top section-space-sm-bottom">
    <div className="section-space-xsm-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-6">
            <h2 className="text-center display-6 mb-0">Elige tu Categoría</h2>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row g-4">
        <div className="col-12">
          <ul className="list list--row flex-wrap gap-4 category-list" >
            <li>
              <a href="#" className="link d-block text-center category-list__content px-4 py-8 rounded-2" style={{color:"black"}}>
                <span className="category-list__icon mb-6 d-block text-center">
                  <img src={categoryIcon1} alt="Silla" className="img-fluid category-list__icon-is transition" />
                </span>
                <span className="category-list__title d-block fw-bold clr-heading text-center fs-20 mb-1 transition" >
                  Silla
                </span>
                <span className="category-list__subtitle d-flex justify-content-center align-items-center gap-1">
                  <span className="category-list__dot transition d-inline-block flex-shrink-0 w-2 h-2 rounded-circle secondary-bg" style={{backgroundColor:"#FF7C0A"}}></span>
                  <span className="category-list__subtitle-text clr-heading fs-14 transition">
                    25 Artículos Silla
                  </span>
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="link d-block text-center category-list__content px-4 py-8 rounded-2" style={{color:"black"}}>
                <span className="category-list__icon mb-6 d-block text-center">
                  <img src={categoryIcon2} alt="Sofá" className="img-fluid category-list__icon-is transition" />
                </span>
                <span className="category-list__title d-block fw-bold clr-heading text-center fs-20 mb-1 transition">
                  Sofá
                </span>
                <span className="category-list__subtitle d-flex justify-content-center align-items-center gap-1">
                  <span className="category-list__dot transition d-inline-block flex-shrink-0 w-2 h-2 rounded-circle secondary-bg" style={{backgroundColor:"#FF7C0A"}}></span>
                  <span className="category-list__subtitle-text clr-heading fs-14 transition">
                    25 Artículos Sofá
                  </span>
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="link d-block text-center category-list__content px-4 py-8 rounded-2" style={{color:"black"}}>
                <span className="category-list__icon mb-6 d-block text-center">
                  <img src={categoryIcon3} alt="Mesa" className="img-fluid category-list__icon-is transition" />
                </span>
                <span className="category-list__title d-block fw-bold clr-heading text-center fs-20 mb-1 transition">
                  Mesa
                </span>
                <span className="category-list__subtitle d-flex justify-content-center align-items-center gap-1">
                  <span className="category-list__dot transition d-inline-block flex-shrink-0 w-2 h-2 rounded-circle secondary-bg" style={{backgroundColor:"#FF7C0A"}}></span>
                  <span className="category-list__subtitle-text clr-heading fs-14 transition">
                    25 Artículos Mesa
                  </span>
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="link d-block text-center category-list__content px-4 py-8 rounded-2" style={{color:"black"}}>
                <span className="category-list__icon mb-6 d-block text-center">
                  <img src={categoryIcon4} alt="Cama" className="img-fluid category-list__icon-is transition" />
                </span>
                <span className="category-list__title d-block fw-bold clr-heading text-center fs-20 mb-1 transition">
                  Cama
                </span>
                <span className="category-list__subtitle d-flex justify-content-center align-items-center gap-1">
                  <span className="category-list__dot transition d-inline-block flex-shrink-0 w-2 h-2 rounded-circle secondary-bg" style={{backgroundColor:"#FF7C0A"}}></span>
                  <span className="category-list__subtitle-text clr-heading fs-14 transition">
                    25 Artículos Cama
                  </span>
                </span>
              </a>
            </li>
            <li>
              <a href="#" className="link d-block text-center category-list__content px-4 py-8 rounded-2" style={{color:"black"}}>
                <span className="category-list__icon mb-6 d-block text-center">
                  <img src={categoryIcon5} alt="Silla de Comedor" className="img-fluid category-list__icon-is transition" />
                </span>
                <span className="category-list__title d-block fw-bold clr-heading text-center fs-20 mb-1 transition">
                  Silla de Comedor
                </span>
                <span className="category-list__subtitle d-flex justify-content-center align-items-center gap-1">
                  <span className="category-list__dot transition d-inline-block flex-shrink-0 w-2 h-2 rounded-circle secondary-bg" style={{backgroundColor:"#FF7C0A"}}></span>
                  <span className="category-list__subtitle-text clr-heading fs-14 transition">
                    25 Artículos Silla
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}

export {CategoriesMueble}
