import ct1 from "../../../../public/img/home-2/ct-1.jpg";
import ct2 from "../../../../public/img/home-2/ct-2.jpg";
import ct3 from "../../../../public/img/home-2/ct-3.jpg";
import ct4 from "../../../../public/img/home-2/ct-4.jpg";
import banner7 from "../../../../public/img/banner/banner-7.jpg";

function CategoriesNatural() {
    return (
        <section className="position-relative pt-120 overflow-hidden">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-5">
                        <div className="section-title text-center mb-6">
                            <h5 className="mb-1 gshop-subitile text-secondary">Alimentos 100% Orgánicos</h5>
                            <h2 className="mb-3">Explora Todas las Categorías</h2>
                            <p className="mb-0">Mercados de nicho pegajosos a través de redes orientadas a objetivos. Completamente recapitular.</p>
                        </div>
                    </div>
                </div>
                <div className="row g-4 justify-content-center">
                    <div className="col-xxl-3 col-lg-4 col-sm-6">
                        <div className="categories-card position-relative bg-white rounded pt-4 px-4 pb-5 overflow-hidden">
                            <div className="thumbnail overflow-hidden">
                                <img src={ct1} alt="vegetales" className="img-fluid" />
                            </div>
                            <a href="#">
                                <h5 className="mt-4 mb-3">Vegetales Orgánicos</h5>
                            </a>
                            <ul className="ct-list mb-4">
                                <li>Papa <span className="float-end ms-3">14</span></li>
                                <li>Jengibre <span className="float-end ms-3">14</span></li>
                                <li>Ajo <span className="float-end ms-3">14</span></li>
                                <li>Chile Verde <span className="float-end ms-3">14</span></li>
                                <li>Aceite de Soja <span className="float-end ms-3">14</span></li>
                            </ul>
                            <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-lg-4 col-sm-6">
                        <div className="categories-card position-relative bg-white rounded pt-4 px-4 pb-5 overflow-hidden">
                            <div className="thumbnail overflow-hidden">
                                <img src={ct2} alt="frutas" className="img-fluid" />
                            </div>
                            <a href="#">
                                <h5 className="mt-4 mb-3">Frutas Frescas</h5>
                            </a>
                            <ul className="ct-list mb-4">
                                <li>Papa <span className="float-end ms-3">14</span></li>
                                <li>Jengibre <span className="float-end ms-3">14</span></li>
                                <li>Ajo <span className="float-end ms-3">14</span></li>
                                <li>Chile Verde <span className="float-end ms-3">14</span></li>
                                <li>Aceite de Soja <span className="float-end ms-3">14</span></li>
                            </ul>
                            <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-lg-4 col-sm-6">
                        <div className="categories-card position-relative bg-white rounded pt-4 px-4 pb-5 overflow-hidden">
                            <div className="thumbnail overflow-hidden">
                                <img src={ct3} alt="frutos secos" className="img-fluid" />
                            </div>
                            <a href="#">
                                <h5 className="mt-4 mb-3">Frutos Secos y Alimentos Secos</h5>
                            </a>
                            <ul className="ct-list mb-4">
                                <li>Papa <span className="float-end ms-3">14</span></li>
                                <li>Jengibre <span className="float-end ms-3">14</span></li>
                                <li>Ajo <span className="float-end ms-3">14</span></li>
                                <li>Chile Verde <span className="float-end ms-3">14</span></li>
                                <li>Aceite de Soja <span className="float-end ms-3">14</span></li>
                            </ul>
                            <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-lg-4 col-sm-6">
                        <div className="categories-card position-relative bg-white rounded pt-4 px-4 pb-5 overflow-hidden">
                            <div className="thumbnail overflow-hidden">
                                <img src={ct4} alt="postres frescos" className="img-fluid" />
                            </div>
                            <a href="#">
                                <h5 className="mt-4 mb-3">Postres Frescos</h5>
                            </a>
                            <ul className="ct-list mb-4">
                                <li>Papa <span className="float-end ms-3">14</span></li>
                                <li>Jengibre <span className="float-end ms-3">14</span></li>
                                <li>Ajo <span className="float-end ms-3">14</span></li>
                                <li>Chile Verde <span className="float-end ms-3">14</span></li>
                                <li>Aceite de Soja <span className="float-end ms-3">14</span></li>
                            </ul>
                            <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                        </div>
                    </div>
                </div>
                <div className="row g-4 mt-3">
                    <div className="col-xl-7">
                        <div className="banner-4 rounded-3" style={{ backgroundImage: `url(${banner7})` }}>
                            <div className="d-flex align-items-center gap-2 mb-1">
                                <h6 className="mb-0 gshop-subtitle text-secondary fw-normal">Productos Más Vendidos</h6>
                                <span>
                                    <svg width="58" height="10" viewBox="0 0 58 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="-6.99382e-08" y1="5.2" x2="52" y2="5.2" stroke="#FF7C08" strokeWidth="1.6"></line>
                                        <path d="M58 5L50.5 9.33013L50.5 0.669872L58 5Z" fill="#FF7C08"></path>
                                    </svg>
                                </span>
                            </div>
                            <h3 className="mb-3 text-white">Carne Fresca <br />La Mejor Para Tu Familia</h3>
                            <div className="d-flex align-items-center gap-3">
                                <span className="badge-clip fw-medium text-white">CALIENTE</span>
                                <span className="text-white fw-semibold fs-xs">COMPRA 1 LLEVA 1 GRATIS</span>
                            </div>
                            <a href="#" className="btn btn-secondary btn-md rounded-1 mt-5">Comprar Ahora<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="bg-white rounded shadow p-5">
                            <div className="row g-4">
                                <div className="col-sm-6">
                                    <div className="bg-glimpse-pink py-6 px-5 rounded">
                                        <h3 className="mb-1"><span className="counter">369</span>K+</h3>
                                        <h6 className="mb-0 text-gray">Clientes Activos</h6>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="bg-azure-mist py-6 px-5 rounded">
                                        <h3 className="mb-1"><span className="counter">369</span>K+</h3>
                                        <h6 className="mb-0 text-gray">Clientes Activos</h6>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="bg-ambrosia py-6 px-5">
                                        <h3 className="mb-1"><span className="counter">204</span>K+</h3>
                                        <h6 className="mb-0 text-gray">Clientes Activos</h6>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="bg-placebo-blue py-6 px-5">
                                        <h3 className="mb-1"><span className="counter">200</span>K+</h3>
                                        <h6 className="mb-0 text-gray">Clientes Activos</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export { CategoriesNatural }