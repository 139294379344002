import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { MyAccount } from './pages/MyAccount';
import { HomeMedina } from './components/HomeMedicina/HomeMedina';
import { HomeNatural } from './components/HomeNatural/HomeNatural';
import { HomeOrganico } from './components/HomeOrganico/HomeOrganico';
import { HomeCarne } from './components/HomeCarne/HomeCarne';
import { HomeCarniceria } from './components/HomeCarniceria/HomeCarniceria';
import { HomeMueble } from './components/HomeMueble/HomeMueble';
import { ShopStore } from './components/productsView/shopStore/ShopStore';
import { ShopStoreV } from './components/productsView/shopstoreV2/ShopStoreV';
import { ProductDetails } from './components/productsView/productDetails/ProductDetails';
import { MainBlog } from './components/blogView/mainBlog/MainBlog';
import { BlogList } from './components/blogView/blogList/BlogList';
import { BlogDetails } from './components/blogView/blogDetails/BlogDetails';
import { About } from './pages/About/About';
import { Servicios } from './pages/Servicios/Servicios';
import { ServiceDetails } from './pages/Servicios/ServiceDetails';
import { Shop } from './pages/Shop/Shop';
import { Favorites } from './pages/Favorites/Favorites';
import { Checkout } from './pages/Checkout/Checkout';
import { Invoice } from './pages/Invoice/Invoice';
import { Contact } from './pages/Contact/Contact';
import { Team } from './pages/team/Team';
import { Login } from './pages/Login/Login';
import { Register } from './pages/Register/Register';
import { Page404 } from './pages/404/Page404';
import { Page500 } from './pages/500/Page500';
import { AllCampaign } from './pages/AllCampaign/AllCampaign';
import { DetailsCampaing } from './pages/DetailsCampaing/DetailsCampaing';
import { AllCoupons } from './pages/AllCoupons/AllCoupons';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Productos" element={<ShopStore />} />
      <Route path="/Productos2" element={<ShopStoreV />} />
      <Route path="/Detalles_de_productos" element={<ProductDetails />} />
      <Route path="/Blog" element={<MainBlog />} />
      <Route path="/BlogList" element={<BlogList />} />
      <Route path="/Blog_de_detalles" element={<BlogDetails />} />
      <Route path="/Farmacia" element={<HomeMedina />} />
      <Route path="/Natural" element={<HomeNatural />} />
      <Route path="/Organica" element={<HomeOrganico />} />
      <Route path="/Carne" element={<HomeCarne />} />
      <Route path="/Carniceria" element={<HomeCarniceria />} />
      <Route path="/Muebles" element={<HomeMueble />} />
      <Route path="/mi_cuenta" element={<MyAccount />} />
      <Route path="/Sobre_nosotros" element={<About />} />
      <Route path="/Servicios" element={<Servicios />} />
      <Route path="/Detalles_de_servicios" element={<ServiceDetails />} />
      <Route path="/Carrito_de_Compra" element={<Shop />} />
      <Route path="/Mis_favoritos" element={<Favorites />} />
      <Route path="/Finalizar_compra" element={<Checkout />} />
      <Route path="/Factura" element={<Invoice />} />
      <Route path="/Contactanos" element={<Contact />} />
      <Route path="/Equipo" element={<Team />} />
      <Route path="/Iniciar_sesion" element={<Login />} />
      <Route path="/Registrar" element={<Register />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/500" element={<Page500 />} />
      <Route path="/Campañas" element={<AllCampaign />} />
      <Route path="/Detalles_de_Campañas" element={<DetailsCampaing />} />
      <Route path="/Todos_cupones" element={<AllCoupons />} />
    </Routes>
  )
}

export default App