import bgShape from "../../../../public/img/home-5/bg-shape.png";
import leafShadow from "../../../../public/img/shapes/leaf-shadow.png";
import mango from "../../../../public/img/shapes/mango.png";
import heroCircleSm from "../../../../public/img/shapes/hero-circle-sm.png";
import heroImg from "../../../../public/img/home-5/hero-img.png";

function SliderCarniceria() {
  return (
<section 
      className="gshop-hero meat-hero ptb-120 bg-meat-primary position-relative z-1 overflow-hidden" 
      style={{ background: `url(${bgShape}), no-repeat center top` }}
    >
      <img 
        src={leafShadow} 
        alt="hoja" 
        className="position-absolute leaf-shape z--1 rounded-circle" 
      />
      <img 
        src={mango} 
        alt="mango" 
        className="position-absolute mango z--1" 
        data-parallax='{"y": -120}' 
      />
      <img 
        src={heroCircleSm} 
        alt="círculo" 
        className="position-absolute hero-circle circle-sm z--1" 
      />
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-5 col-lg-8">
            <div className="hero-left-content">
              <h1 className="display-3 fw-bolder mb-3">
                <span className="fs-1">Producción de Carne</span> <br />
                Empresa Líder
              </h1>
              <p className="mb-7 fs-6">
                Integrar distintivamente enlaces totales interoperables y procesos covalentes. Generar sin problemas.
              </p>
              <div className="hero-btns d-flex align-items-center gap-3 gap-sm-5 flex-wrap animated-btn-icon">
                <a href="shop-grid.html" className="btn btn-dark">
                  Comprar Ahora<span className="ms-2"><i className="fas fa-arrow-right-long"></i></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7">
            <div className="hero-right text-center position-relative z-1 mt-8 mt-xl-0">
              <img 
                src={heroImg} 
                alt="frutas" 
                className="img-fluid hero-img" 
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {SliderCarniceria}
