import { Header } from "../../components/Home/header/Header"
import banner7 from "../../../public/img/banner/banner-7.jpg";
import truck from "../../../public/img/icons/truck.svg";
import price from "../../../public/img/icons/price.svg";
import locationTracking from "../../../public/img/icons/location-tracking.svg";
import moneyBack from "../../../public/img/icons/money-back.svg";
import girl from "../../../public/img/gallery/girl.jpg";
import milkColor from "../../../public/img/icons/milk-color.svg";
import milkWhite from "../../../public/img/icons/milk-white.svg";
import cartColor from "../../../public/img/icons/cart-color.svg";
import cartWhite from "../../../public/img/icons/cart-white.svg";
import deliveryColor from "../../../public/img/icons/delivery-color.svg";
import deliveryWhite from "../../../public/img/icons/delivery-white.svg";
import { Footer } from "../../components/Home/footer/Footer";


function ServiceDetails() {
  return (
    <div className="bg-[#FAFAFA]">
      <Header />
      <section className="service-details ptb-120">
            <div className="container">
                <div className="row g-5 g-xl-4">
                    <div className="col-xl-3">
                        <ul className="service-nav">
                            <li><a href="#" className="active">Nuestro Servicio</a></li>
                            <li><a href="#">Servicios de Tienda Orgánica</a></li>
                            <li><a href="#">Productos Agrícolas</a></li>
                            <li><a href="#">Productos Dulces de Leche</a></li>
                            <li><a href="#">Servicios de Siembra</a></li>
                            <li><a href="#">Servicio de Entrega</a></li>
                            <li><a href="#">Servicios de Pesca</a></li>
                        </ul>
                    </div>
                    <div className="col-xl-9">
                        <div className="details-content">
                            <img src={banner7} alt="banner" className="img-fluid rounded-3" />
                            <h2 className="mt-7 mb-4">Servicios de Tienda Orgánica</h2>
                            <p>Orgánico significa cultivar nuestros alimentos, los cuales nos nutren, sin ayudas químicas durante el proceso de cultivo como fertilizantes, pesticidas, fungicidas, herbicidas, larvicidas, etc. Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la tipografía. Lorem Ipsum ha sido el texto estándar de la industria desde el siglo XVI, cuando un impresor desconocido combinó un montón de tipos para crear un libro de muestras. Ha sobrevivido más de cinco siglos.</p>
                            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.</p>
                            <div className="row g-4 mt-4">
                                <div className="col-lg-6">
                                    <div className="horizontal-icon-box d-flex align-items-center gap-4">
                                        <span className="box-icon d-inline-flex align-items-center justify-content-center rounded flex-shrink-0 bg-white">
                                            <img src={truck} alt="camión" className="img-fluid" />
                                        </span>
                                        <div className="content-right">
                                            <h5 className="mb-2">Entrega Rápida</h5>
                                            <p className="mb-0">Magnam aliquam quaerat voluptatem. Ut enim ad minima veniam nostrum exercitationem.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="horizontal-icon-box d-flex align-items-center gap-4">
                                        <span className="box-icon d-inline-flex align-items-center justify-content-center rounded flex-shrink-0 bg-white">
                                            <img src={price} alt="precio" className="img-fluid" />
                                        </span>
                                        <div className="content-right">
                                            <h5 className="mb-2">Precio Razonable</h5>
                                            <p className="mb-0">Magnam aliquam quaerat voluptatem. Ut enim ad minima veniam nostrum exercitationem.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="horizontal-icon-box d-flex align-items-center gap-4">
                                        <span className="box-icon d-inline-flex align-items-center justify-content-center rounded flex-shrink-0 bg-white">
                                            <img src={locationTracking} alt="rastreo" className="img-fluid" />
                                        </span>
                                        <div className="content-right">
                                            <h5 className="mb-2">Rastreo de Ubicación</h5>
                                            <p className="mb-0">Magnam aliquam quaerat voluptatem. Ut enim ad minima veniam nostrum exercitationem.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="horizontal-icon-box d-flex align-items-center gap-4">
                                        <span className="box-icon d-inline-flex align-items-center justify-content-center rounded flex-shrink-0 bg-white">
                                            <img src={moneyBack} alt="garantía" className="img-fluid" />
                                        </span>
                                        <div className="content-right">
                                            <h5 className="mb-2">Garantía de Devolución de Dinero</h5>
                                            <p className="mb-0">Magnam aliquam quaerat voluptatem. Ut enim ad minima veniam nostrum exercitationem.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 align-items-center mt-4">
                                <div className="col-xl-6">
                                    <div className="left-desc">
                                        <h3 className="mb-3">Cómo Beneficiarse de G-Shop</h3>
                                        <p className="mb-0">Orgánico significa cultivar nuestros alimentos sin ayudas químicas durante el proceso de cultivo como fertilizantes, pesticidas, fungicidas, herbicidas, larvicidas, etc. Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la tipografía. Lorem Ipsum ha sido el texto estándar de la industria desde el siglo XVI.</p>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="video-content position-relative">
                                        <img src={girl} alt="no encontrado" className="img-fluid rounded" />
                                        <a href="https://www.youtube.com/watch?v=6WZOxnYi4Cs" className="video-popup-btn bg-secondary text-white rounded-circle position-absolute"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="download-box d-flex flex-wrap gap-4 align-items-center justify-content-between position-relative rounded-3 p-5 overflow-hidden z-1 mt-8 mb-7">
                                <div className="d-inline-flex align-items-center gap-4">
                                    <span className="icon d-inline-flex align-items-center justify-content-center rounded-circle bg-white text-secondary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-download"
                                        >
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3" />
                                        </svg>
                                    </span>
                                    <div>
                                        <h4 className="mb-2">Descargar Folleto de G-Shop</h4>
                                        <h6 className="mb-0 fw-semibold text-gray">[formato pdf] 4MB</h6>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-secondary rounded-1">Descargar Ahora</a>
                            </div>
                            <h3 className="mb-3">Otros Servicios</h3>
                            <p className="mb-5">Orgánico significa cultivar nuestros alimentos sin ayudas químicas durante el proceso de cultivo como fertilizantes, pesticidas, fungicidas, herbicidas, larvicidas, etc. Lorem Ipsum es simplemente un texto ficticio.</p>
                            <div className="row g-4 justify-content-center mt-5">
                                <div className="col-lg-6 col-xl-4">
                                    <div className="icon-box rounded-3 py-7 px-4">
                                        <div className="icon-wrapper mb-4 position-relative">
                                        <img src={cartColor} alt="icon" className="img-fluid icon-color" />
                                        <img src={cartWhite} alt="icon" className="img-fluid icon-white" />
                                        </div>
                                        <a href="#">
                                            <h5 className="mb-3">Productos Agrícolas</h5>
                                        </a>
                                        <p className="mb-5">Aplicaciones basadas en cadenas de suministro flexibles. Evolucionar pizarras autónomas.</p>
                                        <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                    <div className="icon-box rounded-3 py-7 px-4">
                                        <div className="icon-wrapper mb-4 position-relative">
                                            <img src={milkColor} alt="icon" className="img-fluid icon-color" />
                                            <img src={milkWhite} alt="icon" className="img-fluid icon-white" />
                                        </div>
                                        <a href="#">
                                            <h5 className="mb-3">Productos Dulces de Leche</h5>
                                        </a>
                                        <p className="mb-5">Aplicaciones basadas en cadenas de suministro flexibles. Evolucionar pizarras autónomas.</p>
                                        <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                    <div className="icon-box rounded-3 py-7 px-4">
                                        <div className="icon-wrapper mb-4 position-relative">
                                        <img src={deliveryColor} alt="icon" className="img-fluid icon-color" />
                                        <img src={deliveryWhite} alt="icon" className="img-fluid icon-white" />
                                        </div>
                                        <a href="#">
                                            <h5 className="mb-3">Servicios de Siembra</h5>
                                        </a>
                                        <p className="mb-5">Aplicaciones basadas en cadenas de suministro flexibles. Evolucionar pizarras autónomas.</p>
                                        <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
  )
}

export {ServiceDetails}
