import cliente from "../../../../public/img/about/clients.png";
import client1 from "../../../../public/img/authors/client-1.png";
import client2 from "../../../../public/img/authors/client-2.png";
import client3 from "../../../../public/img/authors/client-3.png";

function FeedbacksOrganico() {
    const feedbacks = [
        {
          image: client1,
          name: 'George Nakashima',
          review: '“Convenientemente sinergizamos la colaboración premium y el intercambio de ideas con un pensamiento "fuera de la caja". Producto interactivo paradigmas distintivos mientras que uno-a-uno capital intelectual. servicios de succión de recursos. Personalizar verticalmente objetivamente.”',
        },
        {
          image: client2,
          name: 'George Nakashima',
          review: '“Convenientemente sinergizamos la colaboración premium y el intercambio de ideas con un pensamiento "fuera de la caja". Producto interactivo paradigmas distintivos mientras que uno-a-uno capital intelectual. servicios de succión de recursos. Personalizar verticalmente objetivamente.”',
        },
        {
          image: client3,
          name: 'George Nakashima',
          review: '“Convenientemente sinergizamos la colaboración premium y el intercambio de ideas con un pensamiento "fuera de la caja". Producto interactivo paradigmas distintivos mientras que uno-a-uno capital intelectual. servicios de succión de recursos. Personalizar verticalmente objetivamente.”',
        },
      ];
  return (
    <section className="feedback-section pt-120 has-banner-bottom position-relative z-1 overflow-hidden bg-floral-white">
      <div className="container">
        <div className="row align-items-center g-4">
          <div className="col-xl-7">
            <div className="clients_thumb">
              <img src={cliente} alt="clientes" className="img-fluid" />
            </div>
          </div>
          <div className="col-xl-5">
            <div className="swiper feedback-slider-2">
              <div className="swiper-wrapper">
                {feedbacks.map((feedback, index) => (
                  <div className="swiper-slide feedback-card bg-white rounded py-6 px-4" key={index}>
                    <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                      <img src={feedback.image} alt="cliente" className="img-fluid rounded-circle flex-shrink-0" />
                      <div className="clients-info">
                        <h5 className="mb-1">{feedback.name}</h5>
                        <ul className="d-flex align-items-center fs-xs text-warning">
                          <li><i className="fas fa-star"></i></li>
                          <li><i className="fas fa-star"></i></li>
                          <li><i className="fas fa-star"></i></li>
                          <li><i className="fas fa-star"></i></li>
                          <li><i className="fas fa-star"></i></li>
                        </ul>
                      </div>
                    </div>
                    <p className="mb-0">{feedback.review}</p>
                  </div>
                ))}
              </div>
              <div className="slider-arrows text-end mt-5">
                <button type="button" className="fd2-arrow-left" aria-label="Diapositiva anterior"><i className="fas fa-angle-left"></i></button>
                <button type="button" className="fd2-arrow-right" aria-label="Siguiente diapositiva"><i className="fas fa-angle-right"></i></button>
              </div>
              <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {FeedbacksOrganico}
