import { Header } from "../../Home/header/Header";
import { BreadCrumb } from "../../productsView/breadCrumb/BreadCrumb";
import gallery1 from "../../../../public/img/gallery/gallery-1.jpg";
import gallery2 from "../../../../public/img/gallery/gallery-2.jpg";
import gallery3 from "../../../../public/img/gallery/gallery-3.jpg";
import gallery4 from "../../../../public/img/gallery/gallery-4.jpg";
import gallery5 from "../../../../public/img/gallery/gallery-5.jpg";
import gallery6 from "../../../../public/img/gallery/gallery-6.jpg";
import pSm1 from "../../../../public/img/products/p-sm-1.png";
import bannerImage from "../../../../public/img/banner/banner-5.jpg";

import blogThumbLg1 from "../../../../public/img/blog/blog-thumb-lg-1.jpg";
import blogThumb3 from "../../../../public/img/blog/blog-thumb-3.jpg";
import blogThumb2 from "../../../../public/img/blog/blog-thumb-2.jpg";
import blogThumb4 from "../../../../public/img/blog/blog-thumb-4.jpg";
import authorLg1 from "../../../../public/img/authors/author-lg-1.png";
import userThumb1 from "../../../../public/img/authors/user-thumb-1.jpg";
import userThumb2 from "../../../../public/img/authors/user-thumb-2.jpg";
import { Footer } from "../../Home/footer/Footer";

function BlogDetails() {
  return (
    <>
      <Header />
      <BreadCrumb title="Entrega a domicilio de vacaciones Tenemos recientemente fresco <br /> Carnes & Mariscos" pageName="Página" currentPage="Detalles del blog" />
      <section className="blog-details pb-100">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-8">
              <div className="blog-details-content bg-white rounded-2 py-6 px-5">
                <div className="thumbnail rounded-2 overflow-hidden">
                  <img
                    src={blogThumbLg1}
                    alt="blog thumb"
                    className="img-fluid"
                  />
                </div>
                <div className="blog-meta d-flex align-items-center gap-3 flex-wrap mt-5">
                  <span className="fs-xs fw-medium">
                    <i className="fa-solid fa-tags me-2"></i>Vegetales Orgánicos
                  </span>
                  <span className="fs-xs fw-medium">
                    <i className="fa-regular fa-clock me-2"></i>Vegetales
                    Orgánicos
                  </span>
                  <span className="fs-xs fw-medium">
                    <i className="fa-regular fa-comments me-2"></i>Vegetales
                    Orgánicos
                  </span>
                </div>
                <span className="hr-line w-100 position-relative d-block my-4"></span>
                <p>
                  Estas son las personas que facilitan tu vida. Se colocaron
                  grandes baldosas sobre la encimera cerca de la ventana de la
                  sala, estaban conectadas con la cocina. La manera perfecta de
                  disfrutar de una infusión en la fruta baja para identificar.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris. Las grandes baldosas estaban dispuestas sobre la
                  encimera cerca de la ventana del salón, estaban conectadas a
                  la cocina a través de la apertura en la pared existente. Para
                  mí, la parte más importante de mejorar en fotografía ha sido
                  compartirla. Regístrate en una cuenta de Exposure, o publica
                  regularmente en Tumblr, o ambos. Dile a la gente que estás
                  tratando de mejorar en fotografía.
                </p>
                <p>
                  Al leer el resumen de un cliente, a menudo descubrimos que los
                  objetivos comerciales y los requisitos no están claramente
                  formulados. Ya sea que recibamos Clientes del Infierno,
                  suframos una falta de comunicación o enfrentemos expectativas
                  poco realistas e imprecisas. Escuchamos solicitudes vagas,
                  como "Queremos un nuevo sitio web para atraer más visitantes"
                  o "Necesitamos una aplicación muy llamativa". Como resultado,
                  es difícil identificar los objetivos principales del diseño.
                  Es deber de un diseñador profesional evaluar y planificar
                  todas las necesidades del proyecto así como los resultados
                  esperados.
                </p>
                <div className="row g-4 mt-3 mb-5">
                  <div className="col-6">
                    <div className="thumbnail">
                      <img
                        src={blogThumb3}
                        alt="blog thumb"
                        className="img-fluid rounded-2"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="thumbnail">
                      <img
                        src={blogThumb2}
                        alt="blog thumb"
                        className="img-fluid rounded-2"
                      />
                    </div>
                  </div>
                </div>
                <h4>La Experiencia es en todo el mundo</h4>
                <p>
                  These are the people who make your life easier. Large tiles
                  were arranged on the counter top plate near the window of the
                  living room, they were connected to the kitchen. The perfect
                  way to enjoy brewing tea on low hanging fruit to identify. Ut
                  enim ad minim veniam, quis nostrud exercitation
                </p>
                <blockquote className="my-6 py-6 px-5 position-relative rounded">
                  <h5 className="fw-semibold text-gray mb-0">
                    “The perfect way to enjoy brewing tea on low hanging fruit
                    to identify. Ut enim ad minim veniam, quis nostrud
                    exercitation of the living room, they were connected to the
                    kitchen”{" "}
                    <span className="title text-dark d-inline-block">
                      Mark Jackson
                    </span>
                  </h5>
                  <span className="quote-icon">
                    <svg
                      width="49"
                      height="39"
                      viewBox="0 0 49 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32.5 20.5263L44.28 20.5263C43.9083 23.0668 43.0244 25.4997 41.6848 27.6698C40.3452 29.8399 38.5793 31.6994 36.5 33.1295L32.92 35.5926L35.12 39L38.7 36.5368C41.7134 34.4758 44.1846 31.6833 45.8941 28.4069C47.6037 25.1306 48.4988 21.4718 48.5 17.7553L48.5 4.10527C48.5 3.01648 48.0786 1.97229 47.3284 1.20241C46.5783 0.432522 45.5609 3.55775e-06 44.5 3.46501e-06L32.5 2.41593e-06C31.4391 2.32319e-06 30.4217 0.43252 29.6716 1.20241C28.9214 1.97229 28.5 3.01648 28.5 4.10526L28.5 16.4211C28.5 17.5098 28.9214 18.554 29.6716 19.3239C30.4217 20.0938 31.4391 20.5263 32.5 20.5263ZM4.5 20.5263L16.28 20.5263C15.9083 23.0668 15.0244 25.4997 13.6848 27.6698C12.3452 29.8399 10.5793 31.6994 8.5 33.1295L4.91999 35.5926L7.09999 39L10.7 36.5368C13.7134 34.4758 16.1846 31.6833 17.8941 28.4069C19.6037 25.1306 20.4988 21.4718 20.5 17.7553L20.5 4.10526C20.5 3.01648 20.0786 1.97229 19.3284 1.20241C18.5783 0.432519 17.5609 1.10991e-06 16.5 1.01717e-06L4.5 -3.19052e-08C3.43913 -1.24649e-07 2.42172 0.432518 1.67157 1.2024C0.921424 1.97229 0.499996 3.01648 0.499995 4.10526L0.499994 16.4211C0.499994 17.5098 0.921423 18.554 1.67157 19.3239C2.42172 20.0938 3.43913 20.5263 4.5 20.5263Z"
                        fill="#FFE5CE"
                      ></path>
                    </svg>
                  </span>
                </blockquote>
                <div className="row align-items-center g-4">
                  <div className="col-md-6">
                    <div className="thumbnail">
                      <img
                        src={blogThumb4}
                        alt="blog thumb"
                        className="img-fluid rounded-2"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="blog-content">
                      <h4>Nuestro Enfoque Personal</h4>
                      <p className="mb-0">
                        Estas son las personas que facilitan tu vida. Se
                        colocaron grandes baldosas sobre la encimera cerca de la
                        ventana de la sala, estaban conectadas con la cocina. La
                        manera perfecta de disfrutar de una infusión en la fruta
                        baja para identificar. Ut enim ad minim veniam, quis
                        nostrud exercitation
                      </p>
                    </div>
                  </div>
                </div>
                <h4 className="mt-5 mb-3">Te Puede Interesar</h4>
                <div className="row g-4">
                  <div className="col-md-6">
                    <ul className="list-style-type-disc ps-4">
                      <li>Lorem Ipsum generators on the tend to repeat.</li>
                      <li>If you are going to use a passage.</li>
                      <li>Lorem Ipsum generators on the tend to repeat.</li>
                      <li>Lorem Ipsum generators on the tend to repeat.</li>
                      <li>If you are going to use a passage.</li>
                    </ul>
                  </div>
                </div>
                <h4 className="mt-6 mb-4">
                  ¿Por qué los inversores están mirando hacia los vegetales?
                </h4>
                <p>
                  Energísticamente conceptualiza paradigmas 24/7 sin procesos
                  completamente probados. Crea de manera fluida catalizadores
                  críticos para el cambio con cadenas de suministro
                  intermandadas.
                </p>
                <div className="row g-4 mb-5">
                  <div className="col-sm-6">
                    <div className="thumbnail">
                      <img
                        src={blogThumb2}
                        alt="blog thumb"
                        className="img-fluid rounded-2"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="thumbnail">
                      <img
                        src={blogThumb3}
                        alt="blog thumb"
                        className="img-fluid rounded-2"
                      />
                    </div>
                  </div>
                </div>
                <p>
                  Personaliza distintivamente la información holística mientras
                  mejoras los procesos multidisciplinarios. Facilita
                  proactivamente asociaciones de alto impacto a largo plazo a
                  través de servicios meta de maximización de recursos.
                  Predomina dinámicamente los intermediarios estandarizados
                  frente a la vinculación total covalente. Despliega
                  funcionalmente.
                </p>
                <p>
                  Vitae ultricies erat elit eu lacus. Vestibulum non justo fun
                  consectetur, cursus ante, tincidunt sapien. Nulla quis diam
                  sit ametturpis interd enim. Vivamus fauc ex sed nibh egestas
                  elementum. Mauris et bibendum dui. Aenean consequat pulvinar
                  luctus. Suspendisse consectetur tristique tortor.
                </p>
                <div className="tags-social d-flex align-items-center justify-content-between flex-wrap gap-4 mt-6">
                  <div className="tags-list d-flex align-items-center gap-2 flex-wrap">
                    <span className="title text-secondary fw-bold me-2">
                      Etiquetas:
                    </span>
                    <a href="#">Concesionario de Automóviles</a>
                    <a href="#">Discos de Frenos</a>
                    <a href="#">Tienda de Autos</a>
                  </div>
                  <div className="bs_social_share">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-behance"></i>
                    </a>
                  </div>
                </div>
                <div className="bs-author-box mt-6 d-sm-flex align-items-center rounded">
                  <img
                    src={authorLg1}
                    alt="author"
                    className="rounded-circle flex-shrink-0"
                  />
                  <div className="author-box-content ms-sm-4 mt-4 mt-sm-0">
                    <h6 className="mb-1">Kristin Watsons</h6>
                    <a
                      href="https://themetags.com"
                      className="text-danger fw-semibold"
                    >
                      visita: themtags.com
                    </a>
                    <p className="mt-2 mb-3">
                      Transiciona progresivamente contenido integrado mientras
                      restauras objetivamente el valor intermandado sin
                      relaciones óptimas.
                    </p>
                    <div className="bs-author-socials">
                      <a href="#" className="facebook">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#" className="twitter">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#" className="instagram">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <h4 className="mt-6">Comentarios(02)</h4>
                <ul className="comments_list mt-6">
                  <li className="d-sm-flex">
                    <div className="thumbnail flex-shrink-0">
                      <img
                        src={userThumb1}
                        alt="user"
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <span className="date text-danger">
                      05.03.2021[11.00am]
                    </span>
                    <div className="comments_content ms-sm-4 mt-4 mt-sm-0">
                      <h6 className="mb-3">Randy K. Melton</h6>
                      <p>
                        Personaliza distintivamente la información holística
                        mientras mejoras los procesos multidisciplinarios.
                        Facilita proactivamente asociaciones de alto impacto a
                        largo plazo a través de servicios meta de maximización
                        de recursos.
                      </p>
                      <a href="#" className="reply-btn">
                        <span className="me-1">
                          <i className="fa-solid fa-reply"></i>
                        </span>
                        Responder
                      </a>
                    </div>
                  </li>
                  <li className="d-sm-flex ms-5">
                    <div className="thumbnail flex-shrink-0">
                      <img
                        src={userThumb2}
                        alt="user"
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <span className="date text-danger">
                      05.03.2021[11.00am]
                    </span>
                    <div className="comments_content ms-sm-4 mt-4 mt-sm-0">
                      <h6 className="mb-3">Randy K. Melton</h6>
                      <p>
                        Personaliza distintivamente la información holística
                        mientras mejoras los procesos multidisciplinarios.
                        Facilita proactivamente asociaciones de alto impacto a
                        largo plazo a través de servicios meta de maximización
                        de recursos.
                      </p>
                      <a href="#" className="reply-btn">
                        <span className="me-1">
                          <i className="fa-solid fa-reply"></i>
                        </span>
                        Responder
                      </a>
                    </div>
                  </li>
                </ul>
                <hr className="my-6" />
                <div className="comment_form_wrapper">
                  <h4>Deja un Comentario</h4>
                  <p className="mb-5">
                    Tu dirección de correo electrónico no será publicada. Los
                    campos obligatorios están marcados *
                  </p>
                  <form action="#" className="comment_form">
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <div className="input-field">
                          <input type="text" placeholder="Nombre" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <input
                            type="email"
                            placeholder="Correo Electrónico"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="input-field">
                          <input type="url" placeholder="Sitio Web" />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="input-field">
                          <textarea
                            placeholder="Escribe aquí"
                            
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary mt-30">
                      Publicar Comentario
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="gshop-sidebar">
                {/* Widget de búsqueda */}
                <div className="sidebar-widget search-widget bg-white py-5 px-4">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Buscar Ahora</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <form className="search-form d-flex align-items-center mt-4">
                    <input type="text" placeholder="Buscar..." />
                    <button type="submit" className="submit-icon-btn-secondary">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </form>
                </div>

                {/* Widget de publicaciones recientes */}
                <div className="sidebar-widget search-widget bg-white pt-5 pb-4 px-4 border-top">
                  <div className="widget-title d-flex mb-3">
                    <h6 className="mb-0 flex-shrink-0">
                      Publicaciones Recientes
                    </h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <ul className="sidebar-posts">
                    {[
                      {
                        src: gallery1,
                        date: "24 de mayo, 2022",
                        title:
                          "Calidad perfecta, precio razonable para tu familia",
                      },
                      {
                        src: gallery2,
                        date: "24 de mayo, 2022",
                        title:
                          "Calidad perfecta, precio razonable para tu familia",
                      },
                      {
                        src: gallery3,
                        date: "24 de mayo, 2022",
                        title:
                          "Calidad perfecta, precio razonable para tu familia",
                      },
                      {
                        src: gallery4,
                        date: "24 de mayo, 2022",
                        title:
                          "Calidad perfecta, precio razonable para tu familia",
                      },
                    ].map((post, index) => (
                      <li
                        className="d-flex align-items-center gap-3"
                        key={index}
                      >
                        <div className="thumbnail rounded-2 overflow-hidden">
                          <a href="blog-details.html">
                            <img
                              src={post.src}
                              alt="blog thumb"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                        <div className="posts-content">
                          <span className="date d-block fw-medium fs-xs">
                            <i className="fa-regular fa-clock me-2"></i>
                            {post.date}
                          </span>
                          <a
                            href="blog-details.html"
                            className="fw-bold d-block mt-2 blog-title"
                          >
                            {post.title}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Widget de categorías */}
                <div className="sidebar-widget category-widget bg-white py-5 px-4 border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Categorías</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <ul className="widget-nav mt-4">
                    {[
                      { category: "Manzanas", count: "08" },
                      { category: "Vegetales Orgánicos", count: "09" },
                      { category: "Aguacates", count: "06" },
                      { category: "Frutas Secas y Nueces", count: "10" },
                      { category: "Café", count: "11" },
                      { category: "Bebidas de Jugo", count: "15" },
                      { category: "Pescado Fresco", count: "18" },
                    ].map((item, index) => (
                      <li key={index}>
                        <a
                          href="blog-details.html"
                          className="d-flex justify-content-between align-items-center"
                        >
                          {item.category}
                          <span className="fw-bold fs-xs total-count">
                            {item.count}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Widget de galería */}
                <div className="sidebar-widget gallery-widget bg-white py-5 px-4 border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Galería</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <ul className="widget-gallery-thumb d-flex align-items-center justify-content-center gap-3 flex-wrap mt-5">
                    {[
                      gallery1,
                      gallery2,
                      gallery3,
                      gallery4,
                      gallery5,
                      gallery6,
                    ].map((image, index) => (
                      <li
                        className="position-relative rounded-2 overflow-hidden"
                        key={index}
                      >
                        <img
                          src={`${image}`}
                          alt="gallery"
                          className="img-fluid"
                        />
                        <a href={`${index + 1}.jpg`}>
                          <i className="fa-solid fa-images"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Widget de etiquetas */}
                <div className="sidebar-widget tags-widget py-5 px-4 bg-white border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0">Etiquetas</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <div className="mt-4 d-flex gap-2 flex-wrap">
                    {[
                      "Vegetal",
                      "Saludable",
                      "Carne",
                      "Orgánico",
                      "Naturaleza",
                      "comida",
                      "comida bd",
                      "pescado",
                      "vegetal",
                    ].map((tag, index) => (
                      <a
                        href="#"
                        className="btn btn-outline btn-sm"
                        key={index}
                      >
                        {tag}
                      </a>
                    ))}
                  </div>
                </div>

                {/* Widget de archivos */}
                <div className="sidebar-widget category-widget bg-white py-5 px-4 border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Archivos</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <ul className="widget-nav mt-4">
                    {[
                      { month: "Febrero 2021", count: "08" },
                      { month: "Octubre 2021", count: "09" },
                      { month: "Abril 2020", count: "06" },
                      { month: "Marzo 2022", count: "10" },
                      { month: "Noviembre 2022", count: "11" },
                      { month: "Diciembre 2022", count: "15" },
                    ].map((item, index) => (
                      <li key={index}>
                        <a
                          href="blog-details.html"
                          className="d-flex justify-content-between align-items-center"
                        >
                          {item.month}
                          <span className="fw-bold fs-xs total-count">
                            {item.count}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Widget de productos más vendidos */}
                <div className="sidebar-widget products-widget py-5 px-4 bg-white border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Más Vendidos</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <div className="sidebar-products-list">
                    {[
                      {
                        imgSrc: pSm1,
                        title: "Ralladura de Limón Europea",
                        oldPrice: "$240.00",
                        newPrice: "$140.00",
                      },
                      {
                        imgSrc: pSm1,
                        title: "Ralladura de Limón Europea",
                        oldPrice: "$240.00",
                        newPrice: "$140.00",
                      },
                      {
                        imgSrc: pSm1,
                        title: "Ralladura de Limón Europea",
                        oldPrice: "$240.00",
                        newPrice: "$140.00",
                      },
                    ].map((product, index) => (
                      <div
                        className="horizontal-product-card d-sm-flex align-items-center bg-white rounded-2 mt-4 gap-3"
                        key={index}
                      >
                        <div className="thumbnail position-relative rounded-2">
                          <a href="product-details.html">
                            <img
                              src={product.imgSrc}
                              alt="product"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                        <div className="card-content mt-4 mt-sm-0">
                          <div className="d-flex align-items-center flex-nowrap star-rating mb-2">
                            <i className="fa-solid fa-star text-warning"></i>
                            <i className="fa-solid fa-star text-warning"></i>
                            <i className="fa-solid fa-star text-warning"></i>
                            <i className="fa-solid fa-star text-warning"></i>
                            <i className="fa-solid fa-star text-muted"></i>
                            <span className="ms-2">(4.0)</span>
                          </div>
                          <a
                            href="product-details.html"
                            className="fw-bold text-dark"
                          >
                            {product.title}
                          </a>
                          <div className="d-flex align-items-center mt-1 gap-2">
                            <span className="old-price">
                              {product.oldPrice}
                            </span>
                            <span className="new-price">
                              {product.newPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="sidebar-widget banner-widget text-center mt-4"
                  style={{ backgroundImage: `url(${bannerImage})` }}
                >
                  <span className="gshop-subtitle text-secondary">
                    Gran Venta 50%
                  </span>
                  <h3>Venta Especial</h3>
                  <div className="coupon fw-bold">
                    <span>Ingresa el código:</span>
                    <span className="coupon-code bg-danger text-white fs-xs">
                      G-shop45
                    </span>
                  </div>
                  <a
                    href="shop-grid.html"
                    className="btn btn-secondary bg-dark border-dark mt-5"
                  >
                    Comprar Ahora
                    <span className="ms-2">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { BlogDetails };
