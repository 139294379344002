import thumbsUp2 from "../../../../public/img/icons/thumbs-up2.svg";
import thumbsUp3 from "../../../../public/img/icons/thumbs-up-3.svg";
import shield from "../../../../public/img/icons/shield.svg";

function FeatureCarne() {
  return (
    <section className="hl-feature-section pb-120 position-relative z-2">
    <div className="container">
      <div className="hl-feature-area">
        <div className="row align-items-center g-4 justify-content-center">
          <div className="col-xl-4 col-lg-6">
            <div className="hl-feature-box border-r">
              <div className="icon-wrapper mb-4">
                <span>
                  <img src={thumbsUp2} alt="icono" className="img-fluid" />
                </span>
              </div>
              <h5 className="hl-heading mb-3">Entrega de Carne 100% Halal</h5>
              <p className="mb-0">Aprovecha energéticamente la tecnología progresiva para los principales catalizadores del cambio.</p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="hl-feature-box border-r">
              <div className="icon-wrapper mb-4">
                <span>
                  <img src={thumbsUp3} alt="icono" className="img-fluid" />
                </span>
              </div>
              <h5 className="hl-heading mb-3">Confiado por Más de 20k+ Clientes</h5>
              <p className="mb-0">Tenemos la solución de empaque líder en la industria de la carne Halal.</p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="hl-feature-box">
              <div className="icon-wrapper mb-4">
                <span>
                  <img src={shield} alt="icono" className="img-fluid"  />
                </span>
              </div>
              <h5 className="hl-heading mb-3">Seguro y Controlado por Temperatura</h5>
              <p className="mb-0">El paquete llega a tu puerta frío, seguro y 100% sellado. Compra los más vendidos.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export {FeatureCarne}
