import user1 from "../../../../public/img/home-5/user-1.png";
import user2 from "../../../../public/img/home-5/user-2.png";

function FeedbackCarniceria() {
  return (
    <div className="section-space-sm-y">
      <div className="section-space-sm-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-xl-6 col-xxl-5">
              <h2 className="mb-0 text-center display-6">
                Escucha a Nuestros{" "}
                <span className="meat-primary">Clientes Satisfechos</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="meat-feedback-slider-container">
              <div className="swiper meat-feedback-slider swiper-initialized swiper-horizontal swiper-backface-hidden">
                <div
                  className="swiper-wrapper"
                  id="swiper-wrapper-3f4426fbb4d310c010"
                  aria-live="off"
                  style={{
                    transform: "translate3d(-2800px, 0px, 0px)",
                    transitionDuration: "0ms",
                  }}
                >
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 3"
                    style={{ width: "536px", marginRight: "24px" }}
                  >
                    <div className="meat-feedback bg-white px-6 py-10">
                      <div className="d-flex align-items-center gap-4 mb-6">
                        <div className="w-13 h-13 rounded-circle overflow-hidden flex-shrink-0">
                          <img
                            src={user2}
                            alt="imagen"
                            className="img-fluid w-100 h-100 object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0">Peter Parker</h6>
                          <ul className="list list--row gap-1">
                            {Array(5)
                              
                              .map((_, i) => (
                                <li key={i}>
                                  <span className="d-inline-block fs-14 clr-warning">
                                    <i className="fas fa-star"></i>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <p className="mb-0">
                        ¿Por qué el tocino es tan bueno? Esa es una buena
                        pregunta. Porque lo hacemos aquí, controlamos todos los
                        aspectos, se marina naturalmente, no forzamos la
                        salmuera en el tocino, se remoja durante la noche. Es un
                        humo de madera natural en lugar de uno químico.
                      </p>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                    data-swiper-slide-index="2"
                    role="group"
                    aria-label="3 / 3"
                    style={{ width: "536px", marginRight: "24px" }}
                  >
                    <div className="meat-feedback bg-white px-6 py-10">
                      <div className="d-flex align-items-center gap-4 mb-6">
                        <div className="w-13 h-13 rounded-circle overflow-hidden flex-shrink-0">
                          <img
                            src="assets/img/home-5/user-1.png"
                            alt="imagen"
                            className="img-fluid w-100 h-100 object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0">Peter Parker</h6>
                          <ul className="list list--row gap-1">
                            {Array(5)
                              
                              .map((_, i) => (
                                <li key={i}>
                                  <span className="d-inline-block fs-14 clr-warning">
                                    <i className="fas fa-star"></i>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <p className="mb-0">
                        ¿Por qué el tocino es tan bueno? Esa es una buena
                        pregunta. Porque lo hacemos aquí, controlamos todos los
                        aspectos, se marina naturalmente, no forzamos la
                        salmuera en el tocino, se remoja durante la noche. Es un
                        humo de madera natural en lugar de uno químico.
                      </p>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate-active"
                    data-swiper-slide-index="0"
                    role="group"
                    aria-label="1 / 3"
                    style={{ width: "536px", marginRight: "24px" }}
                  >
                    <div className="meat-feedback bg-white px-6 py-10">
                      <div className="d-flex align-items-center gap-4 mb-6">
                        <div className="w-13 h-13 rounded-circle overflow-hidden flex-shrink-0">
                          <img
                            src="assets/img/home-5/user-1.png"
                            alt="imagen"
                            className="img-fluid w-100 h-100 object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0">Peter Parker</h6>
                          <ul className="list list--row gap-1">
                            {Array(5)
                              
                              .map((_, i) => (
                                <li key={i}>
                                  <span className="d-inline-block fs-14 clr-warning">
                                    <i className="fas fa-star"></i>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <p className="mb-0">
                        ¿Por qué el tocino es tan bueno? Esa es una buena
                        pregunta. Porque lo hacemos aquí, controlamos todos los
                        aspectos, se marina naturalmente, no forzamos la
                        salmuera en el tocino, se remoja durante la noche. Es un
                        humo de madera natural en lugar de uno químico.
                      </p>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate-next"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 3"
                    style={{ width: "536px", marginRight: "24px" }}
                  >
                    <div className="meat-feedback bg-white px-6 py-10">
                      <div className="d-flex align-items-center gap-4 mb-6">
                        <div className="w-13 h-13 rounded-circle overflow-hidden flex-shrink-0">
                          <img
                            src="assets/img/home-5/user-2.png"
                            alt="imagen"
                            className="img-fluid w-100 h-100 object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0">Peter Parker</h6>
                          <ul className="list list--row gap-1">
                            {Array(5)
                              
                              .map((_, i) => (
                                <li key={i}>
                                  <span className="d-inline-block fs-14 clr-warning">
                                    <i className="fas fa-star"></i>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <p className="mb-0">
                        ¿Por qué el tocino es tan bueno? Esa es una buena
                        pregunta. Porque lo hacemos aquí, controlamos todos los
                        aspectos, se marina naturalmente, no forzamos la
                        salmuera en el tocino, se remoja durante la noche. Es un
                        humo de madera natural en lugar de uno químico.
                      </p>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-prev"
                    data-swiper-slide-index="2"
                    role="group"
                    aria-label="3 / 3"
                    style={{ width: "536px", marginRight: "24px" }}
                  >
                    <div className="meat-feedback bg-white px-6 py-10">
                      <div className="d-flex align-items-center gap-4 mb-6">
                        <div className="w-13 h-13 rounded-circle overflow-hidden flex-shrink-0">
                          <img
                            src="assets/img/home-5/user-1.png"
                            alt="imagen"
                            className="img-fluid w-100 h-100 object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0">Peter Parker</h6>
                          <ul className="list list--row gap-1">
                            {Array(5)
                              
                              .map((_, i) => (
                                <li key={i}>
                                  <span className="d-inline-block fs-14 clr-warning">
                                    <i className="fas fa-star"></i>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <p className="mb-0">
                        ¿Por qué el tocino es tan bueno? Esa es una buena
                        pregunta. Porque lo hacemos aquí, controlamos todos los
                        aspectos, se marina naturalmente, no forzamos la
                        salmuera en el tocino, se remoja durante la noche. Es un
                        humo de madera natural en lugar de uno químico.
                      </p>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-active"
                    data-swiper-slide-index="0"
                    role="group"
                    aria-label="1 / 3"
                    style={{ width: "536px", marginRight: "24px" }}
                  >
                    <div className="meat-feedback bg-white px-6 py-10">
                      <div className="d-flex align-items-center gap-4 mb-6">
                        <div className="w-13 h-13 rounded-circle overflow-hidden flex-shrink-0">
                          <img
                            src={user1}
                            alt="imagen"
                            className="img-fluid w-100 h-100 object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0">Peter Parker</h6>
                          <ul className="list list--row gap-1">
                            {Array(5)
                              
                              .map((_, i) => (
                                <li key={i}>
                                  <span className="d-inline-block fs-14 clr-warning">
                                    <i className="fas fa-star"></i>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <p className="mb-0">
                        ¿Por qué el tocino es tan bueno? Esa es una buena
                        pregunta. Porque lo hacemos aquí, controlamos todos los
                        aspectos, se marina naturalmente, no forzamos la
                        salmuera en el tocino, se remoja durante la noche. Es un
                        humo de madera natural en lugar de uno químico.
                      </p>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-next"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 3"
                    style={{ width: "536px", marginRight: "24px" }}
                  >
                    <div className="meat-feedback bg-white px-6 py-10">
                      <div className="d-flex align-items-center gap-4 mb-6">
                        <div className="w-13 h-13 rounded-circle overflow-hidden flex-shrink-0">
                          <img
                            src={user2}
                            alt="imagen"
                            className="img-fluid w-100 h-100 object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0">Peter Parker</h6>
                          <ul className="list list--row gap-1">
                            {Array(5)
                              
                              .map((_, i) => (
                                <li key={i}>
                                  <span className="d-inline-block fs-14 clr-warning">
                                    <i className="fas fa-star"></i>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <p className="mb-0">
                        ¿Por qué el tocino es tan bueno? Esa es una buena
                        pregunta. Porque lo hacemos aquí, controlamos todos los
                        aspectos, se marina naturalmente, no forzamos la
                        salmuera en el tocino, se remoja durante la noche. Es un
                        humo de madera natural en lugar de uno químico.
                      </p>
                    </div>
                  </div>
                </div>
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                ></span>
                <div
                  className="swiper-button-next swiper-button-white"
                  
                  role="button"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-3f4426fbb4d310c010"
                ></div>
                <div
                  className="swiper-button-prev swiper-button-white swiper-button-disabled"
                  
                  role="button"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-3f4426fbb4d310c010"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { FeedbackCarniceria };
