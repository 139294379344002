import productBannerImg7 from "../../../../public/img/home-7/product-banner-img-7.png";
import productBannerImg6 from "../../../../public/img/home-7/product-banner-img-6.png";
import featProduct1 from "../../../../public/img/home-7/feat-product-1.png";
import featProduct2 from "../../../../public/img/home-7/feat-product-2.png";
import featProduct3 from "../../../../public/img/home-7/feat-product-3.png";
import featProduct4 from "../../../../public/img/home-7/feat-product-4.png";
import featProduct5 from "../../../../public/img/home-7/feat-product-5.png";
import featProduct6 from "../../../../public/img/home-7/feat-product-6.png";

function ProductsMueble() {
  return (
    <div className="section-space-sm-y three-bg">
      <div className="section-space-sm-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-xl-7">
              <h2 className="display-6 text-center">
                Productos de marca destacados
              </h2>
              <p className="mb-0 text-center">
                Perseguir de forma colaborativa vórtices proactivos mientras que
                las mejores prácticas granulares Colaborativamente promueven
                mercados funcionales a través de usuarios centrados en los
                procesos.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-space-sm-bottom">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-4">
              <ul className="list gap-4 gap-xxl-8">
                <li>
                  <div className="meat-card meat-card--row">
                    <div className="meat-card__img text-center">
                      <a href="#" className="link d-inline-block text-center">
                        <img
                          src={featProduct1}
                          alt="image"
                          className="img-fluid w-100 h-100 object-fit-contain"
                        />
                      </a>
                    </div>
                    <div className="meat-card__body">
                      <ul className="list list--row mb-2">
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                      </ul>
                      <h6 className="meat-card__title">
                        <a href="#" className="link meat-card__title-link">
                          {" "}
                          Bottel Gaurd (Lauki){" "}
                        </a>
                      </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 my-2">
                        <div className="d-flex align-items-center gap-3">
                          <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                            $95.00
                          </h6>
                          <h6
                            className="meat-card__current-price mb-0"
                            style={{ color: "#6db356" }}
                          >
                            $55.00
                          </h6>
                        </div>
                      </div>
                      <a
                        href="#"
                        className="link d-inline-flex text-center justify-content-center align-items-center fs-14 gap-2 clr-heading :clr-secondary"
                      >
                        <span className="d-inline-block fw-semibold">
                          {" "}
                          Comprar ahora{" "}
                        </span>
                        <span className="d-inline-block">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="meat-card meat-card--row">
                    <div className="meat-card__img text-center">
                      <a href="#" className="link d-inline-block text-center">
                        <img
                          src={featProduct2}
                          alt="image"
                          className="img-fluid w-100 h-100 object-fit-contain"
                        />
                      </a>
                    </div>
                    <div className="meat-card__body">
                      <ul className="list list--row mb-2">
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                      </ul>
                      <h6 className="meat-card__title">
                        <a href="#" className="link meat-card__title-link">
                          {" "}
                          Bottel Gaurd (Lauki){" "}
                        </a>
                      </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 my-2">
                        <div className="d-flex align-items-center gap-3">
                          <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                            $95.00
                          </h6>
                          <h6
                            className="meat-card__current-price mb-0"
                            style={{ color: "#6db356" }}
                          >
                            $55.00
                          </h6>
                        </div>
                      </div>
                      <a
                        href="#"
                        className="link d-inline-flex text-center justify-content-center align-items-center fs-14 gap-2 clr-heading :clr-secondary"
                      >
                        <span className="d-inline-block fw-semibold">
                          {" "}
                          Comprar ahora{" "}
                        </span>
                        <span className="d-inline-block">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="meat-card meat-card--row">
                    <div className="meat-card__img text-center">
                      <a href="#" className="link d-inline-block text-center">
                        <img
                          src={featProduct3}
                          alt="image"
                          className="img-fluid w-100 h-100 object-fit-contain"
                        />
                      </a>
                    </div>
                    <div className="meat-card__body">
                      <ul className="list list--row mb-2">
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                      </ul>
                      <h6 className="meat-card__title">
                        <a href="#" className="link meat-card__title-link">
                          {" "}
                          Bottel Gaurd (Lauki){" "}
                        </a>
                      </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 my-2">
                        <div className="d-flex align-items-center gap-3">
                          <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                            $95.00
                          </h6>
                          <h6
                            className="meat-card__current-price mb-0"
                            style={{ color: "#6db356" }}
                          >
                            $55.00
                          </h6>
                        </div>
                      </div>
                      <a
                        href="#"
                        className="link d-inline-flex text-center justify-content-center align-items-center fs-14 gap-2 clr-heading :clr-secondary"
                      >
                        <span className="d-inline-block fw-semibold">
                          {" "}
                          Comprar ahora{" "}
                        </span>
                        <span className="d-inline-block">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-4 order-md-3 order-xl-2">
              <div className="product-banner px-xxl-4">
                <img
                  src={productBannerImg6}
                  alt="image"
                  className="img-fluid w-100"
                />
                <div className="product-banner__overlay">
                  <span className="product-banner__subtitle">
                    UP TO{" "}
                    <span
                      className="fw-bold clr-secondary"
                      style={{ color: "#ff7c0a" }}
                    >
                      20% OFF
                    </span>
                  </span>
                  <h3 className="product-banner__title">Muebles</h3>
                  <a
                    href="#"
                    className="link button button-effect button-effect--dark"
                    style={{ background: "#ff7c0a", color: "white" }}
                  >
                    <span className="d-inline-block fw-semibold">
                      {" "}
                      Comprar ahora
                    </span>
                    <span className="d-inline-block">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 order-md-2 order-xl-3">
              <ul className="list gap-4 gap-xxl-8">
                <li>
                  <div className="meat-card meat-card--row">
                    <div className="meat-card__img text-center">
                      <a href="#" className="link d-inline-block text-center">
                        <img
                          src={featProduct4}
                          alt="image"
                          className="img-fluid w-100 h-100 object-fit-contain"
                        />
                      </a>
                    </div>
                    <div className="meat-card__body">
                      <ul className="list list--row mb-2">
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                      </ul>
                      <h6 className="meat-card__title">
                        <a href="#" className="link meat-card__title-link">
                          {" "}
                          Bottel Gaurd (Lauki){" "}
                        </a>
                      </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 my-2">
                        <div className="d-flex align-items-center gap-3">
                          <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                            $95.00
                          </h6>
                          <h6
                            className="meat-card__current-price mb-0"
                            style={{ color: "#6db356" }}
                          >
                            $55.00
                          </h6>
                        </div>
                      </div>
                      <a
                        href="#"
                        className="link d-inline-flex text-center justify-content-center align-items-center fs-14 gap-2 clr-heading :clr-secondary"
                      >
                        <span className="d-inline-block fw-semibold">
                          {" "}
                          Comprar ahora{" "}
                        </span>
                        <span className="d-inline-block">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="meat-card meat-card--row">
                    <div className="meat-card__img text-center">
                      <a href="#" className="link d-inline-block text-center">
                        <img
                          src={featProduct5}
                          alt="image"
                          className="img-fluid w-100 h-100 object-fit-contain"
                        />
                      </a>
                    </div>
                    <div className="meat-card__body">
                      <ul className="list list--row mb-2">
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                      </ul>
                      <h6 className="meat-card__title">
                        <a href="#" className="link meat-card__title-link">
                          {" "}
                          Bottel Gaurd (Lauki){" "}
                        </a>
                      </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 my-2">
                        <div className="d-flex align-items-center gap-3">
                          <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                            $95.00
                          </h6>
                          <h6
                            className="meat-card__current-price mb-0"
                            style={{ color: "#6db356" }}
                          >
                            $55.00
                          </h6>
                        </div>
                      </div>
                      <a
                        href="#"
                        className="link d-inline-flex text-center justify-content-center align-items-center fs-14 gap-2 clr-heading :clr-secondary"
                      >
                        <span className="d-inline-block fw-semibold">
                          {" "}
                          Comprar ahora{" "}
                        </span>
                        <span className="d-inline-block">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="meat-card meat-card--row">
                    <div className="meat-card__img text-center">
                      <a href="#" className="link d-inline-block text-center">
                        <img
                          src={featProduct6}
                          alt="image"
                          className="img-fluid w-100 h-100 object-fit-contain"
                        />
                      </a>
                    </div>
                    <div className="meat-card__body">
                      <ul className="list list--row mb-2">
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                        <li>
                          <span className="meat-card__star d-inline-block fs-14">
                            <i className="fas fa-star"></i>
                          </span>
                        </li>
                      </ul>
                      <h6 className="meat-card__title">
                        <a href="#" className="link meat-card__title-link">
                          {" "}
                          Bottel Gaurd (Lauki){" "}
                        </a>
                      </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 my-2">
                        <div className="d-flex align-items-center gap-3">
                          <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                            $95.00
                          </h6>
                          <h6
                            className="meat-card__current-price mb-0"
                            style={{ color: "#6db356" }}
                          >
                            $55.00
                          </h6>
                        </div>
                      </div>
                      <a
                        href="#"
                        className="link d-inline-flex text-center justify-content-center align-items-center fs-14 gap-2 clr-heading :clr-secondary"
                      >
                        <span className="d-inline-block fw-semibold">
                          {" "}
                          Comprar ahora{" "}
                        </span>
                        <span className="d-inline-block">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="product-banner">
              <img
                src={productBannerImg7}
                alt="image"
                className="img-fluid w-100"
              />
              <div className="product-banner__overlay d-flex flex-column justify-content-center">
                <h3 className="fs-32 max-text-20 fw-bolder mb-6">
                  Suscríbase a nuestro boletín para estar al día &amp; Noticias
                </h3>
                <div className="input-group newsletter newsletter--secondary ms-0">
                  <input
                    type="text"
                    className="form-control newsletter__input"
                    placeholder="Introduzca correo electrónico"
                    style={{border:"none"}}
                  />
                  <span className="input-group-text newsletter__btn" style={{ background: "#ff7c0a", color: "white" }}>
                    {" "}
                    Suscríbase ahora{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ProductsMueble };
