import blogThumbLg1 from "../../../../public/img/blog/blog-thumb-lg-1.jpg";
import blogThumbLg2 from "../../../../public/img/blog/blog-thumb-lg-2.jpg";
import blogThumbLg3 from "../../../../public/img/blog/blog-thumb-lg-3.jpg";
import blogThumbLg4 from "../../../../public/img/blog/blog-thumb-lg-4.jpg";
import { Header } from "../../Home/header/Header";
import { BreadCrumb } from "../../productsView/breadCrumb/BreadCrumb";
import gallery1 from "../../../../public/img/gallery/gallery-1.jpg";
import gallery2 from "../../../../public/img/gallery/gallery-2.jpg";
import gallery3 from "../../../../public/img/gallery/gallery-3.jpg";
import gallery4 from "../../../../public/img/gallery/gallery-4.jpg";
import gallery5 from "../../../../public/img/gallery/gallery-5.jpg";
import gallery6 from "../../../../public/img/gallery/gallery-6.jpg";
import pSm1 from "../../../../public/img/products/p-sm-1.png";
import bannerImage from '../../../../public/img/banner/banner-5.jpg';
import { Footer } from "../../Home/footer/Footer";

function BlogList() {
  return (
    <>
      <Header />
      <BreadCrumb title="Listado de blogs" pageName="Página" currentPage="Listado de blogs" />
      <section className="blog-listing-section ptb-120">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-8">
              <div className="blog-listing">
                <article className="blog-card rounded-2 overflow-hidden bg-white p-5">
                  <div className="thumbnail overflow-hidden">
                    <a href="">
                      <img
                        src={blogThumbLg1}
                        alt="thumbnail del blog"
                        className="img-fluid rounded-top"
                      />
                    </a>
                  </div>
                  <div className="blog-card-content p-0 mt-4">
                    <div className="blog-meta d-flex align-items-center gap-3 mb-2">
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-tags me-1"></i>Vegetal
                        Orgánico
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                        2022
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-comment me-1"></i>24 de mayo,
                        2022
                      </span>
                    </div>
                    <a href="blog-details.html">
                      <h3 className="mb-3">
                        Entrega a Domicilio: Carne y Mariscos Frescos y
                        Saludables Recientemente
                      </h3>
                    </a>
                    <p className="mb-0 mb-5">
                      Comunica globalmente la información empresarial para
                      catalizadores distintivos del cambio. Procrastina
                      dramáticamente fuentes revolucionarias sin un enlace total
                      económico.
                    </p>
                    <a
                      href="blog-details.html"
                      className="btn btn-outline-primary btn-md"
                    >
                      Explorar Más
                      <span className="ms-2">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </article>
                <article className="blog-card rounded-2 overflow-hidden bg-white p-5">
                  <div className="thumbnail overflow-hidden">
                    <a href="blog-details.html">
                      <img
                        src={blogThumbLg2}
                        alt="thumbnail del blog"
                        className="img-fluid rounded-top"
                      />
                    </a>
                  </div>
                  <div className="blog-card-content p-0 mt-4">
                    <div className="blog-meta d-flex align-items-center gap-3 mb-2">
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-tags me-1"></i>Vegetal
                        Orgánico
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                        2022
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-comment me-1"></i>24 de mayo,
                        2022
                      </span>
                    </div>
                    <a href="blog-details.html">
                      <h3 className="mb-3">
                        Entrega a Domicilio: Carne y Mariscos Frescos y
                        Saludables Recientemente
                      </h3>
                    </a>
                    <p className="mb-0 mb-5">
                      Comunica globalmente la información empresarial para
                      catalizadores distintivos del cambio. Procrastina
                      dramáticamente fuentes revolucionarias sin un enlace total
                      económico.
                    </p>
                    <a
                      href="blog-details.html"
                      className="btn btn-outline-primary btn-md"
                    >
                      Explorar Más
                      <span className="ms-2">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </article>
                <article className="blog-card rounded-2 overflow-hidden bg-white p-5">
                  <div className="thumbnail overflow-hidden">
                    <a href="blog-details.html">
                      <img
                        src={blogThumbLg3}
                        alt="thumbnail del blog"
                        className="img-fluid rounded-top"
                      />
                    </a>
                  </div>
                  <div className="blog-card-content p-0 mt-4">
                    <div className="blog-meta d-flex align-items-center gap-3 mb-2">
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-tags me-1"></i>Vegetal
                        Orgánico
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                        2022
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-comment me-1"></i>24 de mayo,
                        2022
                      </span>
                    </div>
                    <a href="blog-details.html">
                      <h3 className="mb-3">
                        Entrega a Domicilio: Carne y Mariscos Frescos y
                        Saludables Recientemente
                      </h3>
                    </a>
                    <p className="mb-0 mb-5">
                      Comunica globalmente la información empresarial para
                      catalizadores distintivos del cambio. Procrastina
                      dramáticamente fuentes revolucionarias sin un enlace total
                      económico.
                    </p>
                    <a
                      href="blog-details.html"
                      className="btn btn-outline-primary btn-md"
                    >
                      Explorar Más
                      <span className="ms-2">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </article>
                <article className="blog-card rounded-2 overflow-hidden bg-white p-5">
                  <div className="thumbnail overflow-hidden">
                    <a href="blog-details.html">
                      <img
                        src={blogThumbLg4}
                        alt="thumbnail del blog"
                        className="img-fluid rounded-top"
                      />
                    </a>
                  </div>
                  <div className="blog-card-content p-0 mt-4">
                    <div className="blog-meta d-flex align-items-center gap-3 mb-2">
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-tags me-1"></i>Vegetal
                        Orgánico
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                        2022
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-comment me-1"></i>24 de mayo,
                        2022
                      </span>
                    </div>
                    <a href="blog-details.html">
                      <h3 className="mb-3">
                        Entrega a Domicilio: Carne y Mariscos Frescos y
                        Saludables Recientemente
                      </h3>
                    </a>
                    <p className="mb-0 mb-5">
                      Comunica globalmente la información empresarial para
                      catalizadores distintivos del cambio. Procrastina
                      dramáticamente fuentes revolucionarias sin un enlace total
                      económico.
                    </p>
                    <a
                      href="blog-details.html"
                      className="btn btn-outline-primary btn-md"
                    >
                      Explorar Más
                      <span className="ms-2">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </article>
                <article className="blog-card rounded-2 overflow-hidden bg-white p-5">
                  <div className="thumbnail overflow-hidden">
                    <a href="blog-details.html">
                      <img
                        src={blogThumbLg1}
                        alt="thumbnail del blog"
                        className="img-fluid rounded-top"
                      />
                    </a>
                  </div>
                  <div className="blog-card-content p-0 mt-4">
                    <div className="blog-meta d-flex align-items-center gap-3 mb-2">
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-tags me-1"></i>Vegetal
                        Orgánico
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-regular fa-clock me-1"></i>24 de mayo,
                        2022
                      </span>
                      <span className="fs-xs fw-medium">
                        <i className="fa-solid fa-comment me-1"></i>24 de mayo,
                        2022
                      </span>
                    </div>
                    <a href="blog-details.html">
                      <h3 className="mb-3">
                        Entrega a Domicilio: Carne y Mariscos Frescos y
                        Saludables Recientemente
                      </h3>
                    </a>
                    <p className="mb-0 mb-5">
                      Comunica globalmente la información empresarial para
                      catalizadores distintivos del cambio. Procrastina
                      dramáticamente fuentes revolucionarias sin un enlace total
                      económico.
                    </p>
                    <a
                      href="blog-details.html"
                      className="btn btn-outline-primary btn-md"
                    >
                      Explorar Más
                      <span className="ms-2">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </article>
                <ul className="template-pagination d-flex gap-2 mt-5">
                  <li>
                    <a href="#" className="active">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fas fa-arrow-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="gshop-sidebar">
                {/* Widget de búsqueda */}
                <div className="sidebar-widget search-widget bg-white py-5 px-4">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Buscar Ahora</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <form className="search-form d-flex align-items-center mt-4">
                    <input type="text" placeholder="Buscar..." />
                    <button type="submit" className="submit-icon-btn-secondary">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </form>
                </div>

                {/* Widget de publicaciones recientes */}
                <div className="sidebar-widget search-widget bg-white pt-5 pb-4 px-4 border-top">
                  <div className="widget-title d-flex mb-3">
                    <h6 className="mb-0 flex-shrink-0">
                      Publicaciones Recientes
                    </h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <ul className="sidebar-posts">
                    {[
                      {
                        src: gallery1,
                        date: "24 de mayo, 2022",
                        title:
                          "Calidad perfecta, precio razonable para tu familia",
                      },
                      {
                        src: gallery2,
                        date: "24 de mayo, 2022",
                        title:
                          "Calidad perfecta, precio razonable para tu familia",
                      },
                      {
                        src: gallery3,
                        date: "24 de mayo, 2022",
                        title:
                          "Calidad perfecta, precio razonable para tu familia",
                      },
                      {
                        src: gallery4,
                        date: "24 de mayo, 2022",
                        title:
                          "Calidad perfecta, precio razonable para tu familia",
                      },
                    ].map((post, index) => (
                      <li
                        className="d-flex align-items-center gap-3"
                        key={index}
                      >
                        <div className="thumbnail rounded-2 overflow-hidden">
                          <a href="blog-details.html">
                            <img
                              src={post.src}
                              alt="blog thumb"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                        <div className="posts-content">
                          <span className="date d-block fw-medium fs-xs">
                            <i className="fa-regular fa-clock me-2"></i>
                            {post.date}
                          </span>
                          <a
                            href="blog-details.html"
                            className="fw-bold d-block mt-2 blog-title"
                          >
                            {post.title}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Widget de categorías */}
                <div className="sidebar-widget category-widget bg-white py-5 px-4 border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Categorías</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <ul className="widget-nav mt-4">
                    {[
                      { category: "Manzanas", count: "08" },
                      { category: "Vegetales Orgánicos", count: "09" },
                      { category: "Aguacates", count: "06" },
                      { category: "Frutas Secas y Nueces", count: "10" },
                      { category: "Café", count: "11" },
                      { category: "Bebidas de Jugo", count: "15" },
                      { category: "Pescado Fresco", count: "18" },
                    ].map((item, index) => (
                      <li key={index}>
                        <a
                          href="blog-details.html"
                          className="d-flex justify-content-between align-items-center"
                        >
                          {item.category}
                          <span className="fw-bold fs-xs total-count">
                            {item.count}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Widget de galería */}
                <div className="sidebar-widget gallery-widget bg-white py-5 px-4 border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Galería</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <ul className="widget-gallery-thumb d-flex align-items-center justify-content-center gap-3 flex-wrap mt-5">
                    {[
                      gallery1,
                      gallery2,
                      gallery3,
                      gallery4,
                      gallery5,
                      gallery6,
                    ].map((image, index) => (
                      <li
                        className="position-relative rounded-2 overflow-hidden"
                        key={index}
                      >
                        <img
                          src={`${image}`}
                          alt="gallery"
                          className="img-fluid"
                        />
                        <a
                          href={`${index + 1}.jpg`}
                        >
                          <i className="fa-solid fa-images"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Widget de etiquetas */}
                <div className="sidebar-widget tags-widget py-5 px-4 bg-white border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0">Etiquetas</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <div className="mt-4 d-flex gap-2 flex-wrap">
                    {[
                      "Vegetal",
                      "Saludable",
                      "Carne",
                      "Orgánico",
                      "Naturaleza",
                      "comida",
                      "comida bd",
                      "pescado",
                      "vegetal",
                    ].map((tag, index) => (
                      <a
                        href="#"
                        className="btn btn-outline btn-sm"
                        key={index}
                      >
                        {tag}
                      </a>
                    ))}
                  </div>
                </div>

                {/* Widget de archivos */}
                <div className="sidebar-widget category-widget bg-white py-5 px-4 border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Archivos</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <ul className="widget-nav mt-4">
                    {[
                      { month: "Febrero 2021", count: "08" },
                      { month: "Octubre 2021", count: "09" },
                      { month: "Abril 2020", count: "06" },
                      { month: "Marzo 2022", count: "10" },
                      { month: "Noviembre 2022", count: "11" },
                      { month: "Diciembre 2022", count: "15" },
                    ].map((item, index) => (
                      <li key={index}>
                        <a
                          href="blog-details.html"
                          className="d-flex justify-content-between align-items-center"
                        >
                          {item.month}
                          <span className="fw-bold fs-xs total-count">
                            {item.count}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Widget de productos más vendidos */}
                <div className="sidebar-widget products-widget py-5 px-4 bg-white border-top">
                  <div className="widget-title d-flex">
                    <h6 className="mb-0 flex-shrink-0">Más Vendidos</h6>
                    <span className="hr-line w-100 position-relative d-block align-self-end ms-1"></span>
                  </div>
                  <div className="sidebar-products-list">
                    {[
                      {
                        imgSrc: pSm1,
                        title: "Ralladura de Limón Europea",
                        oldPrice: "$240.00",
                        newPrice: "$140.00",
                      },
                      {
                        imgSrc: pSm1,
                        title: "Ralladura de Limón Europea",
                        oldPrice: "$240.00",
                        newPrice: "$140.00",
                      },
                      {
                        imgSrc: pSm1,
                        title: "Ralladura de Limón Europea",
                        oldPrice: "$240.00",
                        newPrice: "$140.00",
                      },
                    ].map((product, index) => (
                      <div
                        className="horizontal-product-card d-sm-flex align-items-center bg-white rounded-2 mt-4 gap-3"
                        key={index}
                      >
                        <div className="thumbnail position-relative rounded-2">
                          <a href="product-details.html">
                            <img
                              src={product.imgSrc}
                              alt="product"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                        <div className="card-content mt-4 mt-sm-0">
                          <div className="d-flex align-items-center flex-nowrap star-rating mb-2">
                            <i className="fa-solid fa-star text-warning"></i>
                            <i className="fa-solid fa-star text-warning"></i>
                            <i className="fa-solid fa-star text-warning"></i>
                            <i className="fa-solid fa-star text-warning"></i>
                            <i className="fa-solid fa-star text-muted"></i>
                            <span className="ms-2">(4.0)</span>
                          </div>
                          <a
                            href="product-details.html"
                            className="fw-bold text-dark"
                          >
                            {product.title}
                          </a>
                          <div className="d-flex align-items-center mt-1 gap-2">
                            <span className="old-price">
                              {product.oldPrice}
                            </span>
                            <span className="new-price">
                              {product.newPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
      className="sidebar-widget banner-widget text-center mt-4"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <span className="gshop-subtitle text-secondary">Gran Venta 50%</span>
      <h3>Venta Especial</h3>
      <div className="coupon fw-bold">
        <span>Ingresa el código:</span>
        <span className="coupon-code bg-danger text-white fs-xs">G-shop45</span>
      </div>
      <a href="shop-grid.html" className="btn btn-secondary bg-dark border-dark mt-5">
        Comprar Ahora
        <span className="ms-2">
          <i className="fas fa-arrow-right"></i>
        </span>
      </a>
    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { BlogList };
