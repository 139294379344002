import product1 from "../../../../public/img/home-4/product-1.png";
import product2 from "../../../../public/img/home-4/product-2.png";

function FilterCarne() {
  return (
    <section className="hl-filter-area pt-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div className="text-center">
                            <span className="hl-subtitle fw-bold d-inline-block">Lo mejor para usted</span>
                            <h2 className="mb-8 mt-2">Los más vendidos</h2>
                        </div>
                    </div>
                </div>
                <div className="hl-filter">
                    <ul className="nav nav-tabs border-bottom-0" role="tablist">
                        <li><a href="#beef" data-bs-toggle="tab" className="active" aria-selected="true" role="tab">Carne de vacuno</a></li>
                        <li><a href="#chicken" data-bs-toggle="tab" aria-selected="false" role="tab">Pollo Halal</a></li>
                        <li><a href="#goat" data-bs-toggle="tab" aria-selected="false"  role="tab">Carne de cabra</a></li>
                        <li><a href="#ship" data-bs-toggle="tab" aria-selected="false"  role="tab">Carne de barco</a></li>
                        <li><a href="#deli" data-bs-toggle="tab" aria-selected="false"  role="tab">Deli Halal</a></li>
                    </ul>
                    <div className="tab-content mt-6 position-relative">
                        <div className="tab-pane fade show active" id="beef" role="tabpanel">
                            <button type="button" className="hl-product-slider-prev hl-product-slider-btn"  aria-label="Previous slide" aria-controls="swiper-wrapper-21ab8216d53bfda5" aria-disabled="false"><i className="fas fa-arrow-left"></i></button>
                            <button type="button" className="hl-product-slider-next hl-product-slider-btn"  aria-label="Next slide" aria-controls="swiper-wrapper-21ab8216d53bfda5" aria-disabled="false"><i className="fas fa-arrow-right"></i></button>
                            <div className="swiper hl-products-slider pb-4 swiper-initialized swiper-horizontal swiper-backface-hidden">
                                <div className="swiper-wrapper" id="swiper-wrapper-9757215f24945146" aria-live="polite" style={{transform: "translate3d(0px, 0px, 0px)"}}>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative swiper-slide-active" role="group" aria-label="1 / 5" style={{width: "312px", marginRight: "24px"}}>
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative swiper-slide-next" role="group" aria-label="2 / 5" style={{width: "312px", marginRight: "24px"}}>
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product2} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative" role="group" aria-label="3 / 5" style={{width: "312px", marginRight: "24px"}}>
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative" role="group" aria-label="4 / 5" style={{width: "312px", marginRight: "24px"}}>
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative" role="group" aria-label="5 / 5" style={{width: "312px", marginRight: "24px"}}>
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                        </div>
                        <div className="tab-pane fade" id="chicken" role="tabpanel">
                            <button type="button" className="hl-product-slider-prev hl-product-slider-btn" aria-label="Previous slide" aria-controls="swiper-wrapper-21ab8216d53bfda5" aria-disabled="false"><i className="fas fa-arrow-left"></i></button>
                            <button type="button" className="hl-product-slider-next hl-product-slider-btn" aria-label="Next slide" aria-controls="swiper-wrapper-21ab8216d53bfda5" aria-disabled="false"><i className="fas fa-arrow-right"></i></button>
                            <div className="swiper hl-products-slider pb-4 swiper-initialized swiper-horizontal">
                                <div className="swiper-wrapper" id="swiper-wrapper-21ab8216d53bfda5" aria-live="polite" style={{transitionDuration: "0ms"}}>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="swiper-slide hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                        </div>
                        <div className="tab-pane fade" id="goat" role="tabpanel">
                            <div className="row g-4">
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ship" role="tabpanel">
                            <div className="row g-4">
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="deli" role="tabpanel">
                            <div className="row g-4">
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="hl-collection-single bg-white rounded position-relative">
                                        <div className="feature-image text-center">
                                            <a href="#"><img src={product1} alt="product" className="img-fluid"/></a>
                                        </div>
                                        <div>
                                            <span className="hlp-tagline text-danger fs-xs fw-bold d-block mb-1">100% Halal 
                                            Carne de vacuno</span>
                                            <a href="#" className="product-title">Pizzas Halal de Ternera y Pepperoni &amp; Calzones</a>
                                            <div className="d-flex align-items-center gap-1 mt-2">
                                                <ul className="rating-star fs-xs d-inline-flex align-items-center text-warning">
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span className="fs-xs">25 Reseñas</span>
                                            </div>
                                            <div className="d-flex align-items-center mt-3">
                                                <del className="fw-bold me-2">$95.00</del>
                                                <span className="text-danger fw-bold">$55.00</span>
                                            </div>
                                        </div>
                                        <a href="#" className="cart-btn">
                                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86446 6.31254H3.42525C2.43074 6.31254 1.60525 7.08117 1.53429 8.0735L0.605877 21.0735C0.568502 21.5984 0.750494 22.1151 1.10908 22.5003C1.4682 22.8854 1.97033 23.1042 2.49683 23.1042H17.5032C18.0297 23.1042 18.5318 22.8854 18.8909 22.5003C19.2495 22.1151 19.4315 21.5984 19.3941 21.0735L18.4657 8.0735C18.3947 7.08117 17.5692 6.31254 16.5747 6.31254H15.1458V6.04171C15.1458 3.19958 12.8421 0.895874 10 0.895874C7.26187 0.895874 4.7312 3.07662 4.85416 6.04171C4.85795 6.13162 4.86121 6.22208 4.86446 6.31254ZM15.1458 7.93754V12C15.1458 12.4485 14.7818 12.8125 14.3333 12.8125C13.8848 12.8125 13.5208 12.4485 13.5208 12V7.93754H6.47916V12C6.47916 12.4485 6.11516 12.8125 5.66666 12.8125C5.21816 12.8125 4.85416 12.4485 4.85416 12C4.85416 12 4.92458 10.1015 4.90129 7.93754H3.42525C3.28333 7.93754 3.16525 8.04749 3.1555 8.18887L2.22653 21.1889C2.22111 21.2642 2.24713 21.3378 2.29859 21.3931C2.35004 21.4478 2.42154 21.4792 2.49683 21.4792H17.5032C17.5785 21.4792 17.65 21.4478 17.7014 21.3931C17.7529 21.3378 17.7789 21.2642 17.7735 21.1889L16.8445 8.18887C16.8347 8.04749 16.7167 7.93754 16.5747 7.93754H15.1458ZM13.5208 6.31254V6.04171C13.5208 4.09712 11.9446 2.52087 10 2.52087C8.05541 2.52087 6.47916 4.09712 6.47916 6.04171V6.31254H13.5208Z" fill="white"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export  {FilterCarne}
