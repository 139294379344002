import a from "../../../../public/img/home-5/products/1.png";
import c from "../../../../public/img/home-5/products/3.png";
import d from "../../../../public/img/home-5/products/4.png";
import e from "../../../../public/img/home-5/products/5.png";
import f from "../../../../public/img/home-5/products/6.png";
import g from "../../../../public/img/home-5/products/7.png";

function OnSaleCarniceria() {
  return (
    <div className="section-space-sm-y">
      <div className="container">
        <div className="row g-4">
          <div className="col-xl-4">
            <div className="on-sale-banner">
              <span className="d-inline-block meat-primary fs-14 fw-bold mb-2">
                {" "}
                Carne de primera natural{" "}
              </span>
              <h2 className="display-6 clr-white mb-8">
                Carne fresca Bistec Perfect Done
              </h2>
              <a
                href="#"
                className="meat-category-card__btn animated-btn-icon bg-primary-clr clr-white"
              >
                <span className="meat-category-card__btn-text fw-medium">
                  {" "}
                  Comprar ahora{" "}
                </span>
                <span className="meat-category-card__btn-icon">
                  <i className="fas fa-arrow-right-long"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="box-shadow rounded-1 section-space-sm-y px-4 px-lg-6 px-xl-8 bg-white">
              <div className="section-space-xsm-bottom">
                <div className="row g-4 align-items-md-center">
                  <div className="col-md-8">
                    <h2 className="mb-0 display-6">Productos en venta</h2>
                  </div>
                  <div className="col-md-4">
                    <div className="on-sale-slider__nav justify-content-md-end">
                      <div
                        className="swiper-button-prev on-sale-slider__nav-btn on-sale-slider__nav-btn-prev"
                        role="button"
                        aria-label="Previous slide"
                        aria-controls="swiper-wrapper-7aae97e949670d25"
                      ></div>
                      <div
                        className="swiper-button-next on-sale-slider__nav-btn on-sale-slider__nav-btn-next"
                        role="button"
                        aria-label="Next slide"
                        aria-controls="swiper-wrapper-7aae97e949670d25"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-0">
                <div className="col-12">
                  <div className="swiper on-sale-slider swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div
                      className="swiper-wrapper"
                      id="swiper-wrapper-7aae97e949670d25"
                      aria-live="polite"
                      style={{
                        transform: "translate3d(-824px, 0px, 0px);",
                        transitionDuration: "0ms",
                      }}
                    >
                      <div
                        className="swiper-slide swiper-slide-duplicate swiper-slide-prev"
                        data-swiper-slide-index="2"
                        role="group"
                        aria-label="3 / 3"
                        style={{ width: "808px;", marginRight: "16px" }}
                      >
                        <div className="on-sale-slider__item">
                          <div className="row g-4">
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src={g}
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Carne de vacuno{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Cordero &amp; Tocino de lomo de cordero
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0" style={{color:"#ec092c"}}>
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src={d}
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Carne de vacuno{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Chuletón de Cabra Envejecido
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0" style={{color:"#ec092c"}}>
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src={c}
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Carne de vacuno{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Envejecido Bistec de Vacuno
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0" style={{color:"#ec092c"}}>
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src={e}
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Carne de vacuno{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Cordero &amp; Tocino de lomo de cordero
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0" style={{color:"#ec092c"}}>
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src={f}
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Carne de vacuno{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Cordero &amp; Tocino de lomo de cordero
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0" style={{color:"#ec092c"}}>
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src={a}
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Carne de vacuno{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Tocino de lomo de cordero
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-active"
                        data-swiper-slide-index="0"
                        role="group"
                        aria-label="1 / 3"
                        style={{ width: "808px", marginRight: "16px" }}
                      >
                        <div className="on-sale-slider__item">
                          <div className="row g-4">
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/7.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/4.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Aged Goat Steak Beef
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/3.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Aged Beef Steak Beef
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/5.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/6.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/1.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-next"
                        data-swiper-slide-index="1"
                        role="group"
                        aria-label="2 / 3"
                        style={{ width: "808px", marginRight: "16px" }}
                      >
                        <div className="on-sale-slider__item">
                          <div className="row g-4">
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/7.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/4.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Aged Goat Steak Beef
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/3.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Aged Beef Steak Beef
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/5.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/6.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/1.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate-prev"
                        data-swiper-slide-index="2"
                        role="group"
                        aria-label="3 / 3"
                        style={{ width: "808px", marginRight: "16px" }}
                      >
                        <div className="on-sale-slider__item">
                          <div className="row g-4">
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/7.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/4.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Aged Goat Steak Beef
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/3.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Aged Beef Steak Beef
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/5.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/6.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/1.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                        data-swiper-slide-index="0"
                        role="group"
                        aria-label="1 / 3"
                        style={{ width: "808px", marginRight: "16px" }}
                      >
                        <div className="on-sale-slider__item">
                          <div className="row g-4">
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/7.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/4.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Aged Goat Steak Beef
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/3.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Aged Beef Steak Beef
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/5.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/6.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Beef Meat{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Lamb &amp; Mutton Back Bacon
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="meat-card meat-card--row">
                                <div className="meat-card__img text-center">
                                  <a
                                    href="#"
                                    className="link d-inline-block text-center"
                                  >
                                    <img
                                      src="assets/img/home-5/products/1.png"
                                      alt="image"
                                      className="img-fluid w-100 h-100 object-fit-contain"
                                    />
                                  </a>
                                </div>
                                <div className="meat-card__body">
                                  <span className="meat-card__subtitle">
                                    {" "}
                                    Carne de vacuno{" "}
                                  </span>
                                  <h6 className="meat-card__title">
                                    <a
                                      href="#"
                                      className="link meat-card__title-link"
                                    >
                                      Cordero &amp; Tocino de lomo de cordero
                                    </a>
                                  </h6>
                                  <ul className="list list--row">
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="meat-card__star d-inline-block">
                                        <i className="fas fa-star"></i>
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mt-2">
                                    <div className="d-flex align-items-center gap-3">
                                      <h6 className="meat-card__pre-price text-decoration-line-through mb-0">
                                        $95.00
                                      </h6>
                                      <h6 className="meat-card__current-price mb-0">
                                        $55.00
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span
                      className="swiper-notification"
                      aria-live="assertive"
                      aria-atomic="true"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export  {OnSaleCarniceria};
