
function Footer() {
  return (
    <>
    <div className="footer-curve position-relative overflow-hidden">
      <span
        className="position-absolute section-curve-wrapper top-0 h-100"
        style={{ backgroundImage: 'url("img/shapes/section-curve.png")' }}
      ></span>
    </div>

    <footer style={{background: "#191d28"}} className="gshop-footer position-relative pt-8  z-1 overflow-hidden" >
      <img src="img/shapes/tomato.svg" alt="tomato" className="position-absolute z--1 tomato vector-shape" />
      <img src="img/shapes/pata-lg.svg" alt="pata" className="position-absolute z--1 pata-lg vector-shape" />
      <img src="img/shapes/pata-xs.svg" alt="pata" className="position-absolute z--1 pata-xs vector-shape" />
      <img src="img/shapes/frame-circle.svg" alt="frame" className="position-absolute z--1 frame-circle vector-shape" />
      <img src="img/shapes/leaf.svg" alt="leaf" className="position-absolute z--1 leaf vector-shape" />
      <img src="img/shapes/leaf.svg" alt="leaf" className="position-absolute leaf-2 z--1 vector-shape" />
      <img src="img/shapes/pata-xs.svg" alt="pata" className="position-absolute pata-xs-2 z--1 vector-shape" />
      <img src="img/shapes/tomato-slice.svg" alt="tomato slice" className="position-absolute tomato-slice vector-shape z--1" />
      <img src="img/shapes/tomato-half.svg" alt="tomato" className="position-absolute tomato-half z--1 vector-shape" />
      <div className="container" >
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6">
            <div className="gshop_subscribe_form text-center">
              <h4 className="text-white gshop-title">
              Suscríbete a G-Shop{' '}
                <mark className="p-0 position-relative text-secondary bg-transparent">
                Los recién llegados <img src="img/shapes/border-line.svg" alt="border line" className="position-absolute border-line" />
                </mark>
                <br className="d-none d-sm-block" />
                & Otra información.
              </h4>
              <form className="mt-5 d-flex align-items-center bg-white rounded subscribe_form">
                <input type="email" className="form-control" placeholder="Correo electrónico" />
                <button type="submit" className="btn btn-primary flex-shrink-0">Suscríbase ahora</button>
              </form>
            </div>
          </div>
        </div>
        <span className="gradient-spacer my-8 d-block"></span>
        <div className="row g-5">
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div className="footer-widget">
              <h5 className="text-white mb-4">Categoría</h5>
              <ul className="footer-nav">
                <li><a href="#">Testimonios</a></li>
                <li><a href="#">Contacto</a></li>
                <li><a href="#">Ubicación &amp; Horas Laborales</a></li>
                <li><a href="#">Nuestra garantia</a></li>
                <li><a href="#">Rastrea tu orden</a></li>
                <li><a href="#">Página de ayuda</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div className="footer-widget">
              <h5 className="text-white mb-4">Enlaces rápidos</h5>
              <ul className="footer-nav">
                <li><a href="#">Tu Cuenta</a></li>
                <li><a href="#">Devoluciones e Intercambios</a></li>
                <li><a href="#">Centro de Devoluciones</a></li>
                <li><a href="#">Historial de Compras</a></li>
                <li><a href="#">Últimas Noticias del Blog</a></li>
                <li><a href="#">Envío y Entrega</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div className="footer-widget">
              <h5 className="text-white mb-4">Contacta con nosotros</h5>
              <ul className="footer-nav">
                <li><a href="#">Testimonios</a></li>
                <li><a href="#">Contacto</a></li>
                <li><a href="#">Ubicación &amp; Horas Laborales</a></li>
                <li><a href="#">Nuestra garantia</a></li>
                <li><a href="#">Rastrea tu orden</a></li>
                <li><a href="#">Página de ayuda</a></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div className="footer-widget">
              <h5 className="text-white mb-4">Atención al cliente</h5>
              <ul className="footer-nav">
                <li><a href="#">Tu Cuenta</a></li>
                <li><a href="#">Devoluciones e Intercambios</a></li>
                <li><a href="#">Centro de Devoluciones</a></li>
                <li><a href="#">Historial de Compras</a></li>
                <li><a href="#">Últimas Noticias del Blog</a></li>
                <li><a href="#">Envío y Entrega</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright pt-120 pb-3">
        <span className="gradient-spacer d-block mb-3"></span>
        <div className="container">
          <div className="row align-items-center g-3">
            <div className="col-lg-4">
              <div className="copyright-text">
                <p className="mb-0 text-white">
                  © Todos los derechos reservados Hecho por <a href="#" className="text-secondary">JoseJorgeST</a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <div className="logo-wrapper text-center">
                <a href="index.html" className="logo"><img src="img/logo-white.png" alt="logo" className="img-fluid" /></a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-payments-info d-flex align-items-center justify-content-lg-end gap-2">
                <div className="supported-payment-box rounded-1 bg-dark-light d-inline-flex align-items-center justify-content-center p-2 flex-shrink-0">
                  <img src="img/brands/visa.png" alt="visa" className="img-fluid" />
                </div>
                <div className="supported-payment-box rounded-1 bg-dark-light d-inline-flex align-items-center justify-content-center p-2 flex-shrink-0">
                  <img src="img/brands/mastercard.png" alt="visa" className="img-fluid" />
                </div>
                <div className="supported-payment-box rounded-1 bg-dark-light d-inline-flex align-items-center justify-content-center p-2 flex-shrink-0">
                  <img src="img/brands/payoneer.png" alt="visa" className="img-fluid" />
                </div>
                <div className="supported-payment-box rounded-1 bg-dark-light d-inline-flex align-items-center justify-content-center p-2 flex-shrink-0">
                  <img src="img/brands/paypal.png" alt="visa" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    </>
    
  )
}

export  {Footer}