import { Footer } from "../Home/footer/Footer"
import { Header } from "../Home/header/Header"
import {AboutCarne} from "./aboutCarne/AboutCarne"
import { BannerCarne } from "./bannerCarne/BannerCarne"
import { CategoryCarne } from "./categoryCarne/CategoryCarne"
import { CounterCarne } from "./counterCarne/CounterCarne"
import { FeatureCarne } from "./featureCarne/FeatureCarne"
import FeedbackCarne from "./feedbackCarne/FeedbackCarne"
import { FilterCarne } from "./filterCarne/FilterCarne"
import { LocationCarne } from "./locationCarne/LocationCarne"
import { ProductCarne } from "./productCarne/ProductCarne"
import { SliderCarne } from "./sliderCarne/SliderCarne"
import { WcsSection } from "./wcsSectionCarne/WcsSection"




function HomeCarne() {
  return (
    <div>
      <Header />
      <SliderCarne />
      <FeatureCarne />
      <CategoryCarne />
      <AboutCarne />
      <CounterCarne />
      <FilterCarne />
      <BannerCarne />
      <WcsSection />
      <ProductCarne />
      <FeedbackCarne/>
      <LocationCarne />
      <Footer />
    </div>
  )
}

export {HomeCarne}
