import { Header } from "../../components/Home/header/Header";
import { BreadCrumb } from "../../components/productsView/breadCrumb/BreadCrumb";
import bgShape4 from "../../../public/img/shapes/bg-shape-4.png";
import cartColor from "../../../public/img/icons/cart-color.svg";
import cartWhite from "../../../public/img/icons/cart-white.svg";
import organicColor from "../../../public/img/icons/organic-color.svg";
import organicWhite from "../../../public/img/icons/organic-white.svg";
import milkColor from "../../../public/img/icons/milk-color.svg";
import milkWhite from "../../../public/img/icons/milk-white.svg";
import deliveryColor from "../../../public/img/icons/delivery-color.svg";
import deliveryWhite from "../../../public/img/icons/delivery-white.svg";
import bgShape5 from "../../../public/img/shapes/bg-shape-5.png";
import rollColor from "../../../public/img/shapes/roll-color.png";
import rollColorCurve from "../../../public/img/shapes/roll-color-curve.png";
import onionColor from "../../../public/img/shapes/onion-color.png";
import icon1 from "../../../public/img/icons/icon-1.png";
import icon2 from "../../../public/img/icons/icon-2.png";
import icon3 from "../../../public/img/icons/icon-3.png";
import icon4 from "../../../public/img/icons/icon-4.png";
import React, { useState } from "react";
import frameCircle from "../../../public/img/shapes/frame-circle.svg";
import leaf from "../../../public/img/shapes/leaf.svg";
import cauliflower from "../../../public/img/shapes/cauliflower.png";
import girl from "../../../public/img/about/girl.png";
import { Footer } from "../../components/Home/footer/Footer";



interface CounterProps {
    end: number;
  }
  
  
  const Counter: React.FC<CounterProps> = ({ end }) => {
      const [count, setCount] = React.useState(0);
    
      React.useEffect(() => {
        let start = 0;
        const endNumber = end;
        const duration = 2000; // Duración de la animación en milisegundos
        const stepTime = Math.abs(Math.floor(duration / endNumber));
        
        const timer = setInterval(() => {
          start += 1;
          setCount(start);
          if (start === endNumber) clearInterval(timer);
        }, stepTime);
    
        return () => clearInterval(timer);
      }, [end]);
    
      return <h3 className="mb-1">{count}k+</h3>;
    };

    const accordionData = [
      {
        title: '¿Por qué son necesarios los alimentos orgánicos para la vida?',
        content: 'Permitir sinérgicamente información robusta antes del liderazgo empresarial. Perseguir metodologías mundiales sin materiales oportunos y fabricar relaciones internas covalentes.'
      },
      {
        title: '¿Por qué son necesarios los alimentos orgánicos para la vida?',
        content: 'Permitir sinérgicamente información robusta antes del liderazgo empresarial. Perseguir metodologías mundiales sin materiales oportunos y fabricar relaciones internas covalentes.'
      },
      {
        title: '¿Por qué son necesarios los alimentos orgánicos para la vida?',
        content: 'Permitir sinérgicamente información robusta antes del liderazgo empresarial. Perseguir metodologías mundiales sin materiales oportunos y fabricar relaciones internas covalentes.'
      },
      {
        title: '¿Por qué son necesarios los alimentos orgánicos para la vida?',
        content: 'Permitir sinérgicamente información robusta antes del liderazgo empresarial. Perseguir metodologías mundiales sin materiales oportunos y fabricar relaciones internas covalentes.'
      }
    ];


function Servicios() {

  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <div className="bg-[#FAFAFA]">
      <Header />
      <BreadCrumb title="Página de servicio" pageName="Página" currentPage="Servicios"/>

      <section className="service-section ptb-120 position-relative z-1 overflow-hidden">
        <img src={bgShape4} alt="bg shape" className="position-absolute start-0 bottom-0 w-100 z--1 bg-shape" />
        <div className="container">
            <div className="row g-4">
            <div className="col-xl-6">
                <div className="section-title text-center text-xl-start">
                <h2 className="mb-0">Ofrecemos Verduras Naturales y Orgánicas</h2>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="section-desc text-center text-xl-start">
                <p className="mb-3">Coordinar de manera integral aplicaciones basadas en el cliente y cadenas de suministro flexibles. Evolucionar mejoras de procesos centradas en principios a través de información rentable. Liderazgo. Hacerlo de manera creíble en una pizarra independiente.</p>
                <a href="#" className="text-secondary fw-bold fs-xs">Cargando...<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                </div>
            </div>
            </div>
            <div className="row g-4 mt-5">
            <div className="col-xxl-3 col-lg-4 col-md-6">
                <div className="icon-box rounded-3 py-7 px-4">
                <div className="icon-wrapper mb-4 position-relative">
                    <img src={cartColor} alt="icon" className="img-fluid icon-color" />
                    <img src={cartWhite} alt="icon" className="img-fluid icon-white" />
                </div>
                <a href="#">
                    <h5 className="mb-3">Productos Agrícolas</h5>
                </a>
                <p className="mb-5">Aplicaciones basadas en cadenas de suministro flexibles. Evolucionar en una pizarra centrada en principios de manera independiente.</p>
                <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-md-6">
                <div className="icon-box rounded-3 py-7 px-4">
                <div className="icon-wrapper mb-4 position-relative">
                    <img src={organicColor} alt="icon" className="img-fluid icon-color" />
                    <img src={organicWhite} alt="icon" className="img-fluid icon-white" />
                </div>
                <a href="#">
                    <h5 className="mb-3">Productos Orgánicos</h5>
                </a>
                <p className="mb-5">Aplicaciones basadas en cadenas de suministro flexibles. Evolucionar en una pizarra centrada en principios de manera independiente.</p>
                <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-md-6">
                <div className="icon-box rounded-3 py-7 px-4">
                <div className="icon-wrapper mb-4 position-relative">
                    <img src={milkColor} alt="icon" className="img-fluid icon-color" />
                    <img src={milkWhite} alt="icon" className="img-fluid icon-white" />
                </div>
                <a href="#">
                    <h5 className="mb-3">Productos Dulces de Leche</h5>
                </a>
                <p className="mb-5">Aplicaciones basadas en cadenas de suministro flexibles. Evolucionar en una pizarra centrada en principios de manera independiente.</p>
                <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-md-6">
                <div className="icon-box rounded-3 py-7 px-4">
                <div className="icon-wrapper mb-4 position-relative">
                    <img src={deliveryColor} alt="icon" className="img-fluid icon-color" />
                    <img src={deliveryWhite} alt="icon" className="img-fluid icon-white" />
                </div>
                <a href="#">
                    <h5 className="mb-3">Servicio de Entrega</h5>
                </a>
                <p className="mb-5">Aplicaciones basadas en cadenas de suministro flexibles. Evolucionar en una pizarra centrada en principios de manera independiente.</p>
                <a href="#" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                </div>
            </div>
            </div>
        </div>
      </section>

      <section className="about-section bg-shade position-relative z-1">
        <img src={bgShape5} alt="bg shape" className="position-absolute start-0 bottom-0 z--1 w-100" />
        <img src={rollColor} alt="roll" className="position-absolute roll-color z--1" data-parallax="{&quot;y&quot;: -50}" style={{ transform: 'translate3d(0px, -34.252px, 0px)' }} />
        <img src={rollColorCurve} alt="roll" className="position-absolute roll-color-curve z--1" data-parallax="{&quot;y&quot;: 50}" style={{ transform: 'translate3d(0px, 35.876px, 0px)' }} />
        <img src={onionColor} alt="onion" className="position-absolute onion-color z--1" data-parallax="{&quot;x&quot;: -30}" style={{ transform: 'translate3d(-13.186px, 0px, 0px)' }} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="section-title text-center">
                <h2 className="mb-3">Nuestra Capacidad de Trabajo</h2>
                <p className="mb-0">Apuntar al mercado de manera asertiva, Lorem ipsum es simplemente un texto libre disponible dolor incididunt simplemente libre ut labore y dolore.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4 mt-4">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="horizontal-counter d-flex align-items-center gap-3 bg-white rounded p-4">
                <span className="icon-wrapper d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <img src={icon1} alt="icon" className="img-fluid" />
                </span>
                <div className="numbers">
                  <Counter end={3256} />
                  <h6 className="mb-0 text-gray fs-sm">Productos Totales</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="horizontal-counter d-flex align-items-center gap-3 bg-white rounded p-4">
                <span className="icon-wrapper d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <img src={icon2} alt="icon" className="img-fluid" />
                </span>
                <div className="numbers">
                  <Counter end={2456} />
                  <h6 className="mb-0 text-gray fs-sm">Pedidos Totales</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="horizontal-counter d-flex align-items-center gap-3 bg-white rounded p-4">
                <span className="icon-wrapper d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <img src={icon3} alt="icon" className="img-fluid" />
                </span>
                <div className="numbers">
                  <Counter end={1250} />
                  <h6 className="mb-0 text-gray fs-sm">Visitantes Totales</h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="horizontal-counter d-flex align-items-center gap-3 bg-white rounded p-4">
                <span className="icon-wrapper d-inline-flex align-items-center justify-content-center flex-shrink-0">
                  <img src={icon4} alt="icon" className="img-fluid" />
                </span>
                <div className="numbers">
                  <Counter end={1245} />
                  <h6 className="mb-0 text-gray fs-sm">Entregas Totales</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-section ptb-120 position-relative overflow-hidden z-1">
        <img src={frameCircle} alt="circle shape" className="position-absolute frame-circle z--1" />
        <img src={leaf} alt="leaf shape" className="position-absolute leaf z--1" />
        <img src={cauliflower} alt="cauliflower" className="position-absolute cauliflower z--1" />
        <div className="container">
          <div className="row g-5">
            <div className="col-xl-7">
              <div className="feature-image p-3 text-center">
                <img src={girl} alt="girl" className="img-fluid" />
              </div>
            </div>
            <div className="col-xl-5">
              <div className="faq-right">
                <h4 className="mb-4">Cuidamos a tu Familia con Productos Naturales</h4>
                <p className="mb-5">
                  Coordinamos de manera holística aplicaciones basadas en el cliente y cadenas de suministro flexibles.
                  Evolucionamos liderazgo. Hacemos brainstorming de forma independiente.
                </p>
                <div className="accordion faq-accordion" id="faq-accordion">
                  {accordionData.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <div className="accordion-header">
                        <button
                          onClick={() => toggleAccordion(index)}
                          className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                          style={{
                            backgroundColor: activeIndex === index ? '#ffffff' : '#EEF6EB', // Cambia el color de fondo
                            color: activeIndex === index ? '#000000' : '#000000', // Cambia el color del texto
                          }}
                        >
                          {item.title}
                        </button>
                      </div>
                      <div className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}>
                        <div className="accordion-body">
                          <p className="mb-0">{item.content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feedback-section pb-120">
        <div className="container">
          <div className="row align-items-center g-4">
            <div className="col-xl-7">
              <div className="clients_thumb">
                <img src="../../../public/img/about/clients.png" alt="clientes" className="img-fluid" />
              </div>
            </div>
            <div className="col-xl-5">
              <div className="swiper feedback-slider-2">
                <div className="swiper-wrapper">
                  {/* Comentario 1 */}
                  <div className="swiper-slide feedback-card bg-white rounded py-6 px-4">
                    <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                      <img src="../../../public/img/authors/client-3.png" alt="cliente" className="img-fluid rounded-circle flex-shrink-0" />
                      <div className="clients-info">
                        <h5 className="mb-1">George Nakashima</h5>
                        <ul className="d-flex align-items-center fs-xs text-warning">
                          {[...Array(5)].map((_, i) => (
                            <li key={i}>
                              <i className="fas fa-star"></i>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <p className="mb-0">“Sinergiza convenientemente la colaboración premium y el intercambio de ideas con un pensamiento innovador fuera de lo común. Productos distintivos interactivos que generan paradigmas únicos mientras se personaliza el capital intelectual de manera individual. Servicios que consumen recursos. Personalización vertical objetiva.”</p>
                  </div>

                  {/* Comentario 2 */}
                  <div className="swiper-slide feedback-card bg-white rounded py-6 px-4">
                    <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                      <img src="../../../public/img/authors/client-1.png" alt="cliente" className="img-fluid rounded-circle flex-shrink-0" />
                      <div className="clients-info">
                        <h5 className="mb-1">George Nakashima</h5>
                        <ul className="d-flex align-items-center fs-xs text-warning">
                          {[...Array(5)].map((_, i) => (
                            <li key={i}>
                              <i className="fas fa-star"></i>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <p className="mb-0">“Sinergiza convenientemente la colaboración premium y el intercambio de ideas con un pensamiento innovador fuera de lo común. Productos distintivos interactivos que generan paradigmas únicos mientras se personaliza el capital intelectual de manera individual. Servicios que consumen recursos. Personalización vertical objetiva.”</p>
                  </div>

                  {/* Comentario 3 */}
                  <div className="swiper-slide feedback-card bg-white rounded py-6 px-4">
                    <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                      <img src="../../../public/img/authors/client-2.png" alt="cliente" className="img-fluid rounded-circle flex-shrink-0" />
                      <div className="clients-info">
                        <h5 className="mb-1">George Nakashima</h5>
                        <ul className="d-flex align-items-center fs-xs text-warning">
                          {[...Array(5)].map((_, i) => (
                            <li key={i}>
                              <i className="fas fa-star"></i>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <p className="mb-0">“Sinergiza convenientemente la colaboración premium y el intercambio de ideas con un pensamiento innovador fuera de lo común. Productos distintivos interactivos que generan paradigmas únicos mientras se personaliza el capital intelectual de manera individual. Servicios que consumen recursos. Personalización vertical objetiva.”</p>
                  </div>
                </div>

                {/* Flechas de navegación */}
                <div className="slider-arrows text-end mt-5">
                  <button type="button" className="fd2-arrow-left">
                    <i className="fas fa-angle-left"></i>
                  </button>
                  <button type="button" className="fd2-arrow-right">
                    <i className="fas fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    <Footer />
    </div>
  )
}

export {Servicios}