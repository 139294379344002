import "../../style/global.css"
function Banner() {
    return (
        <div className="section-space-top section-space-sm-bottom">
            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-6">
                        <div className="product-banner product-banner--1 h-100 py-14 px-8 rounded-3">
                            <span className="d-inline-block mb-6 rounded py-1 px-3 fs-14 clr-white one-bg" style={{background: "#fe4343", fontWeight:"700"}}>
                                Venta 65% de Descuento
                            </span>
                            <span className="d-block mb-1 fs-18  clr-alt-text" style={{color:"#212e50", fontWeight:"700"}}>Vitamina B6 de Piridoxina</span>
                            <h3 className="product-banner__title mb-12">Vitaminas y Suplementos</h3>
                            <a href="#" className="link button button--primary button-effect button-effect--secondary" style={{ background: "#20235b", color:"white"}}>
                                <span className="d-inline-block fw-semibold">Comprar Ahora</span>
                                <span className="d-inline-block">
                                    <i className="fas fa-arrow-right"></i>
                                </span>
                            </a>
                            <div className="product-banner__img">
                                <img src="img/home-6/product-banner-img-1.png" alt="imagen" className="img-fluid product-banner__img-is" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row g-4">
                            <div className="col-md-6" >
                                <div className="product-banner product-banner--2 h-100 pt-4 pb-20 px-4 rounded-3" style={{background: "#00acf01a", marginBottom:"1rem"}}>
                                    <span className="d-inline-block mb-4 rounded-pill py-1 px-3 fs-10 fw-semibold clr-white one-bg" style={{background: "#fe4343", fontWeight:"700"}}>
                                        Venta 65% de Descuento
                                    </span>
                                    <span className="d-block mb-1 fs-12 fw-semibold clr-alt-text">Desinfectante de Manos</span>
                                    <h3 className="product-banner__title mb-6">Joven y Saludable</h3>
                                    <a href="#" className="link button button-sm button--two button-effect button-effect--secondary fs-12" style={{ background: "#20235b", color:"white"}}>
                                        <span className="d-inline-block fw-semibold">Comprar Ahora</span>
                                        <span className="d-inline-block">
                                            <i className="fas fa-arrow-right"></i>
                                        </span>
                                    </a>
                                    <div className="product-banner__img" style={{insetInlineStart:"40%", insetInlineEnd:"30px"}}>
                                        <img src="img/home-6/product-banner-img-2.png" alt="imagen" className="img-fluid product-banner__img-is"  />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" >
                                <div className="rounded-3 h-100 px-6 py-8 " style={{backgroundColor: "#20235b", color:"white"}}>
                                    <span className="d-block fw-bold fs-24 clr-light mb-1">Envío Gratis</span>
                                    <h2 className="d-block fw-bold fs-32 clr-light mb-3">40% de Descuento</h2>
                                    <p className="mb-0 clr-light fs-14">
                                        Estrategias de crecimiento funcionales enfocadas en servicios web. Autoritariamente en mercados y fuentes únicas.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="product-banner product-banner--3 h-100 pt-4 pb-20 px-4 rounded-3" style={{backgroundColor:"#d36cea1a"}}>
                                    <span className="d-inline-block mb-4 rounded-pill py-1 px-3 fs-14 fw-semibold clr-white one-bg" style={{background: "#fe4343", fontWeight:"700"}}>
                                        Gran Venta 65% de Descuento
                                    </span>
                                    <span className="d-block mb-1 fs-18 fw-semibold clr-alt-text">Farmacia</span>
                                    <h3 className="product-banner__title mb-6">Vitamina de Piridoxina</h3>
                                    <a href="#" className="link button button-md button--three button-effect button-effect--secondary" style={{ background: "#20235b", color:"white"}}>
                                        <span className="d-inline-block fw-semibold">Comprar Ahora</span>
                                        <span className="d-inline-block">
                                            <i className="fas fa-arrow-right"></i>
                                        </span>
                                    </a>
                                    <div className="product-banner__img" style={{insetInlineStart:"65%"}}>
                                        <img src="img/home-6/product-banner-img-3.png" alt="imagen" className="img-fluid product-banner__img-is" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Banner }