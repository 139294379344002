

function ControlPanel() {
    return (
        <div className="tab-pane fade active show" id="dashboard" role="tabpanel">
            <div className="address-book bg-white rounded p-5">
                <div className="row g-6">
                    <div className="col-md-6">
                        <div className="address-book-content pe-md-4 border-right position-relative">
                            <div className="d-flex align-items-center gap-5 mb-4">
                                <h6 className="mb-0 text-dark fw-bold">Libro de Direcciones</h6>
                                <a href="#" className="enlace colorIcon">Editar</a>
                            </div>
                            <p className="text-uppercase fw-medium mb-3">
                                Dirección de Envío Predeterminada
                            </p>
                            <div className="address">
                                <p className="text-dark fw-bold mb-1">Saiful Talukdar</p>
                                <p className="mb-0">
                                    Ghunshe, Muksudpur Dhaka - Gopalganj.
                                    <br />
                                    (+880) 1633082302
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 align-self-end">
                        <div className="address-book-content ps-md-4">
                            <p className="text-uppercase fw-medium mb-3">
                                Dirección de Facturación Predeterminada
                            </p>
                            <div className="address">
                                <p className="text-dark fw-bold mb-1">Saiful Talukdar</p>
                                <p className="mb-0">
                                    Ghunshe, Muksudpur Dhaka - Gopalganj.
                                    <br />
                                    (+880) 1633082302
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ControlPanel }