import { Header } from '../../components/Home/header/Header'
import frameCircle from "../../../public/img/shapes/frame-circle.svg";
import roll2 from "../../../public/img/shapes/roll-2.png";
import roll1 from "../../../public/img/shapes/roll-1.png";
import pataXs from "../../../public/img/shapes/pata-xs.svg";
import garlicWhite from "../../../public/img/shapes/garlic-white.png";
import leaf from "../../../public/img/shapes/leaf.svg";
import circleHalfFill from "../../../public/img/shapes/circle-half-fill.png";
import textureOverlay from "../../../public/img/shapes/texture-overlay.png";
import { Footer } from '../../components/Home/footer/Footer';


function Contact() {
  return (
    <div>
      <Header />
      <section className="contact-us-section position-relative overflow-hidden z-1 pt-80 pb-120">
            <img
                src={frameCircle}
                alt="círculo de marco"
                className="position-absolute frame z--1 d-none d-sm-block"
            />
            <img
                src={roll2}
                alt="rollo"
                className="position-absolute roll-2 z--1"
            />
            <img
                src={pataXs}
                alt="pata"
                className="position-absolute pata z--1"
            />
            <img
                src={garlicWhite}
                alt="ajo"
                className="position-absolute garlic z--1"
            />
            <img
                src={roll1}
                alt="rollo"
                className="position-absolute roll-1 z--1"
            />
            <img
                src={leaf}
                alt="hoja"
                className="position-absolute leaf z--1"
            />
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-xl-12">
                    <div className="breadcrumb-content">
                    <h2 className="mb-2 text-center">Ponte en Contacto</h2>
                    <nav>
                        <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item fw-bold" aria-current="page">
                            <a href="/">Inicio</a>
                        </li>
                        <li className="breadcrumb-item fw-bold" aria-current="page">
                            Página
                        </li>
                        <li className="breadcrumb-item fw-bold" aria-current="page">
                            Página de Contacto
                        </li>
                        </ol>
                    </nav>
                    </div>
                </div>
                </div>
                <div className="contact-box rounded-2 bg-white overflow-hidden mt-8">
                <div className="row g-4">
                    <div className="col-xl-5">
                    <div
                        className="contact-left-box position-relative overflow-hidden z-1 bg-primary p-6 d-flex flex-column h-100"
                        style={{
                        backgroundImage: `url(${circleHalfFill})`,
                        }}
                    >
                        <img
                        src={textureOverlay}
                        alt="textura"
                        className="position-absolute w-100 h-100 start-0 top-0 z--1"
                        />
                        <h3 className="text-white mb-3">Detalles de Contacto</h3>
                        <p className="fs-sm text-white">
                        <strong>Dirección de la Oficina-1:</strong> G-shop Grocery shop (Estados Unidos de América)
                        Co., Ltd. Bridge 8, Sala 9201.
                        </p>
                        <p className="fs-sm text-white mb-0">
                        <strong>Dirección de la Oficina-2:</strong> #25 Jocker Goru Zhong Road NYPD 200025 USA.
                        </p>
                        <span className="spacer my-5"></span>
                        <ul className="contact-list">
                        <li className="d-flex align-items-center gap-3 flex-wrap">
                            <span className="icon d-inline-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                            <i className="fa-brands fa-whatsapp"></i>
                            </span>
                            <div className="info">
                            <span className="fw-medium text-white fs-xs">Llamada de Emergencia</span>
                            <h5 className="mb-0 mt-1 text-white">+90 54 3147 87 98</h5>
                            </div>
                        </li>
                        <li className="d-flex align-items-center gap-3 flex-wrap mt-3">
                            <span className="icon d-inline-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                            <i className="fa-regular fa-envelope"></i>
                            </span>
                            <div className="info">
                            <span className="fw-medium text-white fs-xs">Comunicación General</span>
                            <p className="mb-0 mt-1 text-white fw-medium">gshop2022@gmail.com</p>
                            </div>
                        </li>
                        </ul>
                        <div className="mt-5">
                        <span className="fw-bold text-white mb-3 d-block">Redes Sociales:</span>
                        <div className="social-links d-flex align-items-center gap-2">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                            <a href="#"><i className="fab fa-twitter"></i></a>
                            <a href="#"><i className="fab fa-behance"></i></a>
                            <a href="#"><i className="fab fa-discord"></i></a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-7">
                    <form className="contact-form ps-5 ps-xl-4 py-6 pe-6" action="#">
                        <h3 className="mb-6">¿Necesitas Ayuda? Envía un Mensaje</h3>
                        <div className="row g-4">
                        <div className="col-sm-6">
                            <div className="label-input-field">
                            <label>Nombre</label>
                            <input type="text" placeholder="Saiful" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                            <label>Apellido</label>
                            <input type="text" placeholder="Talukdar" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                            <label>Email</label>
                            <input type="email" placeholder="Tu correo electrónico" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="label-input-field">
                            <label>Teléfono</label>
                            <input type="tel" placeholder="Tu teléfono" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="checkbox-fields d-flex align-items-center gap-3 flex-wrap my-2">
                            <div className="single-field d-inline-flex align-items-center gap-2">
                                <div className="theme-checkbox">
                                <input type="checkbox" id="delivery" />
                                <span className="checkbox-field">
                                    <i className="fas fa-check"></i>
                                </span>
                                </div>
                                <label htmlFor="delivery" className="text-dark fw-semibold">
                                Problema de Entrega
                                </label>
                            </div>
                            <div className="single-field d-inline-flex align-items-center gap-2">
                                <div className="theme-checkbox">
                                <input type="checkbox" id="service" />
                                <span className="checkbox-field">
                                    <i className="fas fa-check"></i>
                                </span>
                                </div>
                                <label htmlFor="service" className="text-dark fw-semibold">
                                Servicio al Cliente
                                </label>
                            </div>
                            <div className="single-field d-inline-flex align-items-center gap-2">
                                <div className="theme-checkbox">
                                <input type="checkbox" id="others" />
                                <span className="checkbox-field">
                                    <i className="fas fa-check"></i>
                                </span>
                                </div>
                                <label htmlFor="others" className="text-dark fw-semibold">
                                Otros Servicios
                                </label>
                            </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="label-input-field">
                            <label>Mensaje</label>
                            <textarea placeholder="Escribe tu mensaje" ></textarea>
                            </div>
                        </div>
                        </div>
                        <button type="submit" className="btn btn-primary btn-md rounded-1 mt-6">
                        Enviar Mensaje
                        </button>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
  )
}

export {Contact}
