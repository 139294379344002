import { Footer } from "../Home/footer/Footer"
import { Header } from "../Home/header/Header"
import { CallNatural } from "../HomeNatural/callNatural/CallNatural"
import AboutOrganico from "./aboutOrganico/AboutOrganico"
import { BannerOrganico } from "./bannerOrganico/BannerOrganico"
import { BlogOrganico } from "./blogOrganico/BlogOrganico"
import { CategoryOrganico } from "./categoryOrganico/CategoryOrganico"
import { FeaturedOrganico } from "./featuredOrganico/FeaturedOrganico"
import { FeedbacksOrganico } from "./feedbacksOrganico/FeedbacksOrganico"
import { OfferOrganico } from "./offerOrganico/OfferOrganico"
import { ProductsOrganicos } from "./productsOrganicos/ProductsOrganicos"
import { SliderOrganico } from "./sliderOrganico/SliderOrganico"


function HomeOrganico() {
  return (
    <div>
      <Header />
      <SliderOrganico />
      <CategoryOrganico />
      <FeaturedOrganico />
      <BannerOrganico />
      <ProductsOrganicos />
      <OfferOrganico />
      <AboutOrganico />
      <FeedbacksOrganico />
      <CallNatural />
      <BlogOrganico />
      <Footer />
    </div>
  )
}

export {HomeOrganico}
