import bgShape6 from "../../../../public/img/shapes/bg-shape-6.png";
import pataXs from "../../../../public/img/shapes/pata-xs.svg";
import onion from "../../../../public/img/shapes/onion.png";
import frameCircle from "../../../../public/img/shapes/frame-circle.svg";
import leaf from "../../../../public/img/shapes/leaf.svg";
import garlicWhite from "../../../../public/img/shapes/garlic-white.png";
import roll1 from "../../../../public/img/shapes/roll-1.png";
import roll2 from "../../../../public/img/shapes/roll-2.png";
import tomatoHalf from "../../../../public/img/shapes/tomato-half.svg";
import tomatoSlice from "../../../../public/img/shapes/tomato-slice.svg";
import cauliflower from "../../../../public/img/shapes/cauliflower.png";
import leafGray from "../../../../public/img/shapes/leaf-gray.png";

interface BreadCrumbProps {
  title: string;
  pageName: string;
  currentPage: string;
}

function BreadCrumb({ title, pageName, currentPage }:BreadCrumbProps) {
  return (
    <div className="gstore-breadcrumb position-relative z-1 overflow-hidden mt--50">
      <img src={bgShape6} alt="bg-shape" className="position-absolute start-0 z--1 w-100 bg-shape" />
      <img src={pataXs} alt="pata" className="position-absolute pata-xs z--1 vector-shape" />
      <img src={onion} alt="onion" className="position-absolute z--1 onion start-0 top-0 vector-shape" />
      <img src={frameCircle} alt="frame circle" className="position-absolute z--1 frame-circle vector-shape" />
      <img src={leaf} alt="leaf" className="position-absolute z--1 leaf vector-shape" />
      <img src={garlicWhite} alt="garlic" className="position-absolute z--1 garlic vector-shape" />
      <img src={roll1} alt="roll" className="position-absolute z--1 roll vector-shape" />
      <img src={roll2} alt="roll" className="position-absolute z--1 roll-2 vector-shape" />
      <img src={pataXs} alt="roll" className="position-absolute z--1 pata-xs-2 vector-shape" />
      <img src={tomatoHalf} alt="tomato" className="position-absolute z--1 tomato-half vector-shape" />
      <img src={tomatoSlice} alt="tomato" className="position-absolute z--1 tomato-slice vector-shape" />
      <img src={cauliflower} alt="cauliflower" className="position-absolute z--1 cauliflower vector-shape" />
      <img src={leafGray} alt="leaf-gray" className="position-absolute z--1 leaf-gray vector-shape" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb-content">
            <h2 className="mb-2 text-center" dangerouslySetInnerHTML={{ __html: title }} />
              <nav>
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item fw-bold" aria-current="page">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item fw-bold" aria-current="page">{pageName}</li>
                  <li className="breadcrumb-item fw-bold" aria-current="page">{currentPage}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { BreadCrumb };



