import 'bootstrap/dist/css/bootstrap.min.css';
import "../../style/global.css"

function Download() {
    return (
        <div className="section-space-sm-top section-space-bottom">
            <div className="container">
                <div className="row g-4 align-items-center">
                    <div className="col-lg-6">
                        <h2 className="mb-5 display-6" style={{fontWeight:"700"}}>Descarga la App de Entregas y Guía de Medicamentos</h2>
                        <p className="fs-18 mb-10" style={{color:"gray"}}>
                            Libera productos médicos innovadores y tecnología avanzada en el cuidado de la salud.
                            Proporciona eficientemente soluciones de salud de alta calidad. Integra continuamente
                            herramientas y recursos médicos para un mejor bienestar.
                        </p>
                        <div className="d-flex align-items-center gap-4 flex-wrap">
                            <a href="#" className="link button button-sm button--primary align-items-center gap-3 px-6 flex-shrink-0" style={{background:"#00b1b8", color: "white"}}>
                                <span className="d-inline-block flex-shrink-0 fs-32 lh-1">
                                    <i className="fab fa-google-play"></i>
                                </span>
                                <span className="flex-grow-1">
                                    <span className="d-inline-block fs-12"> Obtener en </span>
                                    <span className="d-block fw-bold"> Google Play </span>
                                </span>
                            </a>
                            <a href="#" className="link button button-sm button--secondary align-items-center gap-3 px-6 flex-shrink-0" style={{background:"#20235b", color: "white"}}>
                                <span className="d-inline-block flex-shrink-0 fs-32 lh-1">
                                    <i className="fab fa-apple"></i>
                                </span>
                                <span className="flex-grow-1">
                                    <span className="d-inline-block fs-12"> Descargar en </span>
                                    <span className="d-block fw-bold"> App Store </span>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="text-center">
                            <img src="img/home-6/app-img.png" alt="imagen" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Download }