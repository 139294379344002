import { Header } from '../../components/Home/header/Header'
import { BreadCrumb } from '../../components/productsView/breadCrumb/BreadCrumb'
import thumbSm1 from "../../../public/img/products/thumb-sm-1.png";
import circleHalf from "../../../public/img/shapes/circle-half.png";
import { Footer } from '../../components/Home/footer/Footer';

function Shop() {
  return (
    <div>
      <Header />
      <BreadCrumb title="Carro de la compra" pageName="Página" currentPage="Página del compras" />

      <section className="cart-section ptb-120">
        <div className="container">
            <div className="select-all d-flex align-items-center justify-content-between bg-white rounded p-4">
                <div className="d-inline-flex gap-2 align-items-center">
                    <div className="theme-checkbox">
                        <input type="checkbox" id="select-all" />
                        <span className="checkbox-field"><i className="fa-solid fa-check"></i></span>
                    </div>
                    <label htmlFor="select-all">Seleccionar Todo (03 ITEMS)</label>
                </div>
                <a href="#" className="text-gray"><span className="me-2"><i className="fa-solid fa-trash-can"></i></span>Eliminar</a>
            </div>
            <div className="rounded-2 overflow-hidden">
                <table className="cart-table w-100 mt-4 bg-white">
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Nombre del Producto</th>
                            <th>Cantidad</th>
                            <th>Precio Unitario</th>
                            <th>Precio Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img src={thumbSm1} alt="product-thumb" className="img-fluid" />
                            </td>
                            <td className="text-start product-title">
                                <h6 className="mb-0">Tres Zanahorias Verduras Cocina Peruana</h6>
                            </td>
                            <td>
                                <div className="product-qty d-inline-flex align-items-center">
                                    <button className="decrease">-</button>
                                    <input type="text" value="01" />
                                    <button className="increase">+</button>
                                </div>
                            </td>
                            <td>
                                <span className="text-dark fw-bold me-2 d-lg-none">Precio Unitario:</span>
                                <span className="text-dark fw-bold">$140.00</span>
                            </td>
                            <td>
                                <span className="text-dark fw-bold me-2 d-lg-none">Precio Total:</span>
                                <span className="text-dark fw-bold">$140.00</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={thumbSm1} alt="product-thumb" className="img-fluid" />
                            </td>
                            <td className="text-start product-title">
                                <h6 className="mb-0">Tres Zanahorias Verduras Cocina Peruana</h6>
                            </td>
                            <td>
                                <div className="product-qty d-inline-flex align-items-center">
                                    <button className="decrease">-</button>
                                    <input type="text" value="01" />
                                    <button className="increase">+</button>
                                </div>
                            </td>
                            <td>
                                <span className="text-dark fw-bold me-2 d-lg-none">Precio Unitario:</span>
                                <span className="text-dark fw-bold">$140.00</span>
                            </td>
                            <td>
                                <span className="text-dark fw-bold me-2 d-lg-none">Precio Total:</span>
                                <span className="text-dark fw-bold">$140.00</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={thumbSm1} alt="product-thumb" className="img-fluid" />
                            </td>
                            <td className="text-start product-title">
                                <h6 className="mb-0">Tres Zanahorias Verduras Cocina Peruana</h6>
                            </td>
                            <td>
                                <div className="product-qty d-inline-flex align-items-center">
                                    <button className="decrease">-</button>
                                    <input type="text" value="01" />
                                    <button className="increase">+</button>
                                </div>
                            </td>
                            <td>
                                <span className="text-dark fw-bold me-2 d-lg-none">Precio Unitario:</span>
                                <span className="text-dark fw-bold">$140.00</span>
                            </td>
                            <td>
                                <span className="text-dark fw-bold me-2 d-lg-none">Precio Total:</span>
                                <span className="text-dark fw-bold">$140.00</span>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            <div className="row g-4">
                <div className="col-xl-7">
                    <div className="voucher-box py-7 px-5 position-relative z-1 overflow-hidden bg-white rounded mt-4">
                        <img src={circleHalf} alt="shape" className="position-absolute end-0 top-0 z--1" />
                        <h4 className="mb-3">¿Qué te gustaría hacer a continuación?</h4>
                        <p className="mb-7">Elige si tienes un código de descuento o puntos de recompensa que quieras usar o si deseas calcular tu costo de envío.</p>
                        <form className="d-flex align-items-center" action="#">
                            <input type="text" placeholder="Introduce tu Código de Descuento" className="theme-input w-100" />
                            <button type="submit" className="btn btn-secondary flex-shrink-0">Aplicar</button>
                        </form>
                    </div>
                </div>
                <div className="col-xl-5">
                    <div className="cart-summery bg-white rounded-2 pt-4 pb-6 px-5 mt-4">
                        <table className="w-100">
                            <tbody>
                                <tr>
                                    <td className="py-3">
                                        <h5 className="mb-0 fw-medium">Subtotal</h5>
                                    </td>
                                    <td className="py-3">
                                        <h5 className="mb-0 fw-semibold text-end">$1138.00</h5>
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <td className="py-3">
                                        <h5 className="mb-0">Total</h5>
                                    </td>
                                    <td className="text-end py-3">
                                        <h5 className="mb-0">$918.00</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="mb-5 mt-2">Las opciones de envío se actualizarán durante el proceso de pago.</p>
                        <div className="btns-group d-flex gap-3">
                            <button type="submit" className="btn btn-primary btn-md rounded-1">Confirmar Pedido</button>
                            <button type="button" className="btn btn-outline-secondary border-secondary btn-md rounded-1">Seguir Comprando</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Footer />

    </div>
  )
}

export {Shop}
