import paymentImg1 from "../../../../public/img/home-6/payment-img-1.png";
import paymentImg2 from "../../../../public/img/home-6/payment-img-2.png";
import paymentImg3 from "../../../../public/img/home-6/payment-img-3.png";
import paymentImg4 from "../../../../public/img/home-6/payment-img-4.png";
import logo from "../../../../public/img/logo.png";

function FooterMueble() {
  return (
    <footer className="footer-7">
    <div className="section-space-sm-y">
        <div className="container">
            <div className="row g-4 gy-5 gy-md-0">
                <div className="col-md-3">
                    <h5 className="fs-20 mb-5">Categoría</h5>
                    <ul className="list gap-2">
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Sobre Nosotros </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Información de Entrega </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Política de Privacidad </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Marcas </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Contacto </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Devoluciones </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Mapa del Sitio </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h5 className="fs-20 mb-5">Enlaces Rápidos</h5>
                    <ul className="list gap-2">
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Ubicación de la Tienda </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Historial de Pedidos </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Lista de Deseos </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Boletín </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Ofertas Especiales </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Certificado de Regalo </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Afiliados </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h5 className="fs-20 mb-5">Contacto</h5>
                    <ul className="list gap-2">
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Sobre Nosotros </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Información de Entrega </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Política de Privacidad </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Marcas </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Contacto </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Devoluciones </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Mapa del Sitio </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3">
                    <h5 className="fs-20 mb-5">Soporte</h5>
                    <ul className="list gap-2">
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Ubicación de la Tienda </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Historial de Pedidos </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Lista de Deseos </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Boletín </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Ofertas Especiales </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Certificado de Regalo </a>
                        </li>
                        <li>
                            <a href="#" className="link d-inline-block clr-heading :clr-primary"> Afiliados </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className="section-space-xsm-bottom">
        <div className="container">
            <div className="row g-4 align-items-center">
                <div className="col-md-5">
                    <p className="mb-0 text-center text-md-start">
                        © Todos los derechos reservados. Hecho por
                        <a href="#" className="link d-inline-block clr-secondary :clr-primary" style={{ color: "#ff7c0a" }}>JoseJorgeST</a>
                    </p>
                </div>
                <div className="col-md-2">
                    <div className="text-center">
                        <a href="index.html" className="logo">
                            <img src={logo} alt="logo" className="img-fluid" />
                        </a>
                    </div>
                </div>
                <div className="col-md-5">
                    <ul className="list list--row gap-2 flex-wrap justify-content-center justify-content-md-end">
                        <li>
                            <img src={paymentImg1} alt="image" className="img-fluid" />
                        </li>
                        <li>
                            <img src={paymentImg2} alt="image" className="img-fluid" />
                        </li>
                        <li>
                            <img src={paymentImg3} alt="image" className="img-fluid" />
                        </li>
                        <li>
                            <img src={paymentImg4} alt="image" className="img-fluid" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}

export {FooterMueble}
