import "../../style/global.css"

function Search() {
    return (
        <div className="search-filter-container" style={{position: "relative"}}>
            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-4">
                        <div className="customer-counter rounded">
                            <h1 className="customer-counter__title">900K+</h1>
                            <span className="customer-counter__subtitle">Únete a nuestros clientes felices</span>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="search-filter rounded">
                            <div className="search-filter__title">
                                <span className="search-filter__dash"></span>
                                <h3 className="search-filter__title-is">Busca tus productos</h3>
                            </div>
                            <div className="search-filter__content">
                                <div className="search-filter__select">
                                    <div className="search-filter__form-select">
                                        <select className="form-select">
                                            <option selected={true}>Categoría</option>
                                            <option value="1">Categoría Uno</option>
                                            <option value="2">Categoría Dos</option>
                                            <option value="3">Categoría Tres</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="search-filter__select">
                                    <div className="search-filter__form-select">
                                        <select className="form-select">
                                            <option selected={true}>Marca</option>
                                            <option value="1">Marca Uno</option>
                                            <option value="2">Marca Dos</option>
                                            <option value="3">Marca Tres</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="search-filter__select">
                                    <input className="form-control search-filter__input" type="text" placeholder="Entrada"  style={{padding: "18px 16px", border: "1px solid rgba(0, 184, 171, .2)",     backgroundColor: "#edf4f6"}}/>
                                </div>
                                <button type="button" className="search-filter__btn">
                                    <span className="search-filter__btn-text">Comprar ahora</span>
                                    <span className="search-filter__btn-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Search }