import blogGrid1 from "../../../../public/img/blog/blog-grid-1.jpg";
import blogThumbMd1 from "../../../../public/img/blog/blog-thumb-md-1.jpg";

function BlogOrganico() {
  return (
    <section className="blog-section pb-120 overflow-hidden">
    <div className="container">
      <div className="row g-5 justify-content-between">
        <div className="col-xl-5 col-lg-7">
          <div className="section-title text-center text-lg-start">
            <h2 className="mb-3">Navega Publicaciones Recientes</h2>
            <p className="mb-0">Producto interactivo con paradigmas distintivos mientras que uno-a-uno capital intelectual. servicios de succión de recursos.</p>
          </div>
        </div>
        <div className="col-xl-4 align-self-end col-lg-5">
          <div className="text-center text-lg-end d-none d-lg-block">
            <a href="blog-listing.html" className="btn btn-dark">Mostrar Todo<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
          </div>
        </div>
      </div>
      <div className="row justify-content-center g-4 mt-2">
        <div className="col-xl-6">
          <article className="hm3-blog-card">
            <div className="thumbnail rounded overflow-hidden">
              <a href="blog-details.html"><img src={blogGrid1} alt="blog grid" className="img-fluid" /></a>
            </div>
            <div className="article-contents py-5 px-4 bg-white position-relative">
              <div className="blog-meta d-flex align-items-center gap-3 gap-xl-2 gap-xxl-3 flex-wrap mb-2">
                <span className="fw-medium fs-xs"><i className="fa-solid fa-tags me-2"></i>Vegetales Orgánicos</span>
                <span className="fw-medium fs-xs"><i className="fa-regular fa-clock me-2"></i>24 de mayo de 2022</span>
              </div>
              <a href="blog-details.html">
                <h4 className="mb-2">Entrega a Domicilio Recientemente Ordenada</h4>
              </a>
              <p className="mb-3">Apuntar de manera asertiva al mercado objetivo lorem ipsum es onsectetur notado et dolore.</p>
              <a href="blog-details.html" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
            </div>
          </article>
        </div>
        <div className="col-xl-6">
          <div className="row g-4">
            <div className="col-xl-12 col-md-6">
              <article className="article-horizontal d-flex align-items-center gap-4 p-4 rounded-3 bg-white border-0">
                <div className="thumbnail flex-shrink-0 overflow-hidden rounded-3">
                  <a href="blog-details.html"><img src={blogThumbMd1} alt="no encontrado" className="img-fluid" /></a>
                </div>
                <div className="article-contents">
                  <div className="blog-meta d-flex align-items-center gap-3 gap-xl-2 gap-xxl-3 flex-wrap mb-2">
                    <span className="fw-medium fs-xs"><i className="fa-solid fa-tags me-2"></i>Vegetales Orgánicos</span>
                    <span className="fw-medium fs-xs"><i className="fa-regular fa-clock me-2"></i>24 de mayo de 2022</span>
                  </div>
                  <a href="blog-details.html">
                    <h4 className="mb-2">Calidad Perfecta a un Precio Razonable para tu Familia</h4>
                  </a>
                  <p className="mb-3">Apuntar de manera asertiva al mercado objetivo lorem ipsum es onsectetur notado et dolore.</p>
                  <a href="blog-details.html" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                </div>
              </article>
            </div>
            <div className="col-xl-12 col-md-6">
              <article className="article-horizontal d-flex align-items-center gap-4 p-4 rounded-3 bg-white border-0">
                <div className="thumbnail flex-shrink-0 overflow-hidden rounded-3">
                  <a href="blog-details.html"><img src={blogThumbMd1} alt="no encontrado" className="img-fluid" /></a>
                </div>
                <div className="article-contents">
                  <div className="blog-meta d-flex align-items-center gap-3 gap-xl-2 gap-xxl-3 flex-wrap mb-2">
                    <span className="fw-medium fs-xs"><i className="fa-solid fa-tags me-2"></i>Vegetales Orgánicos</span>
                    <span className="fw-medium fs-xs"><i className="fa-regular fa-clock me-2"></i>24 de mayo de 2022</span>
                  </div>
                  <a href="blog-details.html">
                    <h4 className="mb-2">Calidad Perfecta a un Precio Razonable para tu Familia</h4>
                  </a>
                  <p className="mb-3">Apuntar de manera asertiva al mercado objetivo lorem ipsum es onsectetur notado et dolore.</p>
                  <a href="blog-details.html" className="explore-btn fw-bold">Explorar Más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center d-lg-none mt-6">
        <a href="blog-listing.html" className="btn btn-dark">Mostrar Todo<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
      </div>
    </div>
  </section>
  )
}

export {BlogOrganico}
