


function Blog() {
    const posts = [
        {
          id: 1,
          imgSrc: 'img/blog/blog-thumb-1.jpg',
          category: 'Vegetal Orgánico',
          date: 'May 24, 2022',
          title: 'Entrega a domicilio en vacaciones Hemos pedido recientemente',
          description: 'Explotar de manera integral las estrategias de crecimiento invertidas en acciones mientras que las empresas',
          link: 'blog-details.html',
        },
        {
          id: 2,
          imgSrc: 'img/blog/blog-thumb-2.jpg',
          category: 'Vegetal Orgánico',
          date: 'May 24, 2022',
          title: 'Entrega a domicilio en vacaciones Hemos pedido recientemente',
          description: 'Explotar de manera integral las estrategias de crecimiento invertidas en acciones mientras que las empresas',
          link: 'blog-details.html',
        },
        {
          id: 3,
          imgSrc: 'img/blog/blog-thumb-3.jpg',
          category: 'Vegetal Orgánico',
          date: 'May 24, 2022',
          title: 'Entrega a domicilio en vacaciones Hemos pedido recientemente',
          description: 'Explotar de manera integral las estrategias de crecimiento invertidas en acciones mientras que las empresas',
          link: 'blog-details.html',
        },
      ];
  return (
    <section className="blog-section pb-120 position-relative overflow-hidden z-1">
      <img src="img/shapes/dal.png" alt="shape" className="position-absolute dal-shape z--1" />
      <img src="img/shapes/frame-circle.svg" alt="frame circle" className="position-absolute frame-circle z--1 d-none d-md-block" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8">
            <div className="section-title text-center">
              <h2 className="mb-3" style={{ color: "#191d28", fontWeight:"700"}}>Explorar publicaciones recientes</h2>
              <p className="mb-0" style={{ fontWeight: '500', color: '#5d6374',  marginBottom: '1.75rem', fontSize: '1rem'}}>Paradigmas distintivos del producto interactivo mientras que capital intelectual uno a uno. Servicios de succión de recursos.</p>
            </div>
          </div>
        </div>
        <div className="row g-4 justify-content-center mt-3">
          {posts.map((post) => (
            <div key={post.id} className="col-xl-4 col-md-6">
              <article className="blog-card rounded-2 overflow-hidden bg-white">
                <div className="thumbnail overflow-hidden">
                  <a href={post.link}><img src={post.imgSrc} alt="blog thumb" className="img-fluid" /></a>
                </div>
                <div className="blog-card-content">
                  <div className="blog-meta d-flex align-items-center gap-3 mb-1">
                    <span className="fs-xs fw-medium"><i className="fas fa-tags me-1"></i>{post.category}</span>
                    <span className="fs-xs fw-medium"><i className="fas fa-clock me-1"></i>{post.date}</span>
                  </div>
                  <a href={post.link}>
                    <h4 className="mb-3">{post.title}</h4>
                  </a>
                  <p className="mb-0 mb-5">{post.description}</p>
                  <a href={post.link} className="btn btn-primary-light btn-md">Explora más<span className="ms-2"><i className="fas fa-arrow-right"></i></span></a>
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export  {Blog}